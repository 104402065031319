import React from "react";
import { useRouter } from "next/router";
import { useFlashMessages } from "../../utils/flash-messages";
import { useAuthContext } from "../../utils/authentication";
import { useCreateExpertMutation } from "../../utils/graphql-queries";
import { recordAcceptingTermsAndConditions } from "../../api/authentication";
import { hasGraphQLError } from "../../utils/graphql-errors";

type Variant = "signup" | "login";

interface Props {
  variant: Variant;
  provider?: string;
  token?: string;
  error?: string;
}

export default function AuthCallback(props: Props) {
  const router = useRouter();
  const authContext = useAuthContext();
  const { addMessage } = useFlashMessages();
  const [_, createExpert] = useCreateExpertMutation();
  const { variant, provider, token, error } = props;

  const callback = React.useCallback(async () => {
    if (token != null) {
      authContext?.setToken(token);

      if (variant === "signup") {
        try {
          await recordAcceptingTermsAndConditions(
            token,
            "experts-october-2020"
          );
        } catch (error) {
          // ignore, T&Cs are already accepted
        }

        try {
          // Auth header must be manually specified here to deal with a race condition
          // with the GraphQL client during signup.
          await createExpert(undefined, {
            fetchOptions: { headers: { Authorization: `Bearer ${token}` } },
          });
        } catch (error) {
          if (hasGraphQLError(error, "Forbidden")) {
            // ignore, expert has been created already
          } else {
            throw error;
          }
        }
      }

      router.replace("/");
      return;
    }

    if (provider == null) {
      throw new Error(
        "Provider is missing when handling authentication callback error"
      );
    }

    const redirectUrl = variant === "signup" ? "/signup" : "/";

    if (error != null) {
      if (error === "UserNotFound" && variant === "login") {
        const query = {
          error: "UserNotFound",
          provider: provider,
        };
        router.replace({ pathname: redirectUrl, query });
      } else {
        addMessage({
          type: "third_party_login_error",
          error: error,
          meta: { provider: provider },
        });
        router.replace(redirectUrl);
      }
    } else {
      addMessage({
        type: "third_party_login_error",
        error: "StatusNotOK",
        meta: { provider: provider },
      });
      router.replace(redirectUrl);
    }
  }, [
    token,
    error,
    provider,
    variant,
    addMessage,
    createExpert,
    authContext,
    router,
  ]);

  React.useEffect(() => {
    callback();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
