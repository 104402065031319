import config from "../config";
import { getAuthHeaders, getCredentialsConfig } from "./helpers";
import { NetworkError, parseApiError } from "./errors";

export async function uploadImage(
  token: string | null,
  image: ImageUpload
): Promise<string> {
  const url = new URL(`${config.apiUrl}/api/v1/images`);
  if (image.purpose != null) {
    url.searchParams.set("type", image.purpose);
  }

  const body = new FormData();
  body.append("upload", image.source);

  let response: Response;

  try {
    response = await fetch(url.href, {
      method: "POST",
      ...getCredentialsConfig(),
      headers: getAuthHeaders(token),
      body: body,
    });
  } catch (error) {
    throw new NetworkError("images");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }

  const data = await response.json();
  return data.url;
}

export interface ImageUpload {
  source: File;
  purpose: ImagePurpose | null;
}

export type ImagePurpose = "profile-picture" | "picture-question";
