import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button } from "@hundred5/design-system";
import {
  ExpertQuestionStatus,
  useReviewExpertQuestionMutation,
} from "../../utils/graphql-queries";
import { useFlashMessages } from "../../utils/flash-messages";
import RejectForm from "./reject-form";
import { Form, Formik } from "formik";
import { RichTextState } from "@togglhire/text-editor";
import AcceptCommentForm from "./accept-comment-form";

//region Component
interface Props {
  id: string;
  onSubmit: () => void;
}

export default function AcceptForm({ id, onSubmit }: Props) {
  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);
  const [isAcceptCommentFormOpen, setAcceptCommentFormOpen] = useState(false);
  const { addMessage } = useFlashMessages();
  const [_, reviewExpertQuestion] = useReviewExpertQuestionMutation();

  return (
    <Container>
      {isRejectFormOpen ? (
        <RejectForm
          id={id}
          onBack={() => setIsRejectFormOpen(false)}
          onSubmit={onSubmit}
        />
      ) : isAcceptCommentFormOpen ? (
        <AcceptCommentForm
          id={id}
          onBack={() => setAcceptCommentFormOpen(false)}
          onSubmit={onSubmit}
        />
      ) : (
        <Formik
          initialValues={{
            message: RichTextState.create(""),
          }}
          onSubmit={async (formikValues) => {
            const variables: Parameters<typeof reviewExpertQuestion>[0] = {
              id,
              status: ExpertQuestionStatus.Approved,
            };

            if (formikValues.message.markdown()) {
              variables.feedback = {
                message: formikValues.message.markdown(),
                types: [],
              };
            }

            await reviewExpertQuestion(variables);
            addMessage({ type: "question_accepted" });
            onSubmit();
          }}
        >
          <Form>
            <Buttons>
              <AcceptButton type="submit">Accept question</AcceptButton>
              <AcceptButton onClick={() => setAcceptCommentFormOpen(true)}>
                Accept with comments
              </AcceptButton>
              <RejectButton onClick={() => setIsRejectFormOpen(true)}>
                Request changes
              </RejectButton>
            </Buttons>
          </Form>
        </Formik>
      )}
    </Container>
  );
}
//endregion

//region Styles
const Container = styled.div`
  margin-top: 25px;
  padding: 0 20px 15px;

  @media (min-width: 768px) {
    padding: 0 40px 15px;
  }
`;

const Buttons = styled.div`
  margin-bottom: 25px;
  margin-left: -5px;
`;

const AcceptButton = styled(Button)`
  height: 26px;
  padding: 0 12px;
  background: #1dc488;
  margin: 0 5px;
`;

const RejectButton = styled(Button)`
  height: 26px;
  padding: 0 12px;
  background: #eb5757;
  margin: 0 5px;
`;
//endregion
