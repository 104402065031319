import React from "react";
import styled from "@emotion/styled";
import { add, parseISO, formatDistanceToNow } from "date-fns";
import {
  ExpertQuestionBatch,
  useAcceptExpertQuestionBatchReviewMutation,
} from "../../utils/graphql-queries";

//region Component
interface Props {
  expertQuestionBatch: Partial<ExpertQuestionBatch>;
}

export default function ExpertQuestionBatchForReview({
  expertQuestionBatch,
}: Props) {
  const [
    _,
    acceptBatchForReview,
  ] = useAcceptExpertQuestionBatchReviewMutation();

  const deadline = formatDistanceToNow(
    parseISO(expertQuestionBatch?.deadlineAt)
  );

  return (
    <Container>
      <Name>
        {expertQuestionBatch?.expertChallenge?.expertChallengeSkills
          .map((skill) => skill.skill.name)
          .join(", ")}{" "}
        ({expertQuestionBatch.id})
      </Name>
      <StatsContainer>
        <Stats>
          <div>
            Questions:{" "}
            <strong>
              {expertQuestionBatch.expertChallenge?.requiredQuestions}
            </strong>
          </div>
          <div>
            Deadline: <strong>{deadline}</strong>
          </div>
        </Stats>

        <ActionButton
          onClick={async () =>
            acceptBatchForReview({
              id: expertQuestionBatch.id!,
            })
          }
        >
          Review questions
        </ActionButton>
      </StatsContainer>
    </Container>
  );
}
//endregion

//region Styles
const ActionButton = styled.button`
  font-size: 10px;
  font-weight: bold;
  height: 25px;
  background: #2c1338;
  border-radius: 3px;
  border: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 10px;
  transition: all 0.2s ease-in-out;

  :active {
    transform: scale(0.96);
  }
`;

const Stats = styled.div`
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  text-transform: uppercase;
`;

const Name = styled.h2`
  margin-top: 36px;
  height: 50px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 165px;
  height: 145px;
  padding: 0 18px;
  text-align: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  transition: background-color 0.2s ease-in-out;
  background: #fefaee;

  &:hover {
    background: #fcefca;

    ${ActionButton} {
      opacity: 1;
    }

    ${Stats} {
      opacity: 0;
    }
  }

  &[disabled] {
    color: #828282;

    ${ActionButton} {
      background: #ffffff;
      color: #989898;
    }

    &:hover {
      background: #989898;

      ${Name} {
        color: #ffffff;
      }
    }
  }
`;

const StatsContainer = styled.div`
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
`;
//endregion
