import React, { ReactNode } from "react";
import { Field } from "@hundred5/design-system";
import { useField } from "formik";
import styled from "@emotion/styled";
import { Editor, MarkdownState, RichTextState } from "@togglhire/text-editor";
import { useImageUpload } from "../../hooks/image-upload";

interface Props {
  id: string;
  name: string;
  label: string;
  noteLabel?: string | ReactNode;
  required?: boolean;
}

export default function FormikMarkdownField(props: Props) {
  const [field, meta, { setValue }] = useField<RichTextState | MarkdownState>(
    props.name
  );

  const uploadImage = useImageUpload();

  return (
    <StyledField
      for={props.id}
      label={
        <>
          {props.label}{" "}
          {props.noteLabel && <NoteLabel>{props.noteLabel}</NoteLabel>}
        </>
      }
      required={props.required}
      error={meta.touched ? meta.error : undefined}
      input={
        <StyledEditor
          state={field.value}
          onChange={setValue}
          onBlur={field.onBlur}
          uploadImage={uploadImage}
          resizable={true}
        />
      }
    />
  );
}

const StyledField = styled(Field)`
  margin-bottom: 20px;
`;

const NoteLabel = styled.span`
  font-weight: normal;
  font-size: 0.85rem;
`;

const StyledEditor = styled(Editor)`
  border: 1px solid #e0e0e0;
  min-height: 125px;

  & > :first-child {
    padding: 10px 10px 0 10px;
  }

  & > :nth-child(2) {
    padding: 0 10px 10px 10px;
  }

  & > :nth-child(2) > div.ProseMirror-widget {
    left: 10px;
  }
`;
