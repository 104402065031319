import styled from "@emotion/styled";

interface ContainerProps {
  width?: number;
}

const Container = styled.div<ContainerProps>`
  background: #ffffff;
  max-width: ${(props) => `${props.width || 700}px`};
  width: 80%;
  padding: 30px;
  margin: 40px auto;
  border-radius: 3px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
`;

export default Container;
