import React from "react";
import {
  Column,
  Row,
  TableOptions,
  TableState,
  useSortBy,
  useTable,
} from "react-table";
import styled from "@emotion/styled";
import { TrashIcon } from "../../icons";

export type TableProps<D extends object> = {
  columns: Column<D>[];
  data: D[];
  getRowProps?: (row: Row<D>) => {};
  deleteRow?: (row: Row<D>) => void;
  tableOptions?: Partial<TableOptions<D>>;
  stateCallback?: (state: TableState<D>) => void;
};
export default function Table<D extends object>({
  columns,
  data,
  getRowProps,
  deleteRow,
  tableOptions,
  stateCallback,
}: TableProps<D>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable({ columns, data, ...tableOptions }, useSortBy);

  React.useEffect(() => {
    if (stateCallback) {
      stateCallback(state);
    }
  }, [state, stateCallback]);

  return (
    <TableStyles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            /* JSX key is provided by props function, eslint doesn't understand that */
            /* eslint-disable react/jsx-key */
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    { style: column.style },
                    column.getSortByToggleProps(),
                  ])}
                >
                  {column.isSorted && (
                    <span>{column.isSortedDesc ? "▼" : "▲"}</span>
                  )}

                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps(getRowProps ? getRowProps(row) : undefined)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps([{ style: cell.column.style }])}>
                      {cell.column.formatValue !== undefined
                        ? cell.column.formatValue(cell.row.original)
                        : cell.render("Cell")}
                    </td>
                  );
                })}
                {deleteRow && (
                  <td className="button">
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteRow(row);
                      }}
                    >
                      <TrashIcon />
                    </DeleteButton>
                  </td>
                )}
              </tr>
              /* eslint-enable react/jsx-key */
            );
          })}
          {/* Fill out table to 6 rows */}
          {rows.length < 6 &&
            [...Array(6 - rows.length)].map((_, i) => (
              <tr key={i}>
                {[
                  ...Array(
                    columns.length -
                      (state.hiddenColumns ? state.hiddenColumns?.length : 0)
                  ),
                ].map((_, j) => (
                  <td key={j}>&nbsp;</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </TableStyles>
  );
}

const TableStyles = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
  }

  tbody {
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :hover td.button {
        display: inherit;
      }

      :hover[role="row"] {
        background: #f2f2f6;
        cursor: pointer;
      }
    }
  }

  th,
  td {
    border: 1px solid #e7e7e7;
    padding: 0.5rem 1rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    svg {
      top: 0.125em;
      position: relative;
      margin-right: 0.25em;
    }
  }

  th {
    text-transform: uppercase;
    font-size: 10px;

    span {
      color: #ff897a;
      margin-right: 0.5em;
    }
  }

  td {
    border: 1px solid #e7e7e7;

    &.button {
      border: none;
      display: none;
      position: absolute;
      right: 15px;

      & button {
        top: 0.125em;
      }
    }
  }
`;

const DeleteButton = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  position: relative;
  top: 0.5em;
`;
