import querystring from "querystring";
import config from "../config";
import { NetworkError, parseApiError } from "./errors";
import { getAuthHeaders } from "./helpers";

interface LogInResponse {
  access_token: string;
}

export const logIn = async (
  email: string,
  password: string,
  captchaToken: string
): Promise<string> => {
  let response: Response;

  try {
    response = await fetch(`${config.apiUrl}/api/v1/auth/authenticate`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "captcha-token": captchaToken,
      },
      body: JSON.stringify({ email, password }),
    });
  } catch (error) {
    throw new NetworkError("auth/authenticate");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }

  const data: LogInResponse = await response.json();
  return data.access_token;
};

export async function signup(
  email: string,
  password: string,
  captchaToken: string
): Promise<void> {
  let response: Response;

  try {
    response = await fetch(`${config.apiUrl}/api/v1/auth/signup?app=expert`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "captcha-token": captchaToken,
      },
      body: JSON.stringify({ email, password }),
    });
  } catch (error) {
    throw new NetworkError("auth/signup");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}

export async function recordAcceptingTermsAndConditions(
  token: string,
  version: string
): Promise<void> {
  let response: Response;

  try {
    response = await fetch(`${config.apiUrl}/api/v1/terms-consent`, {
      method: "POST",
      headers: { "content-type": "application/json", ...getAuthHeaders(token) },
      body: JSON.stringify({ version }),
    });
  } catch (error) {
    throw new NetworkError("terms-consent");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}

export async function requestPasswordReset(
  email: string,
  captchaToken: string
): Promise<void> {
  let response: Response;

  try {
    response = await fetch(
      `${config.apiUrl}/api/v1/auth/password/forgot?app=expert`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "captcha-token": captchaToken,
        },
        body: JSON.stringify({ email }),
      }
    );
  } catch (error) {
    throw new NetworkError("auth/password/forgot");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}

export function thirdPartyAuth(
  variant: "signup" | "login",
  provider: "linkedin",
  termsVersion?: string
) {
  // window.location is used here as next/router cannot get or replace
  // the host part of the URL
  const { protocol, host } = window.location;
  const callbackUrl = `${protocol}//${host}/callback/${variant}`;
  const params: { "redirect-url": string; "terms-version"?: string } = {
    "redirect-url": callbackUrl,
  };
  if (termsVersion) {
    params["terms-version"] = termsVersion;
  }
  window.location.assign(
    `${config.apiUrl}/api/v1/auth/${provider}/user?${querystring.stringify(
      params
    )}`
  );
}

export async function resetPassword(
  email: string,
  token: string,
  password: string,
  captchaToken: string
): Promise<void> {
  let response: Response;

  try {
    response = await fetch(
      `${config.apiUrl}/api/v1/auth/password/reset?app=expert`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "captcha-token": captchaToken,
        },
        body: JSON.stringify({ email, token, password }),
      }
    );
  } catch (error) {
    throw new NetworkError("auth/password/reset");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}

export async function sendVerificationEmail(
  token: string | null,
  captchaToken: string
): Promise<void> {
  let response: Response;

  try {
    response = await fetch(
      `${config.apiUrl}/api/v1/auth/email/verify/send-email?app=expert`,
      {
        method: "POST",
        headers: {
          "captcha-token": captchaToken,
          ...getAuthHeaders(token),
        },
      }
    );
  } catch (error) {
    throw new NetworkError("auth/email/verify/send-email");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}

export async function verifyEmail(
  authToken: string | null,
  verificationToken: string,
  captchaToken: string
): Promise<void> {
  let response: Response;

  try {
    response = await fetch(`${config.apiUrl}/api/v1/auth/email/verify`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "captcha-token": captchaToken,
        ...getAuthHeaders(authToken),
      },
      body: JSON.stringify({ token: verificationToken }),
    });
  } catch (error) {
    throw new NetworkError("auth/email/verify");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}

export async function updatePassword(
  authToken: string | null,
  variables: { oldPassword: string; newPassword: string; captchaToken: string }
) {
  let response: Response;
  try {
    response = await fetch(`${config.apiUrl}/api/v1/auth/password/update`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "captcha-token": variables.captchaToken,

        ...getAuthHeaders(authToken),
      },
      body: JSON.stringify({
        old_password: variables.oldPassword,
        new_password: variables.newPassword,
      }),
    });
  } catch (error) {
    throw new NetworkError("auth/password/update");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}
