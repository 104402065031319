import React from "react";
import styled from "@emotion/styled";
import { XIcon } from "../../icons";
//region Component
interface Props {
  name: string;
  onDelete: () => void;
}

export default function ExpertTag({ name, onDelete }: Props) {
  return (
    <Container>
      <Name>{name}</Name>
      <Action>
        <XIcon onClick={onDelete} />
      </Action>
    </Container>
  );
}
//endregion

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  border-radius: 6px;
  user-select: none;
  white-space: nowrap;
  background: #f5f5f5;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  min-width: 25px;
  height: 100%;
  padding: 0 6px 0 9px;
  flex: 1;
  text-transform: uppercase;
  border-right: 1px solid #e7e7e7;
  font-size: 10px;
  font-weight: 500;
`;

const Action = styled.div`
  padding: 0 9px 0 6px;
  min-width: 25px;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;
