import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { Button, CloseModalButton, Modal } from "@hundred5/design-system";
import QuestionPreview from "./question-preview";
import AcceptForm from "./accept-form";
import QuestionComments from "../question-comments";
import {
  ExpertQuestionStatus,
  TestGenQuestion,
  useQuestionReviewModalQuery,
} from "../../utils/graphql-queries";
import {
  questionTypes,
  getDefaultQuestionDuration,
} from "../../utils/questions";
import { ViewMode } from "../../types/viewMode";
import Feedback from "../question-form/feedback";

//region Component
interface Props {
  viewMode?: ViewMode;
  open: boolean;
  id: string;
  onClose: () => void;
}

export default function QuestionReview({ viewMode, open, id, onClose }: Props) {
  const router = useRouter();
  const [{ data }] = useQuestionReviewModalQuery({
    variables: { id },
    pause: !open && !id,
  });

  return (
    <Modal open={open} onClose={onClose} size="large">
      <Header>
        Review a {data?.expertQuestion?.testGenQuestion?.skill?.name} question
        <EditButton
          variant="secondary"
          onClick={() =>
            router.push(
              viewMode === ViewMode.Admin
                ? `/admin/questions/${id}`
                : `/questions/${id}`
            )
          }
        >
          Edit
        </EditButton>
        <CloseButton onClick={onClose} />
      </Header>

      <Tags>
        <Tag>
          {
            (questionTypes as Record<any, string>)[
              data?.expertQuestion?.testGenQuestion.questionType
            ]
          }
        </Tag>
        <Tag>{data?.expertQuestion?.testGenQuestion.difficulty}</Tag>
        <Tag>
          {data?.expertQuestion?.testGenQuestion.durationInSeconds ||
            getDefaultQuestionDuration(
              data?.expertQuestion?.testGenQuestion?.questionType,
              data?.expertQuestion?.testGenQuestion.testType
            )}{" "}
          sec
        </Tag>
      </Tags>

      <QuestionPreview
        question={data?.expertQuestion?.testGenQuestion as TestGenQuestion}
      />

      <CommentsContainer>
        <QuestionComments questionId={id} />
      </CommentsContainer>

      {data?.expertQuestion?.status !== ExpertQuestionStatus.Draft ? (
        <AcceptForm id={id} onSubmit={onClose} />
      ) : null}
    </Modal>
  );
}
//endregion

//region Styles
const Header = styled.h1`
  margin: 0;
  padding: 25px 20px 5px;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    padding: 25px 40px 5px;
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 0 20px 15px;

  @media (min-width: 768px) {
    padding: 0 40px 15px;
  }
`;

const Tag = styled.div`
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #f5f5f5;
  text-transform: uppercase;
  margin-right: 10px;
  padding: 0 6px;
  font-size: 10px;
  font-weight: 500;
`;

const CommentsContainer = styled.div`
  & > * {
    border-top: 1px solid #e7e7e7;
    padding: 25px 20px 0;
    margin-bottom: 25px;

    @media (min-width: 768px) {
      padding: 25px 40px 0;
    }
  }
`;

const EditButton = styled(Button)`
  height: 30px;
  margin-left: 15px;
`;

const CloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
//endregion
