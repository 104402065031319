import React from "react";
import Table from "../table";
import { Column } from "react-table";
import {
  CheckmarkIcon,
  CodeIcon,
  CriticalIcon,
  FreeTextIcon,
  MultipleChoiceIcon,
  NumericIcon,
  PictureIcon,
  SingleChoiceIcon,
  WarningIcon,
} from "../../icons";
import { startCase, capitalize, last } from "lodash";
import { format, parseISO } from "date-fns";
import { ExpertQuestionBatch } from "../../utils/graphql-queries";
import { TableProps } from "../table/table";
import { useStoreSortState } from "../table/utils";
import ReactMarkdown from "react-markdown";
import stripMarkdown from "strip-markdown";

const typeIcons: { [index: string]: React.ReactNode } = {
  "single-choice": <SingleChoiceIcon />,
  "multiple-choice": <MultipleChoiceIcon />,
  "code-input": <CodeIcon />,
  "free-text": <FreeTextIcon />,
  "picture-question": <PictureIcon />,
  "numeric-input": <NumericIcon />,
};

const statusIcons: { [index: string]: React.ReactNode } = {
  Approved: <CheckmarkIcon color="#1DC488" />,
  Rejected: <CriticalIcon />,
  Feedback: <WarningIcon />,
  Submitted: <CheckmarkIcon />,
  Draft: <></>,
};

export interface TableQuestion {
  id: string;
  lastChanged: Date;
  description: React.ReactNode;
  difficulty: string;
  type: string;
  status: string;
  feedback: React.ReactNode;
}

const columns = [
  {
    Header: "Last Change",
    accessor: "lastChanged",
    style: { maxWidth: "125px" },
    sortType: "datetime",
    formatValue: (row: TableQuestion) => format(row.lastChanged, "dd/MM/yyyy"),
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Question Description",
    accessor: "description",
    style: { minWidth: "190px", maxWidth: "0", width: "75%" },
  },
  {
    Header: "Status",
    accessor: "status",
    style: { maxWidth: "125px" },
    formatValue: (row: TableQuestion) => (
      <>
        {statusIcons[row.status]} {row.status}
      </>
    ),
  },
  {
    Header: "Difficulty",
    accessor: "difficulty",
    style: { maxWidth: "100px" },
  },
  {
    Header: "Type",
    accessor: (row: TableQuestion) => startCase(row.type.replace("-", " ")),
    style: { maxWidth: "175px" },
    formatValue: (row: TableQuestion) => (
      <>
        {typeIcons[row.type]} {startCase(row.type.replace("-", " "))}
      </>
    ),
  },
  {
    Header: "Feedback",
    accessor: "feedback",
    style: { minWidth: "190px", maxWidth: "0", width: "25%" },
  },
];

const tableOptions = {
  initialState: { sortBy: [{ id: "lastChanged", desc: true }] },
  disableSortRemove: true,
  autoResetSortBy: false,
};

interface Props extends Partial<TableProps<TableQuestion>> {
  batch: ExpertQuestionBatch;
}
export default function QuestionBatchTable({ batch, ...tableProps }: Props) {
  const [sortState, sortStateCallback] = useStoreSortState("question_batch", {
    id: "lastChanged",
    desc: true,
  });
  const tableOptions = React.useMemo(
    () => ({
      initialState: { sortBy: sortState },
      disableSortRemove: true,
      autoResetSortBy: false,
    }),
    [sortState]
  );

  return (
    <Table
      columns={columns as Column<TableQuestion>[]}
      data={getQuestions(batch)}
      tableOptions={tableOptions}
      stateCallback={sortStateCallback}
      {...tableProps}
    />
  );
}

const getQuestions = (questionBatch: ExpertQuestionBatch): TableQuestion[] => {
  const baseQuestions = questionBatch.expertQuestions;
  const parsedQuestions = [];
  for (const question of baseQuestions) {
    const parsedQuestion = {
      id: question.id,
      feedback: (
        <ReactMarkdown
          disallowedTypes={["paragraph"]}
          unwrapDisallowed={true}
          plugins={[stripMarkdown]}
        >
          {(
            (question.comments?.count &&
              last(question.comments.nodes)?.message) ||
            ""
          ).replace(/\n/g, " ")}
        </ReactMarkdown>
      ),
      description: (
        <ReactMarkdown
          disallowedTypes={["paragraph"]}
          unwrapDisallowed={true}
          plugins={[stripMarkdown]}
        >
          {question.testGenQuestion.description.replace(/\n/g, " ")}
        </ReactMarkdown>
      ),
      type: question.testGenQuestion.questionType,
      difficulty: capitalize(question.testGenQuestion.difficulty),
      status: capitalize(question.status),
      lastChanged: parseISO(question.statusChangedAt),
    };
    parsedQuestions.push(parsedQuestion);
  }

  return parsedQuestions;
};
