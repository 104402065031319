import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { ExpertForm } from "../";
import {
  Expert,
  useFetchExpertQuery,
  useUpdateExpertMutation,
} from "../../utils/graphql-queries";
import { ExpertFormValues } from "../expert-form/expert-form";
import { useApiMutation } from "../../hooks/api-query";
import { useApiClient } from "../../utils/authentication";
import { useApiError } from "../../hooks/api-error";
import { useFlashMessages } from "../../utils/flash-messages";
import { FormikHelpers } from "formik";

//region Component
interface Props {
  id: string;
}

export default function ExpertUpdate({ id }: Props) {
  const router = useRouter();
  const { addMessage } = useFlashMessages();
  const [expertData] = useFetchExpertQuery({ variables: { id: id } });
  const [_, updateExpert] = useUpdateExpertMutation();
  const apiClient = useApiClient();
  const handleApiError = useApiError();
  const [updateEmail] = useApiMutation(apiClient.updateEmail, {
    throwOnError: true,
    onError: () => {},
  });
  if (!expertData.fetching && !expertData.data?.expert) {
    router.push("/admin");
    return null;
  }

  const onSubmit = async (
    expert: ExpertFormValues,
    _: FormikHelpers<ExpertFormValues>,
    initialValues: ExpertFormValues
  ) => {
    const skillIDs = expert.expertSkills.map((skill) => skill.id);
    const updateResult = await updateExpert({
      id: id,
      name: expert.fullName,
      linkedin: expert.linkedin,
      skills: skillIDs,
      isReviewer: expert.isReviewer === "true",
      status: expert.status,
      isActiveReviewer: expert.isActiveReviewer,
      isActiveExpert: expert.isActiveExpert,
    });
    if (!updateResult.error) {
      addMessage({ type: "expert_saved" });
    }
    if (initialValues.email !== expert.email) {
      try {
        await updateEmail({
          id: expertData.data?.expert?.user.id || "",
          email: expert.email,
        });
      } catch (error) {
        handleApiError(error);
        return;
      }
      addMessage({ type: "email_changed" });
    }

    router.push((router.query.returnURL || "/admin") as string);
  };

  return (
    <Container>
      <Header>
        <h1>Edit expert</h1>
      </Header>
      <ExpertForm
        expert={expertData.data?.expert}
        onSubmit={onSubmit}
        onCancel={() =>
          router.push((router.query.returnURL || "/admin") as string)
        }
      />
    </Container>
  );
}
//endregion

//region Styles
const Container = styled.article`
  width: auto;
  min-width: 500px;
  background: #ffffff;
  position: relative;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 36px;
  height: 80px;
  border-bottom: 1px solid #e7e7e7;
`;
//endregion
