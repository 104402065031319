import React from "react";
import { useField } from "formik";
import { Checkbox, InlineField } from "@hundred5/design-system";
import styled from "@emotion/styled";

type Props = {
  id: string;
  name: string;
  label: React.ReactNode;
  required?: boolean;
  hideErrorMessage?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
};

const FormikCheckboxField = (props: Props) => {
  const [field, meta] = useField<any>({ name: props.name, type: "checkbox" });
  const isControlledInput =
    props.onChange !== undefined && props.checked !== undefined;
  return (
    <StyledField
      for={props.id}
      required={props.required}
      label={props.label}
      error={meta.touched ? meta.error : undefined}
      input={
        <Checkbox
          type="checkbox"
          id={props.id}
          error={meta.touched && meta.error != null}
          {...field}
          {...(isControlledInput
            ? { checked: props.checked, onChange: props.onChange }
            : {})}
        />
      }
    />
  );
};

const StyledField = styled(InlineField)`
  [class*="Error"] {
    margin-top: 10px;
  }
`;

export default FormikCheckboxField;
