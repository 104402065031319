import React from "react";
import { useField } from "formik";
import SkillsDropdown from "../skills-dropdown";
import styled from "@emotion/styled";

export default function FormSkillsDropdown() {
  const [skillId, meta, helpers] = useField("skillId");

  return (
    <Container data-testid="qf-skill">
      <Label id="downshift-0-label">Skill</Label>
      <Error>{meta.error}</Error>
      <SkillsDropdown
        onSelect={(o) => (o ? helpers.setValue(o.id) : helpers.setValue(""))}
        initialSkillId={skillId.value}
        error={!!meta.error}
      />
    </Container>
  );
}

const Container = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 4;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
`;

const Error = styled.div`
  margin-bottom: 5px;
  font-size: 1rem;
  line-height: 1.5;
  color: #eb5757;
`;
