import { useCallback } from "react";
import { useFlashMessages } from "../utils/flash-messages";
import { useApiError } from "./api-error";
import { ImagePurpose } from "../api/images";
import { useApiClient } from "../utils/authentication";
import { useMutation } from "react-query";
import { checkClientError } from "../api/errors";

const MAX_FILE_SIZE = 5 * 1024 * 1024;

export const useImageUpload = () => {
  const api = useApiClient();
  const [uploadImage] = useMutation(api.uploadImage, { throwOnError: true });
  const handleApiError = useApiError();
  const { addMessage } = useFlashMessages();

  return useCallback(
    async (
      source: File,
      purpose: ImagePurpose | null = null
    ): Promise<string> => {
      if (source.size > MAX_FILE_SIZE) {
        addMessage({
          type: "image_upload_error",
          error: "file_too_large",
        });

        throw new Error("File is too large");
      }

      let url: string | undefined;

      try {
        url = await uploadImage({ source, purpose });
      } catch (error) {
        if (checkClientError(error, "FileTooLarge")) {
          addMessage({
            type: "image_upload_error",
            error: "file_too_large",
          });
        } else if (checkClientError(error, "UnsupportedFileFormat")) {
          addMessage({
            type: "image_upload_error",
            error: "unsupported_file_format",
          });
        } else {
          handleApiError(error);
        }

        throw new Error("Uploading failed");
      }

      if (url == null) {
        throw new Error("Upload image mutation did not return a URL");
      }

      return url;
    },
    [addMessage, handleApiError, uploadImage]
  );
};
