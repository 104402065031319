import React from "react";
import * as yup from "yup";
import Link from "next/link";
import { useRouter } from "next/router";
import { Formik, Form } from "formik";
import styled from "@emotion/styled";
import { Button } from "@hundred5/design-system";
import { LoginButton } from "@hundred5/candidate-components";
import { useLogin } from "../../utils/authentication";
import { useFlashMessages } from "../../utils/flash-messages";
import { checkClientError } from "../../api/errors";
import { useApiError } from "../../hooks/api-error";
import { FormikTextField } from "../";
import { thirdPartyAuth } from "../../api/authentication";
import { useGoogleReCaptchaToken } from "../../hooks/google-recaptcha";
import { fi } from "date-fns/locale";

const providerNames: { [index: string]: string } = {
  linkedin: "LinkedIn",
};

export default function LoginForm() {
  const logIn = useLogin();
  const { addMessage } = useFlashMessages();
  const handleApiError = useApiError();
  const router = useRouter();
  const { error, provider } = router.query;
  const parsedProvider = provider
    ? typeof provider === "string"
      ? provider
      : provider[0]
    : "";
  const {
    googleRecaptchaToken,
    refreshGoogleRecaptchaToken,
  } = useGoogleReCaptchaToken("login");

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, form) => {
        if (googleRecaptchaToken === null) {
          return;
        }
        try {
          await logIn(values.email, values.password, googleRecaptchaToken);
        } catch (error) {
          if (checkClientError(error, "InvalidEmailOrPassword")) {
            form.setErrors({
              email: "",
              password: "Sorry, you entered an incorrect email or password.",
            });
          } else if (checkClientError(error, "AccountSuspended")) {
            form.setFieldError(
              "email",
              "Sorry, your account has been suspended."
            );
          } else {
            handleApiError(error);
          }

          return;
        } finally {
          refreshGoogleRecaptchaToken();
        }

        addMessage({ type: "logged_in" });
      }}
    >
      {(props) => (
        <>
          <h1>Log in</h1>
          {error === "UserNotFound" ? (
            <ErrorMessage>
              There is no user associated with this{" "}
              {providerNames[parsedProvider]} account. Please{" "}
              <Link href="/signup">create an account</Link> first.
            </ErrorMessage>
          ) : null}
          <LoginButton
            variant="login"
            method="linkedin"
            onClick={() => thirdPartyAuth("login", "linkedin")}
          />
          <Separator>Or, log in with your email</Separator>
          <Form>
            <FormikTextField
              id="email"
              name="email"
              type="email"
              label="Email:"
              required
            />
            <FormikTextField
              id="password"
              name="password"
              type="password"
              label="Password:"
              required
            />

            <SubmitButton
              type="submit"
              disabled={props.isSubmitting || !googleRecaptchaToken}
              variant="secondary"
            >
              {props.isSubmitting ? "Logging in..." : "Log in"}
            </SubmitButton>
          </Form>

          <ForgottenPasswordWrapper>
            <Link href="/forgotten-password">Forgot your password?</Link>
          </ForgottenPasswordWrapper>
        </>
      )}
    </Formik>
  );
}

const SubmitButton = styled(Button)`
  margin-top: 20px;
  display: block;
`;

const ForgottenPasswordWrapper = styled.div`
  position: absolute;
  bottom: 15px;

  a {
    font-size: 12px;
  }
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 24px 0;
  color: #989898;
  font-size: 10px;

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #e7e7e7;
  }

  &:before {
    margin-right: 0.25em;
  }

  &:after {
    margin-left: 0.25em;
  }
`;

const ErrorMessage = styled.div`
  padding: 10px 20px;
  line-height: 21px;
  color: #eb5757;
  background-color: rgba(235, 87, 87, 0.08);
  margin-bottom: 20px;
`;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email cannot be empty")
    .email("Enter a valid email"),
  password: yup.string().required("Password cannot be empty"),
});
