import React from "react";
import styled from "@emotion/styled";
import { LinkButton } from "@hundred5/design-system";
import { AuthPage } from "..";
import { useLogout } from "../../utils/authentication";

interface Props {
  email: string;
}

export default function AccountSuspended() {
  const logOut = useLogout();
  return (
    <AuthPage
      content={
        <>
          <h1>Account suspended</h1>
          <Message>
            Please contact{" "}
            <a href={`mailto:help@hire.toggl.com`}>help@hire.toggl.com</a>.
          </Message>
        </>
      }
      footer={<BackButton onClick={() => logOut()}>‹ Go back</BackButton>}
    />
  );
}

const Message = styled.p`
  margin: 20px 0;
`;

const BackButton = styled(LinkButton)`
  font-size: 14px;
`;
