import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { cleanGoogleRecaptcha, injectGoogleReCaptchaScript } from ".";
import config from "../../config";

interface IGoogleReCaptchaConsumerProps {
  executeRecaptcha?: (action?: string) => Promise<string>;
}

export const GoogleReCaptchaContext = createContext<
  IGoogleReCaptchaConsumerProps
>({
  executeRecaptcha: () => {
    throw Error("GoogleReCaptcha Context has not yet been implemented");
  },
});

export function GoogleReCaptchaProvider({ children }: { children: ReactNode }) {
  const [greCaptchaInstance, setGreCaptchaInstance] = useState<null | {
    execute: Function;
  }>(null);

  useEffect(() => {
    if (!config.recaptchaSiteKey) {
      throw new Error("<GoogleReCaptchaProvider /> recaptcha key not provided");
    }

    const onLoad = () => {
      if (!window || !window.grecaptcha) {
        throw Error(
          `<GoogleRecaptchaProvider /> Recaptcha script is not available`
        );
      }

      const grecaptcha = window.grecaptcha;

      grecaptcha.ready(() => {
        setGreCaptchaInstance(grecaptcha);
      });
    };

    injectGoogleReCaptchaScript({
      onLoad,
    });

    return () => {
      cleanGoogleRecaptcha();
    };
  }, []);

  const executeRecaptcha = useCallback(
    (action?: string) => {
      if (!greCaptchaInstance || !greCaptchaInstance.execute) {
        throw new Error(
          "<GoogleReCaptchaProvider /> Google Recaptcha has not been loaded"
        );
      }

      return greCaptchaInstance.execute(config.recaptchaSiteKey, {
        action,
      });
    },
    [greCaptchaInstance]
  );

  const googleReCaptchaContextValue = useMemo(
    () => ({
      executeRecaptcha: greCaptchaInstance ? executeRecaptcha : undefined,
    }),
    [executeRecaptcha, greCaptchaInstance]
  );

  return (
    <GoogleReCaptchaContext.Provider value={googleReCaptchaContextValue}>
      {children}
    </GoogleReCaptchaContext.Provider>
  );
}
