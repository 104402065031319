import styled from "@emotion/styled";
import { useState } from "react";
import { CreateChallengeButton } from "../";
import {
  AdminChallengeListActiveDocument,
  AdminChallengeListArchivedDocument,
  AdminChallengeListInactiveDocument,
  AdminChallengeListInProgressBatchesDocument,
  AdminChallengeListInReviewBatchesDocument,
  AdminChallengeListNeedsActionBatchesDocument,
  AdminChallengeListNeedsActionOldBatchesDocument,
  AdminChallengeListSearchBatchesDocument,
  AdminChallengeListSearchDocument,
} from "../../utils/graphql-queries";
import SearchInput from "../seaerch-input";
import List from "./list";

const OFFSET = 0;
const LIMIT = 20;

//region Component
export default function AdminChallengeList() {
  const [searchVal, setSearchVal] = useState<string | undefined>(undefined);

  return (
    <Container>
      <StyledSearch
        placeholder="Find challenge"
        onSearch={(value) => {
          setSearchVal(value);
        }}
      />

      {searchVal ? (
        <>
          <List
            type="challenge"
            title="Search result - Challenges"
            emptyState="No results found"
            queryArgs={{
              query: AdminChallengeListSearchDocument,
              variables: { offset: OFFSET, limit: LIMIT, query: searchVal },
              getDataCallback: (result) =>
                result?.data?.admin.expertChallengesList?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.expertChallengesList?.count,
            }}
          />

          <List
            type="batch"
            title="Search result - Batches"
            emptyState="No results found"
            queryArgs={{
              query: AdminChallengeListSearchBatchesDocument,
              variables: { offset: OFFSET, limit: LIMIT, query: searchVal },
              getDataCallback: (result) =>
                result?.data?.admin.expertQuestionBatchesList?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.expertQuestionBatchesList?.count,
            }}
          />
        </>
      ) : (
        <>
          <List
            type="challenge"
            title="Active challenges"
            emptyState="No active challenges"
            firstElement={<CreateChallengeButton />}
            queryArgs={{
              query: AdminChallengeListActiveDocument,
              variables: { offset: OFFSET, limit: LIMIT, query: searchVal },
              getDataCallback: (result) =>
                result?.data?.admin.activeChallenges?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.activeChallenges?.count,
            }}
          />

          <List
            type="batch"
            title="Action required - Batches"
            emptyState="No batches need action"
            queryArgs={{
              query: AdminChallengeListNeedsActionBatchesDocument,
              variables: {
                offset: OFFSET,
                limit: LIMIT,
                query: searchVal,
              },
              getDataCallback: (result) =>
                result?.data?.admin.needsActionBatches?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.needsActionBatches?.count,
            }}
          />

          <List
            type="batch"
            title="Action required - Old batches"
            emptyState="No batches need action"
            queryArgs={{
              query: AdminChallengeListNeedsActionOldBatchesDocument,
              variables: {
                offset: OFFSET,
                limit: LIMIT,
                query: searchVal,
              },
              getDataCallback: (result) =>
                result?.data?.admin.needsActionBatches?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.needsActionBatches?.count,
            }}
          />

          <List
            type="batch"
            title="Batches in review"
            emptyState="No batches in review"
            queryArgs={{
              query: AdminChallengeListInReviewBatchesDocument,
              variables: {
                offset: OFFSET,
                limit: LIMIT,
                query: searchVal,
              },
              getDataCallback: (result) =>
                result?.data?.admin.inReviewBatches?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.inReviewBatches?.count,
            }}
          />
          <List
            type="batch"
            title="Batches in progress"
            emptyState="No batches in progress"
            queryArgs={{
              query: AdminChallengeListInProgressBatchesDocument,
              variables: {
                offset: OFFSET,
                limit: LIMIT,
                query: searchVal,
              },
              getDataCallback: (result) =>
                result?.data?.admin.inProgressBatches?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.inProgressBatches?.count,
            }}
          />

          <List
            type="challenge"
            title="Inactive challenges"
            emptyState="No inactive challenges"
            queryArgs={{
              query: AdminChallengeListInactiveDocument,
              variables: { offset: OFFSET, limit: LIMIT, query: searchVal },
              getDataCallback: (result) =>
                result?.data?.admin.inactiveChallenges?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.inactiveChallenges?.count,
            }}
          />

          <List
            type="challenge"
            title="Archived challenges"
            emptyState="No archived challenges"
            collapsible
            queryArgs={{
              query: AdminChallengeListArchivedDocument,
              variables: { offset: OFFSET, limit: LIMIT, query: searchVal },
              getDataCallback: (result) =>
                result?.data?.admin.archivedChallenges?.nodes,
              getTotalCountCallback: (result) =>
                result?.data?.admin.archivedChallenges?.count,
            }}
          />
        </>
      )}
    </Container>
  );
}
//endregion

//region Styles
const Container = styled.div`
  padding: 24px;
`;

const StyledSearch = styled(SearchInput)`
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 20px;
  width: 350px;
`;

//endregion
