import React from "react";

export default function MultipleChoiceIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 0H12.25C13.2125 0 14 0.7875 14 1.75V12.25C14 13.2125 13.2125 14 12.25 14H1.75C0.7875 14 0 13.2125 0 12.25V1.75C0 0.7875 0.7875 0 1.75 0ZM3.15 7L6.125 9.975L10.85 5.25L9.625 4.025L6.125 7.525L4.375 5.775L3.15 7Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}
