import React from "react";
import { useField } from "formik";
import { TextInput, Radio } from "@hundred5/design-system";
import styled from "@emotion/styled";
import { getDefaultQuestionDuration } from "../../utils/questions";
import { TestType } from "../../utils/graphql-queries";

interface Props {
  isReviewer?: boolean;
  testType?: TestType | null | undefined;
}

const DurationField: React.FC<Props> = ({
  isReviewer = false,
  testType = TestType.Quiz,
}) => {
  const [settingField, settingMeta, settingHelpers] = useField(
    "durationSetting"
  );
  const [durationField, durationMeta] = useField("duration");
  const [typeField] = useField("type");

  return (
    <div>
      <Label id="question-duration">Time allocation</Label>

      {settingMeta.touched && settingMeta.error != null ? (
        <Error>{settingMeta.error}</Error>
      ) : null}
      {durationMeta.touched && durationMeta.error != null ? (
        <Error>{durationMeta.error}</Error>
      ) : null}

      {isReviewer ? (
        <div role="group" aria-labelledby="question-duration">
          <DefaultOption>
            <SettingRadio value="default" />
            <span>Default</span>
          </DefaultOption>
          <CustomOption role="group">
            <SettingRadio value="custom" label="custom" />
            <DurationInput
              {...durationField}
              aria-label="duration in seconds"
              type="number"
              value={settingField.value === "custom" ? durationField.value : ""}
              onFocus={() => {
                settingHelpers.setValue("custom");
              }}
            />
            <span>s</span>
          </CustomOption>
        </div>
      ) : (
        <CustomOption>
          <DurationInput
            {...durationField}
            aria-labelledby="question-duration"
            type="number"
            value={
              settingField.value === "custom"
                ? durationField.value
                : getDefaultQuestionDuration(typeField.value, testType)
            }
            disabled={true}
            title={"Only reviewers or admins can edit the time"}
          />
          <span>s</span>
        </CustomOption>
      )}
    </div>
  );
};

export default DurationField;

const SettingRadio: React.FC<{ value: string; label?: string }> = (props) => {
  const [field] = useField({
    name: "durationSetting",
    value: props.value,
    type: "radio",
  });

  return <Radio {...field} type="radio" aria-label={props?.label} />;
};

const Label = styled.label`
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
`;

const Error = styled.div`
  margin-bottom: 5px;
  font-size: 1rem;
  line-height: 1.5;
  color: #eb5757;
`;

const DefaultOption = styled.label`
  display: grid;
  margin-bottom: 5px;
  grid-template-columns: min-content max-content;
  grid-column-gap: 5px;
  align-items: center;
`;

const CustomOption = styled.div`
  display: grid;
  margin-bottom: 5px;
  grid-template-columns: min-content 60px min-content;
  grid-column-gap: 5px;
  align-items: center;
`;

const DurationInput = styled(TextInput)`
  padding: 3px 3px 3px 7px;
  width: 60px;

  &:disabled,
  &:disabled + span {
    color: #a0a0a0;
  }
`;
