import React from "react";
import styled from "@emotion/styled";
import Preview from "./preview";
import { Form } from "formik";
import { FormikMarkdownField } from "../";
import TypeField from "./type-field";
import DifficultyField from "./difficulty-field";
import DurationField from "./duration-field";
import AnswerField from "./answer-field";
import FormSkillsDropdown from "./form-skills-dropdown";
import Feedback from "./feedback";
import { TestGenQuestionData } from "./update-test-gen-question-form";
import { ExpertQuestionData } from "./update-question-form";
import { TestType } from "../../utils/graphql-queries";

export type OldTestGenQuestionData = NonNullable<
  ExpertQuestionData["testGenQuestion"]["oldTestGenQuestion"]
>;

interface Props {
  title: string;
  actions?: React.ReactNode;
  comments?: React.ReactNode;
  footer: React.ReactNode;
  isReviewer?: boolean;
  testGenQuestion?: TestGenQuestionData;
  oldTestGenQuestion?: OldTestGenQuestionData;
  testType?: TestType;
}

const QuestionForm: React.FC<Props> = (props) => {
  return (
    <Form>
      <Container>
        <Editor>
          <Header>
            <Title>{props.title}</Title>
            {props.actions != null ? <Actions>{props.actions}</Actions> : null}
          </Header>
          {(props.testGenQuestion?.flaggedAt || props.oldTestGenQuestion) && (
            <Feedback
              testGenQuestion={
                props.testGenQuestion || props.oldTestGenQuestion
              }
            />
          )}
          <FieldColumns>
            <DifficultyField />
            <TypeField />
            <DurationField
              isReviewer={props.isReviewer}
              testType={props.testType || props.testGenQuestion?.testType}
            />
            {props.isReviewer && <FormSkillsDropdown />}
          </FieldColumns>
          <Separator />
          <FormikMarkdownField
            id="description"
            name="description"
            label="Question"
          />
          <AnswerField />
          <Separator />
          <FormikMarkdownField
            id="notes"
            name="notes"
            label="Notes"
            noteLabel={
              <div>
                (How to evaluate the answers for hiring managers, not visible to
                candidates. <br />
                <NoteLabelEmphasis>
                  NO internal communication here
                </NoteLabelEmphasis>
                .)
              </div>
            }
          />
          <Separator />
          {props.comments ? (
            <>
              {props.comments}
              {!props.isReviewer && (
                <FormikMarkdownField id="reply" name="reply" label="" />
              )}
              <Separator />
            </>
          ) : null}
          <Footer>{props.footer}</Footer>
        </Editor>
        <Preview />
      </Container>
    </Form>
  );
};

export default QuestionForm;

const Container = styled.div`
  padding: 40px;
  display: grid;
  grid-template-columns: minmax(400px, 550px) minmax(500px, 690px);
  grid-column-gap: 40px;
  justify-content: center;
  align-items: start;
`;

const Header = styled.div`
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 22px;
`;

const Actions = styled.div`
  margin-left: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: 10px;
`;

const Editor = styled.div`
  padding: 25px 40px;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;

const FieldColumns = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin-bottom: 20px;
`;

const Footer = styled.div`
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  justify-content: end;
  margin-top: 30px;
`;

const Separator = styled.div`
  border-top: 1px solid #e7e7e7;
  margin: 0 -40px 20px;
`;

const NoteLabelEmphasis = styled.span`
  color: #eb5757;
  font-weight: 600;
`;
