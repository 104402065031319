import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { Notification } from "@hundred5/design-system";
import Link from "next/link";
import { FlashMessage, useFlashMessages } from "../../utils/flash-messages";

export default function FlashMessages() {
  const { messages } = useFlashMessages();

  return (
    <Container>
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: grid;
  grid-row-gap: 20px;
`;

interface MessageProps {
  message: FlashMessage;
}

const Message = (props: MessageProps) => {
  const { id, content } = props.message;
  const { removeMessage } = useFlashMessages();
  const close = useCallback(() => removeMessage(id), [id, removeMessage]);

  React.useEffect(() => {
    const timeout = setTimeout(() => close(), 5000);
    return () => clearTimeout(timeout);
  }, [close]);

  switch (content.type) {
    case "server_error":
      return (
        <Notification
          type="failure"
          title="Server error"
          content={
            <>
              Looks like something went wrong. We've been notified, but please
              contact us at {emailLink} if the problem continues.
            </>
          }
          onClose={close}
        />
      );

    case "network_error":
      return (
        <Notification
          type="failure"
          title="Network error"
          content="There's a network disconnect. Try checking your network connection."
          onClose={close}
        />
      );

    case "client_error":
      return (
        <Notification
          type="failure"
          title="Client error"
          content={
            <>
              Something went wrong. Please contact us at {emailLink}.<br />
              <code>{content.error.type}</code>
            </>
          }
          onClose={close}
        />
      );

    case "forbidden_error":
      return (
        <Notification
          type="failure"
          title="Not allowed"
          content="Sorry, you are not allowed to access this. Try going back or starting from scratch."
          onClose={close}
        />
      );

    case "not_found_error":
      return (
        <Notification
          type="failure"
          title="Not found"
          content="Sorry, we could not find this. Try going back or starting from scratch."
          onClose={close}
        />
      );

    case "invalid_input_error":
      return (
        <Notification
          type="failure"
          title="Something is wrong"
          content={
            <>
              Sorry, something does not seem right. Check for any errors or
              contact us at {emailLink}.
            </>
          }
          onClose={close}
        />
      );

    case "logged_in":
      return <Notification type="success" title="Logged in!" onClose={close} />;

    case "verification_email_sent":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Verification email has been sent!</p>}
          onClose={close}
        />
      );

    case "email_verification": {
      const { error } = content;

      if (error === "already_verified") {
        return (
          <Notification
            type="failure"
            title="Already verified"
            content={<p>Your email address has already been verified.</p>}
            onClose={close}
          />
        );
      } else if (error === "invalid_token") {
        return (
          <Notification
            type="failure"
            title="Invalid confirmation link"
            content={
              <p>Confirmation link you supplied is invalid or expired.</p>
            }
            onClose={close}
          />
        );
      } else {
        return (
          <Notification
            type="success"
            title="Email verified"
            content={<p>Your email address has been successfully verified.</p>}
            onClose={close}
          />
        );
      }
    }

    case "third_party_login_error": {
      const { error, meta } = content;
      const providerNames: { [index: string]: string } = {
        linkedin: "LinkedIn",
      };

      if (error === "RequestTimeout") {
        return (
          <Notification
            type="failure"
            title="Something went wrong"
            content={
              <p>
                Sorry, logging in took longer than we expected. Please try
                again, or contact us at {emailLink} if the problem continues.
              </p>
            }
            onClose={close}
          />
        );
      } else if (error === "EmailNotProvided") {
        return (
          <Notification
            type="failure"
            title="Email missing"
            content={
              <p>
                Please add a verified email address to your{" "}
                {meta ? providerNames[meta.provider] : ""} account and try
                again.
              </p>
            }
            onClose={close}
          />
        );
      } else if (error === "StatusNotOK" || error === "BadRequest") {
        return (
          <Notification
            type="failure"
            title="Something went wrong"
            content={
              <p>
                Sorry, something went wrong. Please try again, or contact us at{" "}
                {emailLink} if the problem continues.
              </p>
            }
            onClose={close}
          />
        );
      } else if (error === "UserNotUsingProvider") {
        return (
          <Notification
            type="failure"
            title="User Already Exists"
            content={
              <p>
                You have previously signed up using an email address and
                password. Please <Link href="/">Log In</Link>.
              </p>
            }
            onClose={close}
          />
        );
      } else {
        return null;
      }
    }
    case "password_changed":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Password updated successfully!</p>}
          onClose={close}
        />
      );
    case "close_account":
      const { success, error } = content;
      if (success) {
        return (
          <Notification
            type="success"
            title="Success"
            content={<p>Account closed successfully!</p>}
            onClose={close}
          />
        );
      } else {
        if (error === "workspace_owner") {
          return (
            <Notification
              type="failure"
              title="Unable to Close Account"
              content={
                <p>
                  Please delete or transfer ownership of your workspaces in{" "}
                  <a href="https://hire.toggl.com/admin">Toggl Hire</a> before
                  closing your account.
                </p>
              }
              onClose={close}
            />
          );
        } else {
          return (
            <Notification
              type="failure"
              title="Something went wrong"
              content={
                <p>
                  Sorry, something went wrong. Please try again, or contact us
                  at {emailLink} if the problem continues.
                </p>
              }
              onClose={close}
            />
          );
        }
      }

    case "question_saved":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Question saved!</p>}
          onClose={close}
        />
      );

    case "question_submitted":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Question submitted!</p>}
          onClose={close}
        />
      );

    case "question_deleted":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Question deleted!</p>}
          onClose={close}
        />
      );

    case "image_upload_error": {
      if (content.error === "unsupported_file_format") {
        return (
          <Notification
            type="failure"
            title="Unsupported format"
            content="Oops! We don't support that image format. Make sure you're using JPEG or PNG."
            onClose={close}
          />
        );
      } else if (content.error === "file_too_large") {
        return (
          <Notification
            type="failure"
            title="Unsupported file size"
            content="Oops! The image size is too large. Make sure it’s less than 5 MB—try resizing it."
            onClose={close}
          />
        );
      } else {
        return null;
      }
    }
    case "email_changed":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Email updated successfully!</p>}
          onClose={close}
        />
      );
    case "expert_saved":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Changes to expert saved successfully!</p>}
          onClose={close}
        />
      );
    case "expert_created":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Expert created successfully!</p>}
          onClose={close}
        />
      );
    case "challenge_saved":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Changes to challenge saved successfully!</p>}
          onClose={close}
        />
      );
    case "challenge_created":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Challenge created successfully!</p>}
          onClose={close}
        />
      );
    case "challenge_deleted":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Challenge deleted successfully!</p>}
          onClose={close}
        />
      );
    case "challenge_archived":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Challenge archived successfully!</p>}
          onClose={close}
        />
      );
    case "challenge_activated":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Challenge activated successfully!</p>}
          onClose={close}
        />
      );
    case "question_accepted":
      return (
        <Notification
          type="success"
          title="Question accepted"
          content={<p>This question has been accepted!</p>}
          onClose={close}
        />
      );
    case "question_rejected":
      return (
        <Notification
          type="success"
          title="Question rejected"
          content={<p>You rejected this question!</p>}
          onClose={close}
        />
      );
    case "question_review_requested":
      return (
        <Notification
          type="success"
          title="Review requested"
          content={<p>You requested this question to be reviewed!</p>}
          onClose={close}
        />
      );
    case "question_batch_reviewed":
      return (
        <Notification
          type="success"
          title="Question batch submitted"
          content={<p>This question batch has been submitted!</p>}
          onClose={close}
        />
      );
    case "question_batch_rejected":
      return (
        <Notification
          type="success"
          title="Question batch rejected"
          content={<p>This question batch has been rejected!</p>}
          onClose={close}
        />
      );
    case "question_batch_submitted":
      return (
        <Notification
          type="success"
          title={
            content.amountRequired
              ? `${content.amountRequired} ${
                  content.skill || ""
                } questions submitted`
              : "Question batch submitted"
          }
          content={
            content.amountRequired ? (
              `Thank you for submitting the ${content.amountRequired} ${
                content.skill || ""
              } Questions batch. A reviewer will review the questions within the next 7 days.`
            ) : (
              <p>This question batch has been submitted for review!</p>
            )
          }
          onClose={close}
        />
      );
    case "question_batch_deleted":
      return (
        <Notification
          type="success"
          title="Question batch deleted"
          content={<p>This question batch has been deleted!</p>}
          onClose={close}
        />
      );
    case "question_batch_reminder":
      return (
        <Notification
          type="warning"
          title="Reminder"
          content={
            <p>
              Now that you are done with the review, don't forget to
              accept/reject the whole batch.
            </p>
          }
          onClose={close}
        />
      );
    case "question_comment_updated":
      return (
        <Notification
          type="success"
          title="Question comment updated"
          content={<p>This question comment has been updated!</p>}
          onClose={close}
        />
      );
    case "review_batch_created":
      return (
        <Notification
          type="success"
          title="Success"
          content={<p>Batch created successfully!</p>}
          onClose={close}
        />
      );
    default:
      return null;
  }
};

const emailLink = (
  <a href="mailto:support@hire.toggl.com">support@hire.toggl.com</a>
);
