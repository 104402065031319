import React from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { ExpertForm } from "../";
import { useCreateExpertByEmailMutation } from "../../utils/graphql-queries";
import { ExpertFormValues } from "../expert-form/expert-form";
import { FormikHelpers } from "formik";
import { hasGraphQLError } from "../../utils/graphql-errors";
import { useFlashMessages } from "../../utils/flash-messages";

//region Component
export default function ExpertCreate() {
  const router = useRouter();
  const { addMessage } = useFlashMessages();

  const [_, createExpert] = useCreateExpertByEmailMutation();

  const onSubmit = async (
    expert: ExpertFormValues,
    formikHelpers: FormikHelpers<ExpertFormValues>
  ) => {
    const skillIDs = expert.expertSkills.map((skill) => skill.id);
    const newExpert = await createExpert({
      email: expert.email,
      name: expert.fullName,
      linkedin: expert.linkedin,
      skills: skillIDs,
      isReviewer: expert.isReviewer === "true",
      status: expert.status,
    });
    if (newExpert.error && hasGraphQLError(newExpert.error, "NotFound")) {
      formikHelpers.setFieldError(
        "email",
        "Email does not belong to a Toggl Hire user."
      );
      return;
    }
    if (newExpert.error && hasGraphQLError(newExpert.error, "Forbidden")) {
      formikHelpers.setFieldError("email", "Expert profile exists already.");
      return;
    }
    addMessage({ type: "expert_created" });
    router.push((router.query.returnURL || "/admin") as string);
  };
  return (
    <Container>
      <Header>
        <h1>Add expert</h1>
      </Header>
      <ExpertForm
        onSubmit={onSubmit}
        onCancel={() =>
          router.push((router.query.returnURL || "/admin") as string)
        }
      />
    </Container>
  );
}
//endregion

//region Styles
const Container = styled.article`
  width: 500px;
  background: #ffffff;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 36px;
  height: 80px;
  border-bottom: 1px solid #e7e7e7;
`;
//endregion
