import React from "react";

export default function CriticalIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4727 9.0599C10.4727 9.21485 10.4089 9.36979 10.2995 9.47917L9.47917 10.2995C9.36979 10.4089 9.21484 10.4727 9.0599 10.4727C8.90495 10.4727 8.75911 10.4089 8.64974 10.2995L7 8.64974L5.35026 10.2995C5.24089 10.4089 5.09505 10.4727 4.9401 10.4727C4.78516 10.4727 4.63021 10.4089 4.52083 10.2995L3.70052 9.47917C3.59115 9.36979 3.52734 9.21485 3.52734 9.0599C3.52734 8.90495 3.59115 8.75912 3.70052 8.64974L5.35026 7L3.70052 5.35026C3.59115 5.24089 3.52734 5.09505 3.52734 4.9401C3.52734 4.78516 3.59115 4.63021 3.70052 4.52083L4.52083 3.70052C4.63021 3.59115 4.78516 3.52734 4.9401 3.52734C5.09505 3.52734 5.24089 3.59115 5.35026 3.70052L7 5.35026L8.64974 3.70052C8.75911 3.59115 8.90495 3.52734 9.0599 3.52734C9.21484 3.52734 9.36979 3.59115 9.47917 3.70052L10.2995 4.52083C10.4089 4.63021 10.4727 4.78516 10.4727 4.9401C10.4727 5.09505 10.4089 5.24089 10.2995 5.35026L8.64974 7L10.2995 8.64974C10.4089 8.75912 10.4727 8.90495 10.4727 9.0599ZM14 7C14 3.13542 10.8646 0 7 0C3.13542 0 0 3.13542 0 7C0 10.8646 3.13542 14 7 14C10.8646 14 14 10.8646 14 7Z"
        fill="#EB5757"
      />
    </svg>
  );
}
