import * as React from "react";

function ProfileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      {...props}
    >
      <path
        strokeWidth={1.5}
        d="M10.5 15.5S9 15 9 12.5c-.8 0-.8-2 0-2 0-.3-1.5-4 1-3.5.5-2 6-2 6.5 0 .3 1.4-.5 3.3-.5 3.5.8 0 .8 2 0 2 0 2.5-1.5 3-1.5 3V18c2.5.9 4.9 1.7 6.2 2.6 2-2.1 3.3-4.9 3.3-8.1C24 6.1 18.9 1 12.5 1S1 6.1 1 12.5c0 3.2 1.3 6 3.3 8.1 1.3-.9 4-1.7 6.2-2.6v-2.5zm-6.2 5.1c2.1 2.1 5 3.4 8.2 3.4 3.2 0 6.1-1.3 8.2-3.4"
        stroke="#ff897a"
        fill="none"
      />
    </svg>
  );
}

export default ProfileIcon;
