import React from "react";
import {
  ExpertQuestionBatch as ExpertQuestionBatchType,
  ExpertQuestionBatchStatus,
} from "../../utils/graphql-queries";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { format, formatDistanceToNow, isPast, parseISO } from "date-fns";
import { Button, LinkButton } from "@hundred5/design-system";
import { useRouter } from "next/router";
import { testTypes } from "../../utils/test-types";

interface ExpertQuestionBatchProps {
  expertQuestionBatch: ExpertQuestionBatchType;
  forReview?: boolean;
  disabled?: boolean;
}

export default function ExpertQuestionBatch({
  expertQuestionBatch,
  forReview,
  disabled = false,
}: ExpertQuestionBatchProps) {
  const router = useRouter();

  return (
    <Container
      status={expertQuestionBatch.status}
      forReview={forReview}
      disabled={disabled}
      onClick={() => {
        router.push(`/batches/${expertQuestionBatch.id}`);
      }}
    >
      <Header>
        <Status
          status={expertQuestionBatch.status}
          disabled={disabled}
          color={getStatusColor(expertQuestionBatch)}
        >
          {getStatusString(expertQuestionBatch)}
        </Status>
        <h2>
          {expertQuestionBatch.expertChallenge.expertChallengeSkills
            .map((skill) => skill.skill.name)
            .join(", ")}{" "}
          {forReview ? `(${expertQuestionBatch.id})` : null}
        </h2>
        <HeaderQuestions>
          Questions: <strong>{expertQuestionBatch.requiredQuestions}</strong>
        </HeaderQuestions>
        <HeaderQuestions>
          Test type:{" "}
          <strong>
            {expertQuestionBatch.expertChallenge.testType
              ? testTypes[expertQuestionBatch.expertChallenge.testType]
              : testTypes.quiz}
          </strong>
        </HeaderQuestions>
      </Header>
      <Main>
        <MainStats>
          {
            expertQuestionBatch.expertQuestions.filter(
              (question) => question.status === "submitted"
            ).length
          }{" "}
          /{" "}
          {forReview
            ? expertQuestionBatch.expertQuestions.filter(
                (question) =>
                  question.status === "approved" ||
                  question.status === "rejected"
              ).length
            : expertQuestionBatch.expertQuestions.filter(
                (question) => question.status === "approved"
              ).length}
        </MainStats>
        <MainText>
          Questions submitted / {forReview ? "reviewed" : "approved"}
        </MainText>
      </Main>
      <Footer>
        {expertQuestionBatch.status === "under_review" &&
        forReview &&
        !disabled ? (
          <AddMoreQuestionsButton
            onClick={() => {
              router.push(`/batches/${expertQuestionBatch.id}`);
            }}
          >
            REVIEW QUESTIONS &gt;
          </AddMoreQuestionsButton>
        ) : expertQuestionBatch.status === "in_progress" ? (
          <AddMoreQuestionsButton
            onClick={() => {
              router.push(`/batches/${expertQuestionBatch.id}/new`);
            }}
          >
            {disabled ? "BROWSE QUESTIONS" : "ADD MORE QUESTIONS"} &gt;
          </AddMoreQuestionsButton>
        ) : (
          <BrowseQuestionButton
            onClick={() => {
              router.push(`/batches/${expertQuestionBatch.id}`);
            }}
          >
            BROWSE QUESTIONS
          </BrowseQuestionButton>
        )}
      </Footer>
    </Container>
  );
}

const getStatusString = (
  expertQuestionBatch: ExpertQuestionBatchType
): React.ReactNode => {
  const isOverdue = isPast(parseISO(expertQuestionBatch.deadlineAt));
  switch (expertQuestionBatch.status) {
    case "in_progress": {
      return (
        <>
          <strong>active</strong>&nbsp;-{" "}
          {formatDistanceToNow(parseISO(expertQuestionBatch.deadlineAt))}{" "}
          {isOverdue ? "overdue" : "to go"}
        </>
      );
    }

    case "under_review": {
      return (
        <>
          <strong>in review</strong> &nbsp;-{" "}
          {formatDistanceToNow(parseISO(expertQuestionBatch.deadlineAt))}{" "}
          {isOverdue ? "overdue" : "to go"}
        </>
      );
    }

    case "reviewed": {
      return (
        <>
          <strong>reviewed</strong>&nbsp;on{" "}
          {format(new Date(expertQuestionBatch.statusChangedAt), "MMM do")}
        </>
      );
    }

    case "submitted": {
      return (
        <>
          <strong>submitted</strong>&nbsp;-{" "}
          {formatDistanceToNow(new Date(expertQuestionBatch.statusChangedAt), {
            addSuffix: true,
          })}
        </>
      );
    }

    case "rejected": {
      return (
        <>
          <strong>rejected</strong>
        </>
      );
    }

    case "accepted": {
      return (
        <>
          <strong>completed</strong>&nbsp;on{" "}
          {format(new Date(expertQuestionBatch.statusChangedAt), "MMM do")}
        </>
      );
    }
  }
};

const getStatusColor = (
  expertQuestionBatch: ExpertQuestionBatchType
): string => {
  const isOverdue = isPast(parseISO(expertQuestionBatch.deadlineAt));
  switch (expertQuestionBatch.status) {
    case "in_progress": {
      return isOverdue ? "#eb5757" : "#1DC488";
    }

    case "under_review": {
      return isOverdue ? "#eb5757" : "#1DC488";
    }

    case "submitted": {
      return "#1DC488";
    }

    default: {
      return "#989898";
    }
  }
};

const center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div<{
  status: ExpertQuestionBatchStatus;
  forReview?: boolean;
  disabled?: boolean;
}>`
  width: 270px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${(props) =>
    props.disabled
      ? "#E5E5E5"
      : props.forReview && props.status === "under_review"
      ? "#FEFAEE"
      : props.status === "in_progress"
      ? "#ffedeb"
      : "#e0e0e0"};
  transition: background-color 200ms;
  cursor: pointer;

  :hover {
    background-color: ${(props) =>
      props.disabled ? "#989898" : props.forReview ? "#FCEFCA" : "#ffc4bd"};
  }
`;

const Header = styled.div`
  height: 125px;
  padding: 22px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
`;

const HeaderQuestions = styled.div`
  font-size: 10px;
  text-transform: uppercase;
`;

const Status = styled.div<{
  status: ExpertQuestionBatchStatus;
  color: string;
  disabled?: boolean;
}>`
  ${center};
  height: 24px;
  font-size: 10px;
  text-transform: uppercase;
  padding: 0 6px;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => (props.disabled ? "#828282" : props.color)};
`;

const Main = styled.div`
  height: 90px;
  ${center};
  flex-direction: column;
  border-bottom: 1px solid #e7e7e7;
  background-color: #fff;
`;

const MainStats = styled.div`
  font-size: 32px;
`;

const MainText = styled.div`
  font-size: 10px;
  text-transform: uppercase;
`;

const Footer = styled.div`
  height: 65px;
  background-color: #fff;
  ${center};
`;

const AddMoreQuestionsButton = styled(Button)`
  height: 25px;
  padding: 0 12px;
  font-size: 10px;
`;

const BrowseQuestionButton = styled(LinkButton)`
  font-size: 10px;
`;
