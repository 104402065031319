import React from "react";
import styled from "@emotion/styled";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Button } from "@hundred5/design-system";
import { resetPassword } from "../../api/authentication";
import { useApiError } from "../../hooks/api-error";
import { FormikTextField } from "../";
import { useGoogleReCaptchaToken } from "../../hooks/google-recaptcha";

interface Props {
  email: string;
  token: string;
}

export default function ResetPasswordForm(props: Props) {
  const { email, token } = props;
  const handleApiError = useApiError();
  const {
    googleRecaptchaToken,
    refreshGoogleRecaptchaToken,
  } = useGoogleReCaptchaToken("reset_password");

  return (
    <Formik
      initialValues={{ password: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, form) => {
        if (!googleRecaptchaToken) {
          return;
        }
        try {
          await resetPassword(
            email,
            token,
            values.password,
            googleRecaptchaToken
          );
          form.setStatus("success");
        } catch (error) {
          handleApiError(error);
        } finally {
          refreshGoogleRecaptchaToken();
        }
      }}
    >
      {(props) =>
        props.status === "success" ? (
          <>
            <h1>Done!</h1>
            <Message>Your password has been updated!</Message>
          </>
        ) : (
          <>
            <h1>Enter your new password</h1>
            <Message>
              Almost done. Enter your new password and you're good to go.
            </Message>
            <Form>
              <FormikTextField
                id="password"
                name="password"
                label="New password:"
                type="password"
                required
              />

              <SubmitButton
                type="submit"
                disabled={props.isSubmitting || !googleRecaptchaToken}
              >
                {props.isSubmitting ? "Saving..." : "Save new password"}
              </SubmitButton>
            </Form>
          </>
        )
      }
    </Formik>
  );
}

const Message = styled.p`
  margin-bottom: 30px;
`;

const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
`;

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password cannot be empty")
    .min(6, "Password must have more than 6 characters"),
});
