import React, { HTMLAttributes } from "react";

export default function AcceptIcon(props: HTMLAttributes<any>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.80576 9.93253L3.37326 7.50003L2.54492 8.32253L5.80576 11.5834L12.8058 4.58336L11.9833 3.76086L5.80576 9.93253Z"
        fill="#1DC488"
      />
    </svg>
  );
}
