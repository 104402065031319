import { CombinedError } from "@urql/core";

export const hasNetworkError = (error: CombinedError): boolean => {
  return error.networkError != null;
};

export const hasGraphQLError = (
  error: CombinedError,
  type: string,
  message?: string
): boolean => {
  return error.graphQLErrors.some(
    (e) =>
      e.extensions?.type === type &&
      (message ? e.message.includes(message) : true)
  );
};

export const hasGraphQLPaths = (
  error: CombinedError,
  paths: string | string[]
) => {
  if (typeof paths === "string") {
    return error.graphQLErrors.some((e) => e.path?.includes(paths));
  } else {
    return (
      paths.filter((path) =>
        error.graphQLErrors.some((e) => e.path?.includes(path))
      ).length > 0
    );
  }
};
