import styled from "@emotion/styled";
import { Button } from "@hundred5/design-system";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import * as yup from "yup";
import { FormikTextField, Container } from "../";
import { checkClientError } from "../../api/errors";
import { useApiError } from "../../hooks/api-error";
import { useApiMutation, useApiQuery } from "../../hooks/api-query";
import { useApiClient } from "../../utils/authentication";
import { useFlashMessages } from "../../utils/flash-messages";
import { Divider } from "../common";
import {
  useProfileSettingsQuery,
  useSaveProfileSettingsMutation,
} from "../../utils/graphql-queries";
import { useGoogleReCaptchaToken } from "../../hooks/google-recaptcha";
import { fi } from "date-fns/locale";

interface FormValues {
  name: string;
  email: string;
  linkedin: string;
  old_password: string;
  password: string;
  confirm_password: string;
}

export default function ProfileSettings() {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [emailVisible, setEmailVisible] = React.useState(false);
  const { addMessage } = useFlashMessages();
  const {
    googleRecaptchaToken,
    refreshGoogleRecaptchaToken,
  } = useGoogleReCaptchaToken("reset_password");

  // GraphQL - Get and Update Expert/Me
  const [getExpertResult, _] = useProfileSettingsQuery();
  const [__, updateExpert] = useSaveProfileSettingsMutation();
  const { data: expertData, fetching, error } = getExpertResult;

  // REST API Client
  const apiClient = useApiClient();
  const handleApiError = useApiError();
  // Update Password
  const [updatePassword] = useApiMutation(apiClient.updatePassword);
  // Get and Update API Me (required to update email)
  const { data: meApi } = useApiQuery("me", apiClient.fetchMe);
  const [updateMeApi] = useApiMutation(apiClient.updateMe, {
    throwOnError: true,
    onError: () => {},
  });
  // Delete Me (Close account)
  const [deleteMeApi] = useApiMutation(apiClient.deleteMe, {
    throwOnError: true,
    onError: () => {},
  });

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>{error.message}</p>;

  const expert = expertData?.expert;
  if (expert == null) throw new Error("Expert data is missing");

  const submitForm = async (
    values: FormValues,
    form: FormikHelpers<FormValues>
  ) => {
    if (passwordVisible && values.password && values.old_password) {
      if (!googleRecaptchaToken) {
        return;
      }
      try {
        await updatePassword({
          oldPassword: values.old_password,
          newPassword: values.password,
          captchaToken: googleRecaptchaToken,
        });
        addMessage({ type: "password_changed" });
        setPasswordVisible(false);
      } catch (error) {
        if (checkClientError(error, "InvalidPassword")) {
          form.setFieldError("old_password", "Incorrect password.");
        } else {
          handleApiError(error);
        }
        // Don't attempt any other changes if errored at this point
        // to try and limit partial updates.
        return;
      } finally {
        refreshGoogleRecaptchaToken();
      }
    }

    if (emailVisible && values.email && meApi) {
      // Email currently needs to be updated via REST API to send a
      // validation email
      try {
        await updateMeApi({ ...meApi, email: values.email });
        addMessage({ type: "verification_email_sent" });
        setEmailVisible(false);
      } catch (error) {
        if (checkClientError(error, "EmailAlreadyTaken")) {
          form.setFieldError("email", "Email already taken.");
        } else {
          handleApiError(error);
        }
        // Don't attempt any other changes if errored at this point
        // to try and limit partial updates.
        return;
      }
    }

    await updateExpert({
      id: expert.id,
      name: values.name,
      linkedin: values.linkedin,
    });
  };

  const closeAccount = async () => {
    try {
      await deleteMeApi();
      // addMessage({ type: "close_account", success: true });
    } catch (error) {
      if (checkClientError(error, "ForbiddenForWorkspaceOwner")) {
        addMessage({
          type: "close_account",
          success: false,
          error: "workspace_owner",
        });
      } else {
        handleApiError(error);
      }
    }
  };

  return (
    <ProfileContainer>
      <Container>
        <Formik
          initialValues={{
            name: expert.user.name || "",
            email: expert.user.email || "",
            linkedin: expert.linkedin || "",
            old_password: "",
            password: "",
            confirm_password: "",
          }}
          onSubmit={submitForm}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <FormikTextField
                id="name"
                name="name"
                label="Display name"
                required
              />
              <FormikTextField
                id="linkedin"
                name="linkedin"
                label="LinkedIn Profile"
              />
              {emailVisible ? (
                <FormikTextField
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  required
                />
              ) : (
                <ToggleButton
                  variant="secondary"
                  onClick={() => setEmailVisible(true)}
                >
                  Change Email
                </ToggleButton>
              )}
              {passwordVisible ? (
                <>
                  <FormikTextField
                    id="old_password"
                    name="old_password"
                    label="Current Password"
                    type="password"
                    required
                  />
                  <FormikTextField
                    id="password"
                    name="password"
                    label="New Password"
                    type="password"
                    required
                  />
                  <FormikTextField
                    id="confirm_password"
                    name="confirm_password"
                    label="Confirm New Password"
                    type="password"
                    required
                  />
                </>
              ) : (
                <ToggleButton
                  variant="secondary"
                  onClick={() => setPasswordVisible(true)}
                >
                  Change Password
                </ToggleButton>
              )}
              <Divider />
              <Footer>
                <Button
                  type="submit"
                  disabled={props.isSubmitting || !googleRecaptchaToken}
                >
                  {props.isSubmitting ? "Saving..." : "Save settings"}
                </Button>
              </Footer>
            </Form>
          )}
        </Formik>
      </Container>
      {/* Requires a confirmation modal, contents to be confirmed.
      <CloseBlock>
        <LinkButton onClick={() => closeAccount()}>Close account</LinkButton>
      </CloseBlock> */}
    </ProfileContainer>
  );
}

const ProfileContainer = styled.div`
  & input {
    max-width: 300px;
  }
`;

const CloseBlock = styled.div`
  max-width: 700px;
  width: 80%;
  margin: 10px auto;
  display: flex;
  justify-content: flex-end;
`;

const ToggleButton = styled(Button)`
  display: block;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const linkedInExpr = /^(https:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/in\/.+$/;

const validationSchema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  email: yup
    .string()
    .email("Please enter an email address, for example alex@gmail.com"),
  linkedin: yup
    .string()
    .matches(
      linkedInExpr,
      "Please enter a LinkedIn URL, for example https://linkedin.com/in/alex"
    ),
  old_password: yup.string(),
  password: yup.string().min(6, "Password needs to have at least 6 characters"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});
