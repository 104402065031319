import { DocumentNode } from "graphql";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { TableState } from "react-table";
import store from "store2";
import { useQuery, UseQueryArgs } from "urql";
import {
  OrderBy,
  OrderByDirection,
  OrderByField,
} from "../../utils/graphql-queries";

export function useStoreSortState(
  tableName: string,
  defaultSort: { id: string; desc: boolean }
) {
  const storageKey = `table_sort_state_${tableName}`;
  const [sortState, setSortState] = React.useState(
    store.get(storageKey) ?? [defaultSort]
  );

  const tableStateCallback = React.useCallback(
    (state: TableState<object>) => {
      setSortState(state.sortBy);
      store.set(storageKey, state.sortBy);
    },
    [storageKey]
  );
  return [sortState, tableStateCallback];
}

interface useTableDataProps<T, K, A, V> {
  getQueryDataCallback: (queryData: T) => K[] | undefined;
  parseTableItemsCallback: (tableItems: K[]) => A[];
  queryDocument: DocumentNode;
  getQueryOptions: (
    offset: number,
    limit: number,
    orderBy: OrderBy
  ) => {
    variables: V;
  };
  sortFields: {
    [index: string]: {
      field?: OrderByField;
      fields?: OrderByField[];
      coalesce?: boolean;
    };
  };
  sortState: any;
  getQueryDataTotalCountCallback?: (queryData: T) => number | undefined;
}

export function useTableData<T, K, A, V>({
  getQueryDataCallback,
  parseTableItemsCallback,
  queryDocument,
  getQueryOptions,
  sortFields,
  sortState,
  getQueryDataTotalCountCallback,
}: useTableDataProps<T, K, A, V>) {
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const orderBy = useMemo(
    () => ({
      direction: sortState[0].desc
        ? OrderByDirection.Desc
        : OrderByDirection.Asc,
      ...sortFields[sortState[0].id],
    }),
    [sortState, sortFields]
  );
  const options = getQueryOptions(offset, limit, orderBy);
  const [tableData, setTableData] = useState<A[]>([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [dataCache, setDataCache] = useState<K[]>([]);
  const [inViewRef, inView] = useInView();
  const query: UseQueryArgs<V, T> = { query: queryDocument, ...options };
  const [queryState, refetchQuery] = useQuery<T, V>(query);
  const totalCount = queryState.data
    ? getQueryDataTotalCountCallback?.(queryState.data)
    : undefined;

  useEffect(() => {
    if (inView && !queryState.fetching) {
      setOffset((offset) => offset + limit);
      setCanLoadMore(false);
    }
  }, [limit, inView, queryState.fetching]);

  // Keep cache updated
  useEffect(() => {
    if (offset === 0 && queryState.fetching) {
      setDataCache([]);
    }
    if (
      queryState.data &&
      getQueryDataCallback(queryState.data) &&
      !queryState.fetching
    ) {
      setDataCache((cache) =>
        uniqBy(
          [
            ...cache,
            ...((queryState.data && getQueryDataCallback(queryState.data)) ||
              []),
          ],
          "id"
        )
      );
    }
  }, [offset, queryState, getQueryDataCallback]);

  useEffect(() => {
    setTableData((tableData) => {
      if (tableData.length === dataCache.length) {
        setCanLoadMore(false);
      } else if (dataCache.length % limit === 0) {
        setCanLoadMore(true);
      }
      return parseTableItemsCallback(dataCache);
    });
  }, [dataCache, limit, parseTableItemsCallback]);

  const tableOptions = useMemo(
    () => ({
      initialState: { sortBy: sortState },
      disableSortRemove: true,
      autoResetSortBy: false,
    }),
    [sortState]
  );

  return {
    setDataCache,
    setTableData,
    setOffset,
    canLoadMore,
    tableData,
    tableOptions,
    inViewRef,
    refetchQuery,
    totalCount,
  };
}
