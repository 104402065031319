import React from "react";

export default function TrashIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7943 15H3.20195L2.35938 2.96466H13.7016L12.7943 15Z"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999998 2.96467H14.9996"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.49245 1H6.57587L5.92774 2.96366H10.1406L9.49245 1Z"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.86506V12.973"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9818 4.86506L10.3984 12.973"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.08203 4.86505L5.60054 12.973"
        stroke="#828282"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
