import React, { useRef } from "react";
import { FormikSelectField } from "..";
import { useField } from "formik";
import { questionTypes, questionTypesDisabled } from "../../utils/questions";
import styled from "@emotion/styled";

export default function TypeField() {
  const [type] = useField("type");
  const initialType = useRef(type.value);

  return (
    <Container>
      <FormikSelectField id="question-type" name="type" label="Question type">
        {options.map(
          ([value, label]) =>
            !(
              questionTypesDisabled.includes(value) &&
              initialType.current !== value
            ) && (
              <option key={value} value={value}>
                {label}
              </option>
            )
        )}
      </FormikSelectField>
    </Container>
  );
}

const options = Object.entries(questionTypes);

const Container = styled.div`
  & > div:first-of-type {
    margin-bottom: 0;
  }
`;
