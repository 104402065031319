import React from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { LoginButton as LibraryLoginButton } from "@hundred5/candidate-components";
import { Button } from "@hundred5/design-system";
import { useSignup } from "../../utils/authentication";
import FormikTextField from "../formik-text-field/formik-text-field";
import { checkClientError } from "../../api/errors";
import { useApiError } from "../../hooks/api-error";
import { FormikCheckboxField } from "../index";
import { thirdPartyAuth } from "../../api/authentication";
import { useGoogleReCaptchaToken } from "../../hooks/google-recaptcha";

export default function SignupForm() {
  const signup = useSignup();
  const handleApiError = useApiError();
  const router = useRouter();
  const {
    googleRecaptchaToken: googleLoginRecaptchaToken,
    refreshGoogleRecaptchaToken: refreshGoogleRecaptchaLoginToken,
  } = useGoogleReCaptchaToken("login");
  const {
    googleRecaptchaToken: googleSignupRecaptchaToken,
    refreshGoogleRecaptchaToken: refreshGoogleRecaptchaSignupToken,
  } = useGoogleReCaptchaToken("signup");

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        password: "",
        acceptTerms: false,
        originalContent: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, form) => {
        if (
          googleSignupRecaptchaToken === null ||
          googleLoginRecaptchaToken === null
        ) {
          return;
        }
        try {
          await signup(
            values.name,
            values.email,
            values.password,
            googleSignupRecaptchaToken,
            googleLoginRecaptchaToken
          );
        } catch (error) {
          if (checkClientError(error, "AlreadyRegistered")) {
            form.setFieldError("email", "Email is already taken");
          } else {
            handleApiError(error);
          }
          return;
        } finally {
          refreshGoogleRecaptchaLoginToken();
          refreshGoogleRecaptchaSignupToken();
        }

        await router.push("/");
      }}
    >
      {(props) => (
        <>
          <h1>Sign up</h1>
          <GreetingText>
            Become part of our expert community and help us creating the best
            skills testing library in the world.
          </GreetingText>

          <LoginButton
            variant="signup"
            method="linkedin"
            onClick={() =>
              thirdPartyAuth("signup", "linkedin", "experts-october-2020")
            }
            disabled={
              !(props.values.acceptTerms && props.values.originalContent)
            }
            data-tooltip={
              !(props.values.acceptTerms && props.values.originalContent)
                ? "Please accept the Terms & Conditions and Original Content Policy to sign up."
                : undefined
            }
          />
          <Separator>Or, sign up with your email</Separator>

          <Form>
            <FormikTextField
              id="name"
              name="name"
              type="text"
              label="Name:"
              required
            />
            <FormikTextField
              id="email"
              name="email"
              type="email"
              label="Email:"
              required
            />
            <FormikTextField
              id="password"
              name="password"
              type="password"
              label="Password:"
              required
            />

            <FormikCheckboxField
              name="acceptTerms"
              id="accept-terms"
              label={
                <>
                  I agree to{" "}
                  <a
                    href="https://toggl.com/legal/hire/expert-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Toggl Hire Terms & Conditions
                  </a>
                </>
              }
            />
            <FormikCheckboxField
              name="originalContent"
              id="original-content"
              label="I agree to provide original content only"
            />

            <SubmitButton
              type="submit"
              disabled={
                props.isSubmitting ||
                !googleSignupRecaptchaToken ||
                !googleLoginRecaptchaToken
              }
              variant="secondary"
            >
              {props.isSubmitting ? "Creating..." : "Create account ›"}
            </SubmitButton>
          </Form>
        </>
      )}
    </Formik>
  );
}

const GreetingText = styled.p`
  margin-bottom: 30px;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  display: block;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 24px 0;
  color: #989898;
  font-size: 10px;

  &:before,
  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #e7e7e7;
  }

  &:before {
    margin-right: 0.25em;
  }

  &:after {
    margin-left: 0.25em;
  }
`;

const LoginButton = styled(LibraryLoginButton)`
  position: relative;
`;

const validationSchema = yup.object().shape({
  name: yup.string().required("Name cannot be empty"),
  email: yup
    .string()
    .required("Email cannot be empty")
    .email("Enter a valid email"),
  password: yup
    .string()
    .required("Password cannot be empty")
    .min(6, "Password must have more than 6 characters"),
  acceptTerms: yup
    .boolean()
    .oneOf([true], "You must accept Terms & Conditions"),
  originalContent: yup
    .boolean()
    .oneOf([true], "You must accept original content policy"),
});
