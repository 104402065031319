import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Button, LinkButton } from "@hundred5/design-system";
import {
  ExpertChallenge as ExpertChallengeType,
  ExpertQuestionBatch as ExpertQuestionBatchType,
  ExpertQuestionBatchStatus,
  ExpertStatus,
  useExpertDashboardAddSkillMutation,
  useExpertDashboardQuery,
} from "../../utils/graphql-queries";
import {
  ExpertChallenge,
  ExpertQuestionBatch,
  ExpertQuestionBatchForReview,
  SkillPicker,
} from "../";
import { FlexGrid } from "../common";

export default function ExpertDashboard() {
  const context = useMemo(
    () => ({ additionalTypenames: ["ExpertSkill", "ExpertQuestionBatch"] }),
    []
  );
  const [{ data, fetching }] = useExpertDashboardQuery({
    context,
  });
  const [_, addSkill] = useExpertDashboardAddSkillMutation();

  const [skillPickerOpen, setSkillPickerOpen] = useState(false);

  if (data === undefined && fetching) return null;

  const expertSkills = [...(data?.expert?.expertSkills || [])].sort((a, b) =>
    a.skill.name.localeCompare(b.skill.name)
  );

  const isSkillButtonDisabled = !!data?.expert?.expertSkills?.filter(
    (skill) => !skill.isApproved
  ).length;

  const batchesReadyForReview = data?.expert?.expertQuestionBatchesForReview.filter(
    (batch) => batch.status === ExpertQuestionBatchStatus.Submitted
  );
  const reviewingBatches = data?.expert?.expertQuestionBatchesForReview.filter(
    (batch) => batch.status !== ExpertQuestionBatchStatus.Submitted
  );

  return (
    <Container>
      <ChallengesContainer>
        <h1>New Challenges</h1>

        <SkillsContainer>
          <SkillsLabel>my skills:</SkillsLabel>
          <SkillList data-testid="ed-skilllist">
            {expertSkills.map((skill) => (
              <Skill
                key={skill.id}
                isApproved={skill.isApproved}
                title={skill.isApproved ? "" : "This skill is not yet approved"}
              >
                {skill.skill.name}
              </Skill>
            ))}

            {data?.expert?.status === ExpertStatus.Active &&
              (expertSkills.length ? (
                <SkillButton
                  disabled={isSkillButtonDisabled}
                  onClick={() => setSkillPickerOpen(true)}
                  data-tooltip={
                    isSkillButtonDisabled
                      ? "Currently added skill needs to be reviewed and approved before you can add another skill."
                      : null
                  }
                >
                  + Add a skill
                </SkillButton>
              ) : (
                <SkillButtonInitial onClick={() => setSkillPickerOpen(true)}>
                  + Add a skill
                </SkillButtonInitial>
              ))}
          </SkillList>
        </SkillsContainer>

        <FlexGrid>
          {(data?.expert?.status === ExpertStatus.Active ||
            data?.expert?.status === ExpertStatus.OnHold) &&
            (data?.expert?.expertChallenges.length ? (
              data?.expert?.expertChallenges.map((expertChallenge) => (
                <ExpertChallenge
                  key={expertChallenge.id}
                  expertChallenge={expertChallenge as ExpertChallengeType}
                  disabled={data?.expert?.status === ExpertStatus.OnHold}
                />
              ))
            ) : data?.expert?.expertQuestionBatches.length === 0 ? (
              <ExpertChallenge isEmpty />
            ) : null)}
        </FlexGrid>
      </ChallengesContainer>

      {batchesReadyForReview?.length &&
      data?.expert?.status === ExpertStatus.Active ? (
        <QuestionBatchesContainer>
          <h1>Batches for review</h1>

          <FlexGrid>
            {batchesReadyForReview.map((expertQuestionBatch) => (
              <ExpertQuestionBatchForReview
                key={expertQuestionBatch.id}
                expertQuestionBatch={
                  expertQuestionBatch as ExpertQuestionBatchType
                }
              />
            ))}
          </FlexGrid>
        </QuestionBatchesContainer>
      ) : null}

      {data?.expert?.expertQuestionBatches.length ? (
        <QuestionBatchesContainer>
          <h1>Recent batches</h1>

          <FlexGrid>
            {data?.expert?.expertQuestionBatches.map((expertQuestionBatch) => (
              <ExpertQuestionBatch
                key={expertQuestionBatch.id}
                expertQuestionBatch={
                  expertQuestionBatch as ExpertQuestionBatchType
                }
                disabled={data?.expert?.status === ExpertStatus.Inactive}
              />
            ))}
          </FlexGrid>
        </QuestionBatchesContainer>
      ) : null}

      {reviewingBatches?.length ? (
        <QuestionBatchesContainer>
          <h1>Recent reviews</h1>

          <FlexGrid>
            {reviewingBatches.map((expertQuestionBatch) => (
              <ExpertQuestionBatch
                key={expertQuestionBatch.id}
                forReview
                expertQuestionBatch={
                  expertQuestionBatch as ExpertQuestionBatchType
                }
                disabled={data?.expert?.status === ExpertStatus.Inactive}
              />
            ))}
          </FlexGrid>
        </QuestionBatchesContainer>
      ) : null}

      <SkillPicker
        open={skillPickerOpen}
        onClose={() => setSkillPickerOpen(false)}
        onPick={(skill) => {
          addSkill({
            skillId: skill.id,
          });
          setSkillPickerOpen(false);
        }}
      />
    </Container>
  );
}

const Container = styled.div``;

const ChallengesContainer = styled.div`
  padding: 12px 24px 8px;
  background: #ffffff;
`;

const QuestionBatchesContainer = styled.div`
  padding: 12px 24px;
`;

const SkillsContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const SkillsLabel = styled.div`
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 10px;
  margin-top: 5px;
  white-space: nowrap;
`;

const SkillList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SkillButtonInitial = styled(Button)`
  height: 28px;
  padding: 0 10px;
  text-transform: uppercase;
  position: relative;
  top: -2px;
`;

const SkillButton = styled(LinkButton)`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  position: relative;
  top: -2px;

  :disabled,
  :disabled:hover {
    color: #989898;
    opacity: 1;
  }
`;

const Skill = styled.div<{ isApproved: boolean }>`
  height: 25px;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  background: ${(props) => (props.isApproved ? "#E1E1E1" : "#ffffff")};
  color: ${(props) => (props.isApproved ? "#1a1a1a" : "#989898")};
  border: ${(props) => (props.isApproved ? "none" : "1px solid #E5E5E5")};
  white-space: nowrap;
`;
