import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import {
  useAdminDashboardChallengesQuery,
  ExpertChallenge as ExpertChallengeType,
} from "../../utils/graphql-queries";
import { FlexGrid } from "../common";
import { CreateChallengeButton, ExpertChallenge } from "../";
import { ViewMode } from "../../types/viewMode";

//region Component
export default function AdminDashboardChallenges() {
  const router = useRouter();
  const [{ data }] = useAdminDashboardChallengesQuery();

  return (
    <Container>
      <h1>Challenges</h1>
      <FlexGrid>
        <CreateChallengeButton />

        {data?.admin.expertChallenges.map((expertChallenge) => (
          <ExpertChallenge
            key={expertChallenge.id}
            expertChallenge={expertChallenge as ExpertChallengeType}
            viewMode={ViewMode.Admin}
          />
        ))}

        <AllChallengesButtonContainer>
          <AllChallengesButton
            onClick={() => router.push("/admin/challenges/list")}
          >
            All challenges &nbsp;<span>→</span>
          </AllChallengesButton>
        </AllChallengesButtonContainer>
      </FlexGrid>
    </Container>
  );
}
//endregion

//region Styles
const Container = styled.div`
  padding: 12px 24px 8px;
  background: #ffffff;
`;

const AllChallengesButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AllChallengesButton = styled.button`
  height: 38px;
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 3px;
  -webkit-appearance: none;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :active {
    transform: scale(0.96);
  }

  span {
    color: #ffde91;
  }
`;
//endregion
