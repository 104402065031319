import Head from "next/head";

interface Props {
  title?: string;
}

const PageTitle: React.FC<Props> = (props) => {
  return (
    <Head>
      <title>
        {props.title
          ? `${props.title} — Toggl Hire Expert`
          : "Toggl Hire Expert"}
      </title>
    </Head>
  );
};

export default PageTitle;
