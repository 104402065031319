import React, { useMemo } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { LinkButton } from "@hundred5/design-system";
import { ExpertChallengeForm } from "../";
import {
  ExpertChallenge,
  ExpertChallengeState,
  useExpertChallengeDeleteMutation,
  useExpertChallengeQuery,
  useExpertChallengeUpdateMutation,
  useExpertChallengeArchiveMutation,
  ExpertQuestionBatch,
  TestType,
} from "../../utils/graphql-queries";
import { useFlashMessages } from "../../utils/flash-messages";
import BatchSummaries from "../batch-summaries";
import {
  BatchSummaryViewType,
  TableBatchItem,
} from "../batch-summaries/batch-summaries";
import { format, parseISO } from "date-fns";
import { getDeadlineDate } from "../../utils/question-batch";

//region Component
interface Props {
  id: string;
}

export default function ExpertChallengeUpdate({ id }: Props) {
  const router = useRouter();
  const { addMessage } = useFlashMessages();
  const [{ data }] = useExpertChallengeQuery({ variables: { id } });
  const [_, updateExpertChallenge] = useExpertChallengeUpdateMutation();
  const [__, deleteExpertChallenge] = useExpertChallengeDeleteMutation();
  const [___, archiveExpertChallenge] = useExpertChallengeArchiveMutation();

  const parsedBatches = useMemo<TableBatchItem[]>(() => {
    const parsedBatches = [];
    const batches = data?.expertChallenge?.expertQuestionBatches;
    if (batches) {
      for (const batch of batches) {
        const parsedBatch: TableBatchItem | undefined = batch && {
          id: batch.id,
          expertName: batch.expert.user.name,
          status: batch.status,
          statusChangedAt: parseISO(batch.statusChangedAt),
          reviewers: batch.reviewers.nodes.map(
            (reviewer) => reviewer.user.name
          ),
          isExpertPaid: !!batch.paidAt,
          isReviewerPaid: !!batch.reviewersPaidAt,
          dueOn: getDeadlineDate(batch as ExpertQuestionBatch),
        };
        parsedBatch && parsedBatches.push(parsedBatch);
      }
    }

    return parsedBatches;
  }, [data]);

  return (
    <>
      <Container>
        <Header>
          <h1>Edit challenge</h1>
          {(data?.expertChallenge.state === "draft" ||
            data?.expertChallenge.state === "archived") && (
            <ActionButton
              onClick={async () => {
                await updateExpertChallenge({
                  id,
                  input: {
                    state: ExpertChallengeState.Active,
                    testType: data?.expertChallenge.testType || TestType.Quiz,
                  },
                });
                addMessage({
                  type: "challenge_activated",
                });
              }}
            >
              Activate
            </ActionButton>
          )}
          {data?.expertChallenge.state === "active" && (
            <ActionButton
              onClick={async () => {
                await archiveExpertChallenge({ id });
                addMessage({
                  type: "challenge_archived",
                });
              }}
            >
              Archive
            </ActionButton>
          )}
        </Header>

        <ExpertChallengeForm
          expertChallenge={data?.expertChallenge as ExpertChallenge}
          onSubmit={async (challenge) => {
            await updateExpertChallenge({ id, input: challenge });
            addMessage({ type: "challenge_saved" });
            router.push((router.query.returnURL || "/admin") as string);
          }}
          onCancel={() =>
            router.push((router.query.returnURL || "/admin") as string)
          }
        />

        {data?.expertChallenge.expertQuestionBatches.length === 0 && (
          <DeleteButton
            onClick={async () => {
              router.push((router.query.returnURL || "/admin") as string);
              await deleteExpertChallenge(
                { id },
                { additionalTypenames: ["ExpertChallenge"] }
              );
              addMessage({ type: "challenge_deleted" });
            }}
          >
            Delete challenge
          </DeleteButton>
        )}
      </Container>
      <BatchesContainer>
        <b>Batches:</b>
        <BatchSummaries
          batches={parsedBatches}
          viewType={BatchSummaryViewType.Challenge}
        />
      </BatchesContainer>
    </>
  );
}
//endregion

//region Styles
const Container = styled.article`
  width: 900px;
  background: #ffffff;
  position: relative;
`;

const BatchesContainer = styled.article`
  margin-top: 50px;
  width: 900px;
  background: #ffffff;
  position: relative;
  padding: 24px 36px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 36px;
  height: 80px;
  border-bottom: 1px solid #e7e7e7;
`;

const ActionButton = styled(LinkButton)`
  text-transform: uppercase;
  font-size: 10px;
`;

const DeleteButton = styled(LinkButton)`
  position: absolute;
  right: 0;
  bottom: -30px;
`;
//endregion
