import React from "react";
import { pickBy, keys, map } from "lodash";
import { Form, Formik } from "formik";
import styled from "@emotion/styled";
import { Button, LinkButton } from "@hundred5/design-system";
import { MarkdownState, RichTextState } from "@togglhire/text-editor";
import { FormikCheckboxField, FormikMarkdownField } from "../";
import * as yup from "yup";
import {
  ExpertQuestionStatus,
  QuestionCommentTypes,
  useReviewExpertQuestionMutation,
} from "../../utils/graphql-queries";
import { useFlashMessages } from "../../utils/flash-messages";
import { commentTypeLabels } from "../../utils/comment-type-labels";

//region Component
interface Props {
  id: string;
  onBack: () => void;
  onSubmit: () => void;
}

export default function RejectForm({ id, onSubmit, onBack }: Props) {
  const [_, reviewExpertQuestion] = useReviewExpertQuestionMutation();
  const { addMessage } = useFlashMessages();

  return (
    <Container>
      <Header>
        What is the issue with this question? Please tick all that apply:
      </Header>
      <Formik
        initialValues={{
          formal: false,
          topic: false,
          wrong_answers: false,
          obvious_answers: false,
          time: false,
          difficulty: false,
          other: false,
          message: RichTextState.create(""),
          status: ExpertQuestionStatus.Submitted,
        }}
        onSubmit={async (formikValues) => {
          await reviewExpertQuestion({
            id,
            status: formikValues.status,
            feedback: {
              message: formikValues.message.markdown(),
              types: keys(
                pickBy(formikValues, (value) => value === true)
              ) as QuestionCommentTypes[],
            },
          });

          addMessage({
            type:
              formikValues.status === ExpertQuestionStatus.Feedback
                ? "question_review_requested"
                : "question_rejected",
          });

          onSubmit();
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            <Issues>
              {map(commentTypeLabels, (value, key) => (
                <FormikCheckboxField
                  id={key}
                  name={key}
                  key={key}
                  label={
                    <>
                      {value.label}&nbsp;
                      {value.description ? (
                        <IssueDescription>
                          ({value.description})
                        </IssueDescription>
                      ) : null}
                    </>
                  }
                />
              ))}
            </Issues>

            <FormikMarkdownField
              id="message"
              name="message"
              label="Please provide the details:"
              required
            />

            <Buttons>
              <LinkButton variant="secondary" type="button" onClick={onBack}>
                Back
              </LinkButton>
              <Button
                variant="secondary"
                onClick={() =>
                  formikProps.setFieldValue(
                    "status",
                    ExpertQuestionStatus.Rejected
                  )
                }
              >
                Reject question
              </Button>
              <Button
                onClick={() =>
                  formikProps.setFieldValue(
                    "status",
                    ExpertQuestionStatus.Feedback
                  )
                }
              >
                Request changes →
              </Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const validationSchema = yup.object().shape({
  message: yup
    .object<RichTextState | MarkdownState>()
    .test(
      "content",
      "You must provide the details!",
      (value) => (value?.markdown().trim() ?? "") !== ""
    ),
});
//endregion

//region Styles
const Container = styled.div``;

const Header = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 25px;
`;

const Issues = styled.div`
  & > * {
    margin-bottom: 10px;
  }

  margin-bottom: 20px;
`;

const IssueDescription = styled.span`
  font-size: 12px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
`;
//endregion
