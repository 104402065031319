import React from "react";

export default function CodeIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1117 9.41609L9.88672 8.19115L11.8991 6.17875L9.88672 4.16635L11.1117 2.94141L13.7365 5.56628C14.0865 5.91626 14.0865 6.44124 13.7365 6.79122L11.1117 9.41609Z"
        fill="#BDBDBD"
      />
      <path
        d="M2.88736 9.41609L0.262487 6.79122C-0.0874957 6.44124 -0.0874957 5.91626 0.262487 5.56628L2.88736 2.94141L4.1123 4.16635L2.0999 6.17875L4.1123 8.19115L2.88736 9.41609Z"
        fill="#BDBDBD"
      />
      <path
        d="M5.24852 12.3042C5.16103 12.3042 5.07353 12.3042 4.98604 12.2167C4.54856 12.0417 4.28607 11.6042 4.46106 11.0793L7.96089 0.579787C8.13588 0.142308 8.57336 -0.120179 9.09834 0.0548128C9.53581 0.229804 9.7983 0.667283 9.62331 1.19226L6.12348 11.6917C5.94849 12.0417 5.59851 12.3042 5.24852 12.3042Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}
