import {
  addDays,
  differenceInCalendarDays,
  intervalToDuration,
  parseISO,
} from "date-fns";
import {
  ExpertQuestionBatch,
  ExpertQuestionBatchStatus,
} from "./graphql-queries";

export const getDeadlineInDays = (
  questionBatch: ExpertQuestionBatch
): number => {
  return differenceInCalendarDays(
    parseISO(questionBatch.deadlineAt),
    new Date()
  );
};

export const getDeadlineDate = (
  questionBatch: ExpertQuestionBatch
): Date | undefined => {
  return parseISO(questionBatch.deadlineAt);
};
