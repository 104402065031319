import {
  getOptions,
  getSingleOptionContent,
  OptionsState,
} from "@togglhire/question-editor";
import { MarkdownState, RichTextState } from "@togglhire/text-editor";
import {
  NewTestGenQuestion,
  NewTestGenQuestionOption,
  QuestionDifficulty,
  TestType,
} from "../../utils/graphql-queries";
import * as yup from "yup";
import { TestOptions } from "yup";
import {
  hardDifficulty,
  multipleChoiceQuestion,
  numericInputQuestion,
  pictureQuestion,
  singleChoiceQuestion,
  standardDifficulty,
} from "../../utils/questions";

export interface Values {
  description: RichTextState | MarkdownState;
  notes: RichTextState | MarkdownState;
  reply: RichTextState | MarkdownState;
  type: string;
  difficulty: string;
  duration: string;
  durationSetting: "default" | "custom";
  options: OptionsState;
  action?: "save" | "submit";
  skillId: string;
  testType: TestType;
}

export const valuesToQuestion = (values: Values): NewTestGenQuestion => {
  return {
    title: "",
    description: values.description.markdown(),
    notes: values.notes.markdown(),
    questionType: values.type,
    difficulty: valueToDifficulty(values.difficulty),
    durationInSeconds:
      values.durationSetting === "custom" ? parseInt(values.duration, 10) : 0,
    skillID: values.skillId,
    options: getOptions(values.options).map(
      (option): NewTestGenQuestionOption => ({
        content: option.content,
        points: option.points,
        orderWeight: option.weight,
      })
    ),
    points: 1,
    testType: values.testType,
  };
};

export const valueToDifficulty = (value: string): QuestionDifficulty => {
  switch (value) {
    case standardDifficulty:
      return QuestionDifficulty.Standard;
    case hardDifficulty:
      return QuestionDifficulty.Hard;
    default:
      throw new Error(`Unexpected difficulty: ${value}`);
  }
};

export const difficultyToValue = (difficulty: QuestionDifficulty): string => {
  switch (difficulty) {
    case QuestionDifficulty.Standard:
      return standardDifficulty;
    case QuestionDifficulty.Hard:
      return hardDifficulty;
  }
};

export const schema = yup.object<Values>().shape({
  description: yup
    .object<RichTextState | MarkdownState>()
    .test(
      "content",
      "Please enter the question",
      (value) => (value?.markdown().trim() ?? "") !== ""
    ),
  type: yup.string().required("Please select the question type"),
  difficulty: yup
    .string()
    .oneOf([standardDifficulty, hardDifficulty])
    .required("Please select the difficulty level"),
  durationSetting: yup.string().oneOf(["custom", "default"]),
  duration: yup.number().when("durationSetting", {
    is: "custom",
    then: yup
      .number()
      .required("Please enter the time allocation")
      .min(30, "Time allocation must be at least 30 seconds"),
  }),
  options: yup.object().when("type", (type: string) => {
    switch (type) {
      case singleChoiceQuestion:
      case multipleChoiceQuestion:
      case pictureQuestion:
        return yup
          .object()
          .test(enoughChoices)
          .test(getCorrectChoicesTest(type))
          .test(requiredChoices);
      case numericInputQuestion:
        return yup.object().test(numericAnswer);
      default:
        return yup.object();
    }
  }),
  skillId: yup.number().required("Please select a skill"),
});

const enoughChoices: TestOptions = {
  exclusive: true,
  name: "enough_choices",
  message: "Please add at least two choices",
  test: (value) => {
    if (value == null) return false;

    try {
      const options = getOptions(value as OptionsState);
      return options.length >= 2;
    } catch (error) {
      return false;
    }
  },
};

const getCorrectChoicesTest = (type: string): TestOptions => {
  const minChoices = type === multipleChoiceQuestion ? 2 : 1;
  return {
    exclusive: true,
    name: "correct_choice",
    message:
      type === multipleChoiceQuestion
        ? "Please select the correct answers"
        : "Please select the correct answer",
    test: (value) => {
      if (value == null) return false;

      try {
        const options = getOptions(value as OptionsState);
        return (
          options.filter((option) => option.points > 0).length >= minChoices
        );
      } catch (error) {
        return false;
      }
    },
  };
};

const requiredChoices: TestOptions = {
  exclusive: true,
  name: "required_choices",
  message: "Please enter all choices",
  test: (value) => {
    if (value == null) return false;

    try {
      const options = getOptions(value as OptionsState);
      return options.find((option) => option.content.trim() === "") == null;
    } catch (error) {
      return false;
    }
  },
};

const numericAnswer: TestOptions = {
  exclusive: true,
  name: "numeric_answer",
  message: "Please add the correct answer",
  test: (value) => {
    if (value == null) return false;

    try {
      const option = getSingleOptionContent(value as OptionsState);
      return option != null && option !== "";
    } catch (error) {
      return false;
    }
  },
};
