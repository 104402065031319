import React from "react";
import styled from "@emotion/styled";
import { VerticalLogo } from "../../icons";

interface Props {
  content: React.ReactNode;
  footer?: React.ReactNode;
}

export default function AuthPage({ content, footer }: Props) {
  return (
    <Layout>
      <Main>
        <Logo />
        {content}
      </Main>
      {footer ? <Footer>{footer}</Footer> : null}
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
`;

const Main = styled.main`
  position: relative;
  width: 440px;
  padding: 50px 50px 40px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  background: #ffffff;
`;

const Footer = styled.footer`
  margin-top: 20px;
  text-align: center;
`;

const Logo = styled(VerticalLogo)`
  display: block;
  margin-bottom: 40px;
`;
