import React, { HTMLAttributes } from "react";

export default function RejectIcon(props: HTMLAttributes<any>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 5 }}
      {...props}
    >
      <rect
        x="4.24219"
        y="11.3284"
        width="9"
        height="1"
        transform="rotate(-45 4.24219 11.3284)"
        fill="#EB5757"
      />
      <rect
        x="4.94922"
        y="4.96448"
        width="9"
        height="1"
        transform="rotate(45 4.94922 4.96448)"
        fill="#EB5757"
      />
    </svg>
  );
}
