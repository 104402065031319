import React, { useEffect, useState } from "react";
import { CombinedError, useQuery, UseQueryArgs, UseQueryState } from "urql";
import { uniqBy } from "lodash";

interface UsePaginatedQueryVariables {
  limit: number;
  offset: number;
  query?: string;
  orderBy?: string;
}
export interface UsePaginatedQueryArgs<T>
  extends UseQueryArgs<UsePaginatedQueryVariables, T> {
  variables: UsePaginatedQueryVariables;
  getDataCallback: (result: UseQueryState) => any;
  getTotalCountCallback: (result: UseQueryState) => any;
}

const useInfiniteQuery = <T extends { id: string }>({
  query,
  variables,
  getDataCallback,
  getTotalCountCallback,
  ...restProps
}: UsePaginatedQueryArgs<T>): {
  data: T[];
  fetching: boolean;
  error?: CombinedError;
  fetchMore: () => void;
  hasMore: boolean;
  total: number;
} => {
  const { limit, offset: initialOffset } = variables;

  const [offset, setOffset] = useState<number>(initialOffset);

  const [result] = useQuery({
    query,
    variables: { ...variables, limit, offset },
    ...restProps,
  });
  const data = getDataCallback(result);
  const totalCount = getTotalCountCallback(result);

  const [dataCache, setDataCache] = useState<T[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (variables.query !== undefined) {
      setDataCache([]);
      setOffset(0);
    }
  }, [variables.query]);

  useEffect(() => {
    data && setDataCache((prevData) => uniqBy([...prevData, ...data], "id"));
    totalCount && setTotal(totalCount);
  }, [data, totalCount]);

  const fetchMore = React.useCallback(() => {
    setOffset((prevOffset) => prevOffset + limit);
  }, [limit]);

  return {
    data: dataCache,
    fetching: result.fetching,
    error: result.error,
    fetchMore,
    total,
    hasMore: total > dataCache.length,
  };
};

export default useInfiniteQuery;
