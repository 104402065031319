import React from "react";
import styled from "@emotion/styled";
import { Button, LinkButton } from "@hundred5/design-system";
import { AuthPage } from "../";
import { useApiMutation } from "../../hooks/api-query";
import { useFlashMessages } from "../../utils/flash-messages";
import { useApiClient, useLogout } from "../../utils/authentication";
import { useGoogleReCaptchaToken } from "../../hooks/google-recaptcha";

interface Props {
  email: string;
}

export default function VerifyEmail(props: Props) {
  const logOut = useLogout();
  const { addMessage } = useFlashMessages();

  const client = useApiClient();
  const [sendEmail, { isLoading }] = useApiMutation(
    client.sendVerificationEmail,
    {
      onSuccess: () => addMessage({ type: "verification_email_sent" }),
    }
  );
  const {
    googleRecaptchaToken,
    refreshGoogleRecaptchaToken,
  } = useGoogleReCaptchaToken("verify_email");

  return (
    <AuthPage
      content={
        <>
          <h1>Verify your e-mail address</h1>
          <Message>
            A verification link has been sent to{" "}
            <a href={`mailto:${props.email}`}>{props.email}</a>. Click on the
            link to get started.
          </Message>
          <Button
            variant="secondary"
            disabled={isLoading || !googleRecaptchaToken}
            onClick={() => {
              if (googleRecaptchaToken) {
                sendEmail(googleRecaptchaToken);
                refreshGoogleRecaptchaToken();
              }
            }}
          >
            {isLoading ? "Sending..." : "Send again"}
          </Button>
        </>
      }
      footer={<LogoutButton onClick={() => logOut()}>‹ Log out</LogoutButton>}
    />
  );
}

const Message = styled.p`
  margin: 20px 0;
`;

const LogoutButton = styled(LinkButton)`
  font-size: 14px;
`;
