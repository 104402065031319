import gql from "graphql-tag";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  QuestionType: any;
  Time: any;
};

export type Admin = {
  __typename?: "Admin";
  experts: Array<Expert>;
  expertChallenges: Array<ExpertChallenge>;
  expertChallengesList: ExpertChallengeList;
  expertQuestionBatches: Array<ExpertQuestionBatch>;
  expertQuestionBatchesList: ExpertQuestionBatchList;
  flaggedQuestions: FlaggedQuestionList;
};

export type AdminExpertsArgs = {
  status?: Maybe<ExpertStatus>;
  name?: Maybe<Scalars["String"]>;
  isReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveExpert?: Maybe<Scalars["Boolean"]>;
  isActiveReviewer?: Maybe<Scalars["Boolean"]>;
  orderBy?: Maybe<OrderByMultiple>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  skillId?: Maybe<Scalars["ID"]>;
};

export type AdminExpertChallengesArgs = {
  params?: Maybe<ExpertChallengeParameters>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type AdminExpertChallengesListArgs = {
  params?: Maybe<ExpertChallengeParameters>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type AdminExpertQuestionBatchesArgs = {
  filter?: Maybe<ExpertQuestionBatchFilters>;
  orderBy?: Maybe<OrderBy>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type AdminExpertQuestionBatchesListArgs = {
  filter?: Maybe<ExpertQuestionBatchFilters>;
  orderBy?: Maybe<OrderBy>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type AdminFlaggedQuestionsArgs = {
  orderBy?: Maybe<OrderBy>;
  description?: Maybe<Scalars["String"]>;
  skillName?: Maybe<Scalars["String"]>;
  difficulty?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  skillTypeName?: Maybe<Scalars["String"]>;
};

export type Credit = {
  __typename?: "Credit";
  id: Scalars["ID"];
  expert: Expert;
  expertQuestionBatch?: Maybe<ExpertQuestionBatch>;
  amount: Scalars["Int"];
  executedAt: Scalars["Time"];
  externalTransactionID?: Maybe<Scalars["String"]>;
};

export type Expert = {
  __typename?: "Expert";
  id: Scalars["ID"];
  user: User;
  fullName: Scalars["String"];
  linkedin: Scalars["String"];
  billingAddress: Scalars["String"];
  bankName: Scalars["String"];
  iban: Scalars["String"];
  bicSwift: Scalars["String"];
  credits?: Maybe<Array<Credit>>;
  expertSkills?: Maybe<Array<ExpertSkill>>;
  expertQuestionBatches: Array<ExpertQuestionBatch>;
  expertQuestionBatchList?: Maybe<ExpertQuestionBatchList>;
  expertChallenges: Array<ExpertChallenge>;
  role: Scalars["String"];
  status: ExpertStatus;
  isReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveExpert?: Maybe<Scalars["Boolean"]>;
  isActiveReviewer?: Maybe<Scalars["Boolean"]>;
  createdAt: Scalars["Time"];
};

export type ExpertExpertQuestionBatchesArgs = {
  status?: Maybe<ExpertQuestionBatchStatus>;
  filter?: Maybe<ExpertQuestionBatchFilters>;
  orderBy?: Maybe<OrderBy>;
};

export type ExpertExpertQuestionBatchListArgs = {
  filter?: Maybe<ExpertQuestionBatchFilters>;
  orderBy?: Maybe<OrderBy>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type ExpertChallenge = {
  __typename?: "ExpertChallenge";
  id: Scalars["ID"];
  experts?: Maybe<Array<Expert>>;
  name: Scalars["String"];
  requiredQuestions: Scalars["Int"];
  deadline: Scalars["Int"];
  state?: Maybe<ExpertChallengeState>;
  visibility?: Maybe<ExpertChallengeVisibility>;
  isFirst?: Maybe<Scalars["Boolean"]>;
  expertChallengeSkills: Array<ExpertChallengeSkill>;
  /** @deprecated Use `expertQuestionBatchList` */
  expertQuestionBatches: Array<ExpertQuestionBatch>;
  expertQuestionBatchList?: Maybe<ExpertQuestionBatchList>;
  testType?: Maybe<TestType>;
};

export type ExpertChallengeExpertQuestionBatchesArgs = {
  status?: Maybe<ExpertQuestionBatchStatus>;
  orderBy?: Maybe<OrderBy>;
};

export type ExpertChallengeExpertQuestionBatchListArgs = {
  status?: Maybe<ExpertQuestionBatchStatus>;
  orderBy?: Maybe<OrderBy>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type ExpertChallengeList = {
  __typename?: "ExpertChallengeList";
  count: Scalars["Int"];
  nodes: Array<ExpertChallenge>;
};

export type ExpertChallengeParameters = {
  state?: Maybe<ExpertChallengeState>;
  visibility?: Maybe<ExpertChallengeVisibility>;
  isFirst?: Maybe<Scalars["Boolean"]>;
  query?: Maybe<Scalars["String"]>;
};

export type ExpertChallengeSkill = {
  __typename?: "ExpertChallengeSkill";
  id: Scalars["ID"];
  skill: TestGenSkill;
  expertChallenge: ExpertChallenge;
};

export enum ExpertChallengeState {
  Draft = "draft",
  Archived = "archived",
  Active = "active",
}

export enum ExpertChallengeVisibility {
  Public = "public",
  Private = "private",
}

export type ExpertList = {
  __typename?: "ExpertList";
  count: Scalars["Int"];
  nodes: Array<Expert>;
};

export type ExpertQuestion = {
  __typename?: "ExpertQuestion";
  id: Scalars["ID"];
  testGenQuestion: TestGenQuestion;
  expertQuestionBatch: ExpertQuestionBatch;
  status: ExpertQuestionStatus;
  statusChangedAt: Scalars["Time"];
  updatedAt: Scalars["Time"];
  comments?: Maybe<ExpertQuestionCommentList>;
  testType?: Maybe<TestType>;
};

export type ExpertQuestionBatch = {
  __typename?: "ExpertQuestionBatch";
  id: Scalars["ID"];
  expert: Expert;
  requiredQuestions: Scalars["Int"];
  deadline: Scalars["Int"];
  deadlineAt: Scalars["Time"];
  status: ExpertQuestionBatchStatus;
  statusChangedAt?: Maybe<Scalars["Time"]>;
  expertQuestions: Array<ExpertQuestion>;
  expertChallenge: ExpertChallenge;
  createdAt: Scalars["Time"];
  reviewers: ExpertList;
  paidAt?: Maybe<Scalars["Time"]>;
  reviewersPaidAt?: Maybe<Scalars["Time"]>;
  isOldBatch?: Maybe<Scalars["Boolean"]>;
  comment?: Maybe<Scalars["String"]>;
};

export type ExpertQuestionBatchFilters = {
  status?: Maybe<ExpertQuestionBatchStatus>;
  reviewing?: Maybe<Scalars["Boolean"]>;
  isOldBatch?: Maybe<Scalars["Boolean"]>;
  query?: Maybe<Scalars["String"]>;
};

export type ExpertQuestionBatchList = {
  __typename?: "ExpertQuestionBatchList";
  count: Scalars["Int"];
  nodes: Array<ExpertQuestionBatch>;
};

export enum ExpertQuestionBatchStatus {
  InProgress = "in_progress",
  Submitted = "submitted",
  UnderReview = "under_review",
  Reviewed = "reviewed",
  Rejected = "rejected",
  Accepted = "accepted",
  Active = "active",
  Completed = "completed",
  Feedback = "feedback",
  InReview = "in_review",
  NeedsAction = "needs_action",
}

export enum ExpertQuestionBatchStatusInput {
  Submitted = "submitted",
  Rejected = "rejected",
  Accepted = "accepted",
}

export type ExpertQuestionComment = {
  __typename?: "ExpertQuestionComment";
  id: Scalars["ID"];
  created_at?: Maybe<Scalars["Time"]>;
  updated_at?: Maybe<Scalars["Time"]>;
  author: Scalars["String"];
  expert?: Maybe<Expert>;
  expertQuestion: ExpertQuestion;
  message: Scalars["String"];
  types: Array<QuestionCommentTypes>;
};

export type ExpertQuestionCommentInput = {
  message: Scalars["String"];
  types: Array<QuestionCommentTypes>;
};

export type ExpertQuestionCommentList = {
  __typename?: "ExpertQuestionCommentList";
  count: Scalars["Int"];
  nodes: Array<ExpertQuestionComment>;
};

export enum ExpertQuestionStatus {
  Draft = "draft",
  Submitted = "submitted",
  Rejected = "rejected",
  Approved = "approved",
  Feedback = "feedback",
}

export type ExpertSkill = {
  __typename?: "ExpertSkill";
  id: Scalars["ID"];
  isApproved: Scalars["Boolean"];
  skill: TestGenSkill;
  expert: Expert;
};

export enum ExpertStatus {
  Active = "active",
  Inactive = "inactive",
  OnHold = "on_hold",
  Suspended = "suspended",
}

export type FlaggedQuestionList = {
  __typename?: "FlaggedQuestionList";
  count: Scalars["Int"];
  nodes: Array<TestGenQuestion>;
};

export type Me = {
  __typename?: "Me";
  id: Scalars["ID"];
  email: Scalars["String"];
  name: Scalars["String"];
  role: Scalars["String"];
  isEmailVerified: Scalars["Boolean"];
  intercomUserHash?: Maybe<Scalars["String"]>;
  isReviewer: Scalars["Boolean"];
  isActiveReviewer: Scalars["Boolean"];
  isActiveExpert: Scalars["Boolean"];
  createdAt: Scalars["Time"];
};

export type Mutation = {
  __typename?: "Mutation";
  createFlaggedQuestionBatch: ExpertQuestionBatch;
  createCredit: Credit;
  updateUpdate: Credit;
  deleteDelete: Credit;
  updateExpert?: Maybe<Expert>;
  createExpert?: Maybe<Expert>;
  createExpertByEmail?: Maybe<Expert>;
  createExpertChallenge: ExpertChallenge;
  updateExpertChallenge: ExpertChallenge;
  deleteExpertChallenge: Scalars["Boolean"];
  archiveExpertChallenge: ExpertChallenge;
  acceptExpertChallenge: ExpertQuestionBatch;
  addExpertChallengeSkill: ExpertChallengeSkill;
  updateExpertChallengeSkill: ExpertChallengeSkill;
  deleteExpertChallengeSkill: ExpertChallengeSkill;
  reviewExpertQuestion: ExpertQuestion;
  createExpertQuestion: ExpertQuestion;
  updateExpertQuestion: ExpertQuestion;
  deleteExpertQuestion: ExpertQuestion;
  submitExpertQuestion: ExpertQuestion;
  deleteExpertQuestionBatch: Scalars["Boolean"];
  submitExpertQuestionBatch: ExpertQuestionBatch;
  acceptExpertQuestionBatchReview: ExpertQuestionBatch;
  reviewExpertQuestionBatch: ExpertQuestionBatch;
  adminReviewExpertQuestionBatch: ExpertQuestionBatch;
  assignReviewerToBatch: Array<Expert>;
  removeReviewerFromBatch: Array<Expert>;
  markExpertQuestionBatchAsPaid: ExpertQuestionBatch;
  markReviewersAsPaid: ExpertQuestionBatch;
  addExpertQuestionComment?: Maybe<ExpertQuestionComment>;
  updateExpertQuestionComment?: Maybe<ExpertQuestionComment>;
  addExpertSkill: ExpertSkill;
  updateExpertSkill: ExpertSkill;
  deleteExpertSkill: ExpertSkill;
  updateMe: Me;
  updateTestGenQuestion: TestGenQuestion;
};

export type MutationCreateFlaggedQuestionBatchArgs = {
  ids: Array<Scalars["ID"]>;
  reviewerID?: Maybe<Scalars["ID"]>;
  skillIDs: Array<Scalars["ID"]>;
  challengeName?: Maybe<Scalars["String"]>;
  deadline?: Maybe<Scalars["Int"]>;
};

export type MutationCreateCreditArgs = {
  input: NewCredit;
};

export type MutationUpdateUpdateArgs = {
  id: Scalars["ID"];
  input?: Maybe<NewCredit>;
};

export type MutationDeleteDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateExpertArgs = {
  id: Scalars["ID"];
  input: UpdateExpert;
};

export type MutationCreateExpertArgs = {
  input: UpdateExpert;
  userID?: Maybe<Scalars["ID"]>;
};

export type MutationCreateExpertByEmailArgs = {
  input: UpdateExpert;
  userEmail: Scalars["String"];
};

export type MutationCreateExpertChallengeArgs = {
  input: NewExpertChallenge;
};

export type MutationUpdateExpertChallengeArgs = {
  id: Scalars["ID"];
  input: NewExpertChallenge;
};

export type MutationDeleteExpertChallengeArgs = {
  id: Scalars["ID"];
};

export type MutationArchiveExpertChallengeArgs = {
  id: Scalars["ID"];
};

export type MutationAcceptExpertChallengeArgs = {
  id: Scalars["ID"];
  expertID?: Maybe<Scalars["ID"]>;
};

export type MutationAddExpertChallengeSkillArgs = {
  input: NewExpertChallengeSkill;
};

export type MutationUpdateExpertChallengeSkillArgs = {
  id: Scalars["ID"];
  input: NewExpertChallengeSkill;
};

export type MutationDeleteExpertChallengeSkillArgs = {
  id: Scalars["ID"];
};

export type MutationReviewExpertQuestionArgs = {
  id: Scalars["ID"];
  status: ExpertQuestionStatus;
  feedback?: Maybe<ExpertQuestionCommentInput>;
};

export type MutationCreateExpertQuestionArgs = {
  batchID: Scalars["ID"];
  question: NewTestGenQuestion;
};

export type MutationUpdateExpertQuestionArgs = {
  id: Scalars["ID"];
  question: NewTestGenQuestion;
};

export type MutationDeleteExpertQuestionArgs = {
  id: Scalars["ID"];
};

export type MutationSubmitExpertQuestionArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteExpertQuestionBatchArgs = {
  id: Scalars["ID"];
};

export type MutationSubmitExpertQuestionBatchArgs = {
  id: Scalars["ID"];
};

export type MutationAcceptExpertQuestionBatchReviewArgs = {
  id: Scalars["ID"];
};

export type MutationReviewExpertQuestionBatchArgs = {
  id: Scalars["ID"];
  status: ExpertQuestionBatchStatusInput;
  comment?: Maybe<Scalars["String"]>;
};

export type MutationAdminReviewExpertQuestionBatchArgs = {
  id: Scalars["ID"];
  status: ExpertQuestionBatchStatus;
};

export type MutationAssignReviewerToBatchArgs = {
  id: Scalars["ID"];
  expertID: Scalars["ID"];
};

export type MutationRemoveReviewerFromBatchArgs = {
  id: Scalars["ID"];
  expertID: Scalars["ID"];
};

export type MutationMarkExpertQuestionBatchAsPaidArgs = {
  id: Scalars["ID"];
};

export type MutationMarkReviewersAsPaidArgs = {
  id: Scalars["ID"];
};

export type MutationAddExpertQuestionCommentArgs = {
  expertQuestionID: Scalars["ID"];
  message: Scalars["String"];
  types: Array<QuestionCommentTypes>;
};

export type MutationUpdateExpertQuestionCommentArgs = {
  expertQuestionCommentID: Scalars["ID"];
  message: Scalars["String"];
  types?: Maybe<Array<Maybe<QuestionCommentTypes>>>;
};

export type MutationAddExpertSkillArgs = {
  skillID: Scalars["ID"];
  expertID?: Maybe<Scalars["ID"]>;
  isApproved?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateExpertSkillArgs = {
  id: Scalars["ID"];
  input?: Maybe<UpdateExpertSkill>;
};

export type MutationDeleteExpertSkillArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateMeArgs = {
  input: UpdateMe;
};

export type MutationUpdateTestGenQuestionArgs = {
  id: Scalars["ID"];
  question: NewTestGenQuestion;
};

export type NewCredit = {
  expertID: Scalars["ID"];
  expertQuestionBatchID?: Maybe<Scalars["ID"]>;
  amount: Scalars["Int"];
  executedAt: Scalars["Time"];
  externalTransactionID?: Maybe<Scalars["String"]>;
};

export type NewExpertChallenge = {
  expertIDs?: Maybe<Array<Scalars["ID"]>>;
  name?: Maybe<Scalars["String"]>;
  requiredQuestions?: Maybe<Scalars["Int"]>;
  deadline?: Maybe<Scalars["Int"]>;
  state?: Maybe<ExpertChallengeState>;
  visibility?: Maybe<ExpertChallengeVisibility>;
  expertChallengeSkillIDs?: Maybe<Array<Scalars["ID"]>>;
  testType: TestType;
};

export type NewExpertChallengeSkill = {
  skillID: Scalars["ID"];
  expertChallengeID: Scalars["ID"];
};

export type NewTestGenQuestion = {
  skillID: Scalars["ID"];
  questionType: Scalars["QuestionType"];
  title: Scalars["String"];
  description: Scalars["String"];
  points: Scalars["Int"];
  difficulty: QuestionDifficulty;
  durationInSeconds: Scalars["Int"];
  notes: Scalars["String"];
  options?: Maybe<Array<NewTestGenQuestionOption>>;
  testType?: Maybe<TestType>;
};

export type NewTestGenQuestionOption = {
  points: Scalars["Int"];
  content: Scalars["String"];
  orderWeight: Scalars["Float"];
};

export type OrderBy = {
  field?: Maybe<OrderByField>;
  direction?: Maybe<OrderByDirection>;
};

export enum OrderByDirection {
  Asc = "asc",
  Desc = "desc",
}

export enum OrderByField {
  CreatedAt = "created_at",
  StatusChangedAt = "status_changed_at",
  FullName = "full_name",
  Name = "name",
  Email = "email",
  TestGenSkillId = "test_gen_skill_id",
  DeadlineAt = "deadline_at",
}

export type OrderByMultiple = {
  fields?: Maybe<Array<OrderByField>>;
  direction?: Maybe<OrderByDirection>;
  coalesce?: Maybe<Scalars["Boolean"]>;
};

export type Query = {
  __typename?: "Query";
  admin: Admin;
  /** @deprecated Use either `expert` or `admin.experts` */
  experts: Array<Expert>;
  expert?: Maybe<Expert>;
  /** @deprecated Use either `expert.expertChallenges` or `admin.expertChallenges` */
  expertChallenges: Array<ExpertChallenge>;
  expertChallenge: ExpertChallenge;
  expertQuestion?: Maybe<ExpertQuestion>;
  expertQuestionBatch?: Maybe<ExpertQuestionBatch>;
  /** @deprecated Use either `expert.expertQuestionBatches` or `admin.expertQuestionBatches` */
  expertQuestionBatches: Array<ExpertQuestionBatch>;
  expertQuestionComment?: Maybe<ExpertQuestionComment>;
  me?: Maybe<Me>;
  testGenQuestion: TestGenQuestion;
  testGenSkills: Array<TestGenSkill>;
  testGenSkillTypes: Array<TestGenSkillType>;
};

export type QueryExpertsArgs = {
  status?: Maybe<ExpertStatus>;
  name?: Maybe<Scalars["String"]>;
  isReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveExpert?: Maybe<Scalars["Boolean"]>;
  orderBy?: Maybe<OrderByMultiple>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type QueryExpertArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryExpertChallengesArgs = {
  params?: Maybe<ExpertChallengeParameters>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type QueryExpertChallengeArgs = {
  id: Scalars["ID"];
};

export type QueryExpertQuestionArgs = {
  id: Scalars["ID"];
};

export type QueryExpertQuestionBatchArgs = {
  id: Scalars["ID"];
};

export type QueryExpertQuestionBatchesArgs = {
  filter?: Maybe<ExpertQuestionBatchFilters>;
  orderBy?: Maybe<OrderBy>;
  first?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type QueryExpertQuestionCommentArgs = {
  id: Scalars["ID"];
};

export type QueryTestGenQuestionArgs = {
  id: Scalars["ID"];
};

export enum QuestionCommentTypes {
  Formal = "formal",
  Topic = "topic",
  WrongAnswers = "wrong_answers",
  ObviousAnswers = "obvious_answers",
  Time = "time",
  Difficulty = "difficulty",
  Other = "other",
}

export enum QuestionDifficulty {
  Standard = "standard",
  Hard = "hard",
}

export enum QuestionFlagReason {
  Rating = "rating",
  TooHard = "too_hard",
  TooEasy = "too_easy",
}

export enum Role {
  Admin = "ADMIN",
  Expert = "EXPERT",
  ActiveExpert = "ACTIVE_EXPERT",
  OnHoldExpert = "ON_HOLD_EXPERT",
  InactiveExpert = "INACTIVE_EXPERT",
  Reviewer = "REVIEWER",
  ActiveReviewer = "ACTIVE_REVIEWER",
  InactiveReviewer = "INACTIVE_REVIEWER",
  OnHoldReviewer = "ON_HOLD_REVIEWER",
  User = "USER",
}

export enum Table {
  TestGenQuestions = "TEST_GEN_QUESTIONS",
  Experts = "EXPERTS",
  ExpertQuestionBatches = "EXPERT_QUESTION_BATCHES",
  ExpertQuestionComments = "EXPERT_QUESTION_COMMENTS",
  ExpertQuestions = "EXPERT_QUESTIONS",
  ExpertChallenges = "EXPERT_CHALLENGES",
  TestGenSkillExpertJt = "TEST_GEN_SKILL_EXPERT_JT",
}

export type TestGenQuestion = {
  __typename?: "TestGenQuestion";
  id: Scalars["ID"];
  createdAt: Scalars["Time"];
  updatedAt: Scalars["Time"];
  deletedAt: Scalars["Time"];
  skill: TestGenSkill;
  questionType: Scalars["QuestionType"];
  title: Scalars["String"];
  description: Scalars["String"];
  points: Scalars["Int"];
  difficulty: QuestionDifficulty;
  durationInSeconds: Scalars["Int"];
  notes: Scalars["String"];
  options?: Maybe<Array<TestGenQuestionOption>>;
  isReviewed: Scalars["Boolean"];
  state: Scalars["String"];
  flaggedAt?: Maybe<Scalars["Time"]>;
  flagReason?: Maybe<Scalars["String"]>;
  negativeRating?: Maybe<Scalars["Int"]>;
  averageScore?: Maybe<Scalars["Float"]>;
  displayed?: Maybe<Scalars["Int"]>;
  feedback?: Maybe<Array<TestGenQuestionFeedback>>;
  oldTestGenQuestion?: Maybe<TestGenQuestion>;
  testType?: Maybe<TestType>;
};

export type TestGenQuestionFeedback = {
  __typename?: "TestGenQuestionFeedback";
  message?: Maybe<Scalars["String"]>;
  types?: Maybe<Array<QuestionCommentTypes>>;
};

export type TestGenQuestionOption = {
  __typename?: "TestGenQuestionOption";
  points: Scalars["Int"];
  content: Scalars["String"];
  orderWeight: Scalars["Float"];
};

export type TestGenSkill = {
  __typename?: "TestGenSkill";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type TestGenSkillType = {
  __typename?: "TestGenSkillType";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export enum TestType {
  Quiz = "quiz",
  Video = "video",
  Homework = "homework",
  Interview = "interview",
}

export type UpdateExpert = {
  fullName?: Maybe<Scalars["String"]>;
  linkedin?: Maybe<Scalars["String"]>;
  billingAddress?: Maybe<Scalars["String"]>;
  bankName?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  bicSwift?: Maybe<Scalars["String"]>;
  expertSkills?: Maybe<Array<Scalars["ID"]>>;
  status?: Maybe<ExpertStatus>;
  isReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveExpert?: Maybe<Scalars["Boolean"]>;
};

export type UpdateExpertSkill = {
  skillID?: Maybe<Scalars["ID"]>;
  expertID?: Maybe<Scalars["ID"]>;
  isApproved?: Maybe<Scalars["Boolean"]>;
};

export type UpdateMe = {
  name?: Maybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  name: Scalars["String"];
  email: Scalars["String"];
  createdAt: Scalars["Time"];
};

export type BatchReviewersQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BatchReviewersQuery = { __typename?: "Query" } & {
  expertQuestionBatch?: Maybe<
    { __typename?: "ExpertQuestionBatch" } & Pick<ExpertQuestionBatch, "id"> & {
        reviewers: { __typename?: "ExpertList" } & {
          nodes: Array<
            { __typename?: "Expert" } & Pick<Expert, "id"> & {
                user: { __typename?: "User" } & Pick<User, "name">;
              }
          >;
        };
        expertChallenge: { __typename?: "ExpertChallenge" } & {
          expertChallengeSkills: Array<
            { __typename?: "ExpertChallengeSkill" } & Pick<
              ExpertChallengeSkill,
              "id"
            > & {
                skill: { __typename?: "TestGenSkill" } & Pick<
                  TestGenSkill,
                  "id"
                >;
              }
          >;
        };
      }
  >;
};

export type AssignReviewerMutationVariables = Exact<{
  batchID: Scalars["ID"];
  expertID: Scalars["ID"];
}>;

export type AssignReviewerMutation = { __typename?: "Mutation" } & {
  assignReviewerToBatch: Array<{ __typename?: "Expert" } & Pick<Expert, "id">>;
};

export type ExpertChallengeFieldsFragment = {
  __typename?: "ExpertChallenge";
} & Pick<
  ExpertChallenge,
  | "id"
  | "state"
  | "deadline"
  | "requiredQuestions"
  | "name"
  | "isFirst"
  | "testType"
  | "visibility"
> & {
    expertChallengeSkills: Array<
      { __typename?: "ExpertChallengeSkill" } & Pick<
        ExpertChallengeSkill,
        "id"
      > & {
          skill: { __typename?: "TestGenSkill" } & Pick<
            TestGenSkill,
            "id" | "name"
          >;
        }
    >;
    expertQuestionBatches: Array<
      { __typename?: "ExpertQuestionBatch" } & Pick<
        ExpertQuestionBatch,
        "id" | "status"
      > & { expert: { __typename?: "Expert" } & Pick<Expert, "id"> }
    >;
    experts?: Maybe<
      Array<
        { __typename?: "Expert" } & Pick<Expert, "id" | "fullName"> & {
            user: { __typename?: "User" } & Pick<User, "name">;
            expertQuestionBatches: Array<
              { __typename?: "ExpertQuestionBatch" } & Pick<
                ExpertQuestionBatch,
                "id" | "status"
              > & {
                  expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
                    ExpertChallenge,
                    "id"
                  >;
                  expertQuestions: Array<
                    { __typename?: "ExpertQuestion" } & Pick<
                      ExpertQuestion,
                      "status" | "statusChangedAt"
                    >
                  >;
                }
            >;
          }
      >
    >;
  };

export type ExpertQuestionBatchFieldsFragment = {
  __typename?: "ExpertQuestionBatch";
} & Pick<
  ExpertQuestionBatch,
  "id" | "status" | "statusChangedAt" | "deadlineAt" | "isOldBatch"
> & {
    expert: { __typename?: "Expert" } & Pick<Expert, "id" | "fullName"> & {
        user: { __typename?: "User" } & Pick<User, "id" | "name">;
      };
    expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
      ExpertChallenge,
      "id" | "name" | "requiredQuestions" | "testType"
    >;
    reviewers: { __typename?: "ExpertList" } & {
      nodes: Array<
        { __typename?: "Expert" } & Pick<Expert, "id" | "fullName"> & {
            user: { __typename?: "User" } & Pick<User, "id" | "name">;
          }
      >;
    };
    expertQuestions: Array<
      { __typename?: "ExpertQuestion" } & Pick<ExpertQuestion, "id" | "status">
    >;
  };

export type AdminChallengeListSearchQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListSearchQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    expertChallengesList: { __typename?: "ExpertChallengeList" } & Pick<
      ExpertChallengeList,
      "count"
    > & {
        nodes: Array<
          { __typename?: "ExpertChallenge" } & ExpertChallengeFieldsFragment
        >;
      };
  };
};

export type AdminChallengeListActiveQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListActiveQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    activeChallenges: { __typename?: "ExpertChallengeList" } & Pick<
      ExpertChallengeList,
      "count"
    > & {
        nodes: Array<
          { __typename?: "ExpertChallenge" } & ExpertChallengeFieldsFragment
        >;
      };
  };
};

export type AdminChallengeListInactiveQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListInactiveQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    inactiveChallenges: { __typename?: "ExpertChallengeList" } & Pick<
      ExpertChallengeList,
      "count"
    > & {
        nodes: Array<
          { __typename?: "ExpertChallenge" } & ExpertChallengeFieldsFragment
        >;
      };
  };
};

export type AdminChallengeListArchivedQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListArchivedQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    archivedChallenges: { __typename?: "ExpertChallengeList" } & Pick<
      ExpertChallengeList,
      "count"
    > & {
        nodes: Array<
          { __typename?: "ExpertChallenge" } & ExpertChallengeFieldsFragment
        >;
      };
  };
};

export type AdminChallengeListSearchBatchesQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListSearchBatchesQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    expertQuestionBatchesList: {
      __typename?: "ExpertQuestionBatchList";
    } & Pick<ExpertQuestionBatchList, "count"> & {
        nodes: Array<
          {
            __typename?: "ExpertQuestionBatch";
          } & ExpertQuestionBatchFieldsFragment
        >;
      };
  };
};

export type AdminChallengeListInReviewBatchesQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListInReviewBatchesQuery = {
  __typename?: "Query";
} & {
  admin: { __typename?: "Admin" } & {
    inReviewBatches: { __typename?: "ExpertQuestionBatchList" } & Pick<
      ExpertQuestionBatchList,
      "count"
    > & {
        nodes: Array<
          {
            __typename?: "ExpertQuestionBatch";
          } & ExpertQuestionBatchFieldsFragment
        >;
      };
  };
};

export type AdminChallengeListInProgressBatchesQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListInProgressBatchesQuery = {
  __typename?: "Query";
} & {
  admin: { __typename?: "Admin" } & {
    inProgressBatches: { __typename?: "ExpertQuestionBatchList" } & Pick<
      ExpertQuestionBatchList,
      "count"
    > & {
        nodes: Array<
          {
            __typename?: "ExpertQuestionBatch";
          } & ExpertQuestionBatchFieldsFragment
        >;
      };
  };
};

export type AdminChallengeListNeedsActionBatchesQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListNeedsActionBatchesQuery = {
  __typename?: "Query";
} & {
  admin: { __typename?: "Admin" } & {
    needsActionBatches: { __typename?: "ExpertQuestionBatchList" } & Pick<
      ExpertQuestionBatchList,
      "count"
    > & {
        nodes: Array<
          {
            __typename?: "ExpertQuestionBatch";
          } & ExpertQuestionBatchFieldsFragment
        >;
      };
  };
};

export type AdminChallengeListNeedsActionOldBatchesQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  query?: Maybe<Scalars["String"]>;
}>;

export type AdminChallengeListNeedsActionOldBatchesQuery = {
  __typename?: "Query";
} & {
  admin: { __typename?: "Admin" } & {
    needsActionBatches: { __typename?: "ExpertQuestionBatchList" } & Pick<
      ExpertQuestionBatchList,
      "count"
    > & {
        nodes: Array<
          {
            __typename?: "ExpertQuestionBatch";
          } & ExpertQuestionBatchFieldsFragment
        >;
      };
  };
};

export type AdminDashboardChallengesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AdminDashboardChallengesQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    expertChallenges: Array<
      { __typename?: "ExpertChallenge" } & Pick<
        ExpertChallenge,
        | "id"
        | "state"
        | "deadline"
        | "requiredQuestions"
        | "name"
        | "testType"
        | "visibility"
      > & {
          expertChallengeSkills: Array<
            { __typename?: "ExpertChallengeSkill" } & Pick<
              ExpertChallengeSkill,
              "id"
            > & {
                skill: { __typename?: "TestGenSkill" } & Pick<
                  TestGenSkill,
                  "id" | "name"
                >;
              }
          >;
          expertQuestionBatches: Array<
            { __typename?: "ExpertQuestionBatch" } & Pick<
              ExpertQuestionBatch,
              "id" | "status"
            > & { expert: { __typename?: "Expert" } & Pick<Expert, "id"> }
          >;
          experts?: Maybe<
            Array<
              { __typename?: "Expert" } & Pick<Expert, "id" | "fullName"> & {
                  user: { __typename?: "User" } & Pick<User, "name">;
                  expertQuestionBatches: Array<
                    { __typename?: "ExpertQuestionBatch" } & Pick<
                      ExpertQuestionBatch,
                      "id" | "status"
                    > & {
                        expertChallenge: {
                          __typename?: "ExpertChallenge";
                        } & Pick<ExpertChallenge, "id">;
                        expertQuestions: Array<
                          { __typename?: "ExpertQuestion" } & Pick<
                            ExpertQuestion,
                            "status" | "statusChangedAt"
                          >
                        >;
                      }
                  >;
                }
            >
          >;
        }
    >;
  };
};

export type AdminReviewBatchMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ExpertQuestionBatchStatus;
}>;

export type AdminReviewBatchMutation = { __typename?: "Mutation" } & {
  adminReviewExpertQuestionBatch: { __typename?: "ExpertQuestionBatch" } & Pick<
    ExpertQuestionBatch,
    "id"
  >;
};

export type RemoveReviewerMutationVariables = Exact<{
  batchID: Scalars["ID"];
  expertID: Scalars["ID"];
}>;

export type RemoveReviewerMutation = { __typename?: "Mutation" } & {
  removeReviewerFromBatch: Array<
    { __typename?: "Expert" } & Pick<Expert, "id">
  >;
};

export type MarkExpertAsPaidMutationVariables = Exact<{
  batchID: Scalars["ID"];
}>;

export type MarkExpertAsPaidMutation = { __typename?: "Mutation" } & {
  markExpertQuestionBatchAsPaid: { __typename?: "ExpertQuestionBatch" } & Pick<
    ExpertQuestionBatch,
    "id"
  >;
};

export type MarkReviewersAsPaidMutationVariables = Exact<{
  batchID: Scalars["ID"];
}>;

export type MarkReviewersAsPaidMutation = { __typename?: "Mutation" } & {
  markReviewersAsPaid: { __typename?: "ExpertQuestionBatch" } & Pick<
    ExpertQuestionBatch,
    "id"
  >;
};

export type AdminQuestionBatchQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AdminQuestionBatchQuery = { __typename?: "Query" } & {
  expertQuestionBatch?: Maybe<
    { __typename?: "ExpertQuestionBatch" } & Pick<
      ExpertQuestionBatch,
      | "id"
      | "requiredQuestions"
      | "status"
      | "statusChangedAt"
      | "comment"
      | "paidAt"
      | "reviewersPaidAt"
    > & {
        expertQuestions: Array<
          { __typename?: "ExpertQuestion" } & Pick<
            ExpertQuestion,
            "id" | "status" | "statusChangedAt"
          > & {
              comments?: Maybe<
                { __typename?: "ExpertQuestionCommentList" } & Pick<
                  ExpertQuestionCommentList,
                  "count"
                > & {
                    nodes: Array<
                      { __typename?: "ExpertQuestionComment" } & Pick<
                        ExpertQuestionComment,
                        "message"
                      >
                    >;
                  }
              >;
              testGenQuestion: { __typename?: "TestGenQuestion" } & Pick<
                TestGenQuestion,
                | "id"
                | "createdAt"
                | "updatedAt"
                | "description"
                | "isReviewed"
                | "questionType"
                | "state"
                | "difficulty"
                | "testType"
              >;
            }
        >;
        reviewers: { __typename?: "ExpertList" } & {
          nodes: Array<
            { __typename?: "Expert" } & Pick<Expert, "id"> & {
                user: { __typename?: "User" } & Pick<User, "id" | "name">;
              }
          >;
        };
        expertChallenge: { __typename?: "ExpertChallenge" } & {
          expertChallengeSkills: Array<
            { __typename?: "ExpertChallengeSkill" } & Pick<
              ExpertChallengeSkill,
              "id"
            > & {
                skill: { __typename?: "TestGenSkill" } & Pick<
                  TestGenSkill,
                  "id" | "name"
                >;
              }
          >;
        };
      }
  >;
};

export type DeleteBatchMutationVariables = Exact<{
  batchID: Scalars["ID"];
}>;

export type DeleteBatchMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteExpertQuestionBatch"
>;

export type SaveBillingFormMutationVariables = Exact<{
  id: Scalars["ID"];
  fullName?: Maybe<Scalars["String"]>;
  billingAddress?: Maybe<Scalars["String"]>;
  bankName?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  bicSwift?: Maybe<Scalars["String"]>;
}>;

export type SaveBillingFormMutation = { __typename?: "Mutation" } & {
  updateExpert?: Maybe<
    { __typename?: "Expert" } & Pick<
      Expert,
      "fullName" | "billingAddress" | "bankName" | "iban" | "bicSwift"
    >
  >;
};

export type BillingFormQueryVariables = Exact<{ [key: string]: never }>;

export type BillingFormQuery = { __typename?: "Query" } & {
  expert?: Maybe<
    { __typename?: "Expert" } & Pick<
      Expert,
      "id" | "fullName" | "billingAddress" | "bankName" | "iban" | "bicSwift"
    >
  >;
};

export type ExpertChallengeCreateMutationVariables = Exact<{
  input: NewExpertChallenge;
}>;

export type ExpertChallengeCreateMutation = { __typename?: "Mutation" } & {
  createExpertChallenge: { __typename?: "ExpertChallenge" } & Pick<
    ExpertChallenge,
    "id"
  >;
};

export type ExpertChallengeQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExpertChallengeQuery = { __typename?: "Query" } & {
  expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
    ExpertChallenge,
    | "id"
    | "name"
    | "deadline"
    | "requiredQuestions"
    | "state"
    | "visibility"
    | "testType"
  > & {
      expertChallengeSkills: Array<
        { __typename?: "ExpertChallengeSkill" } & Pick<
          ExpertChallengeSkill,
          "id"
        > & {
            skill: { __typename?: "TestGenSkill" } & Pick<TestGenSkill, "id">;
          }
      >;
      experts?: Maybe<
        Array<
          { __typename?: "Expert" } & Pick<Expert, "id"> & {
              user: { __typename?: "User" } & Pick<User, "id" | "name">;
            }
        >
      >;
      expertQuestionBatches: Array<
        { __typename?: "ExpertQuestionBatch" } & Pick<
          ExpertQuestionBatch,
          | "id"
          | "status"
          | "statusChangedAt"
          | "createdAt"
          | "deadlineAt"
          | "paidAt"
          | "reviewersPaidAt"
        > & {
            reviewers: { __typename?: "ExpertList" } & {
              nodes: Array<
                { __typename?: "Expert" } & {
                  user: { __typename?: "User" } & Pick<User, "name">;
                }
              >;
            };
            expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
              ExpertChallenge,
              "id" | "name"
            >;
            expert: { __typename?: "Expert" } & Pick<Expert, "id"> & {
                user: { __typename?: "User" } & Pick<User, "id" | "name">;
              };
          }
      >;
    };
};

export type ExpertChallengeUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  input: NewExpertChallenge;
}>;

export type ExpertChallengeUpdateMutation = { __typename?: "Mutation" } & {
  updateExpertChallenge: { __typename?: "ExpertChallenge" } & Pick<
    ExpertChallenge,
    | "id"
    | "name"
    | "deadline"
    | "requiredQuestions"
    | "state"
    | "visibility"
    | "testType"
  > & {
      expertChallengeSkills: Array<
        { __typename?: "ExpertChallengeSkill" } & Pick<
          ExpertChallengeSkill,
          "id"
        > & {
            skill: { __typename?: "TestGenSkill" } & Pick<TestGenSkill, "id">;
          }
      >;
    };
};

export type ExpertChallengeDeleteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExpertChallengeDeleteMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "deleteExpertChallenge"
>;

export type ExpertChallengeArchiveMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExpertChallengeArchiveMutation = { __typename?: "Mutation" } & {
  archiveExpertChallenge: { __typename?: "ExpertChallenge" } & Pick<
    ExpertChallenge,
    "id"
  >;
};

export type CreateExpertByEmailMutationVariables = Exact<{
  email: Scalars["String"];
  linkedin?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  skills?: Maybe<Array<Scalars["ID"]>>;
  isReviewer?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<ExpertStatus>;
}>;

export type CreateExpertByEmailMutation = { __typename?: "Mutation" } & {
  createExpertByEmail?: Maybe<
    { __typename?: "Expert" } & Pick<Expert, "id" | "fullName" | "linkedin"> & {
        user: { __typename?: "User" } & Pick<User, "email">;
        expertSkills?: Maybe<
          Array<
            { __typename?: "ExpertSkill" } & Pick<
              ExpertSkill,
              "id" | "isApproved"
            > & {
                skill: { __typename?: "TestGenSkill" } & Pick<
                  TestGenSkill,
                  "id" | "name"
                >;
              }
          >
        >;
      }
  >;
};

export type ExpertQuestionBatchFragment = {
  __typename?: "ExpertQuestionBatch";
} & Pick<
  ExpertQuestionBatch,
  "id" | "deadlineAt" | "requiredQuestions" | "status" | "statusChangedAt"
> & {
    expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
      ExpertChallenge,
      "id" | "requiredQuestions" | "testType"
    > & {
        expertChallengeSkills: Array<
          { __typename?: "ExpertChallengeSkill" } & Pick<
            ExpertChallengeSkill,
            "id"
          > & {
              skill: { __typename?: "TestGenSkill" } & Pick<
                TestGenSkill,
                "id" | "name"
              >;
            }
        >;
      };
    expertQuestions: Array<
      { __typename?: "ExpertQuestion" } & Pick<
        ExpertQuestion,
        "id" | "status" | "statusChangedAt"
      >
    >;
  };

export type ExpertDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type ExpertDashboardQuery = { __typename?: "Query" } & {
  expert?: Maybe<
    { __typename?: "Expert" } & Pick<Expert, "id" | "status"> & {
        expertSkills?: Maybe<
          Array<
            { __typename?: "ExpertSkill" } & Pick<
              ExpertSkill,
              "id" | "isApproved"
            > & {
                skill: { __typename?: "TestGenSkill" } & Pick<
                  TestGenSkill,
                  "id" | "name"
                >;
              }
          >
        >;
        expertChallenges: Array<
          { __typename?: "ExpertChallenge" } & Pick<
            ExpertChallenge,
            | "id"
            | "name"
            | "state"
            | "deadline"
            | "requiredQuestions"
            | "isFirst"
            | "testType"
          > & {
              expertChallengeSkills: Array<
                { __typename?: "ExpertChallengeSkill" } & Pick<
                  ExpertChallengeSkill,
                  "id"
                > & {
                    skill: { __typename?: "TestGenSkill" } & Pick<
                      TestGenSkill,
                      "id" | "name"
                    >;
                  }
              >;
            }
        >;
        expertQuestionBatches: Array<
          { __typename?: "ExpertQuestionBatch" } & ExpertQuestionBatchFragment
        >;
        expertQuestionBatchesForReview: Array<
          { __typename?: "ExpertQuestionBatch" } & ExpertQuestionBatchFragment
        >;
      }
  >;
};

export type ExpertDashboardAddSkillMutationVariables = Exact<{
  skillId: Scalars["ID"];
}>;

export type ExpertDashboardAddSkillMutation = { __typename?: "Mutation" } & {
  addExpertSkill: { __typename?: "ExpertSkill" } & Pick<
    ExpertSkill,
    "id" | "isApproved"
  > & {
      skill: { __typename?: "TestGenSkill" } & Pick<
        TestGenSkill,
        "id" | "name"
      >;
    };
};

export type ApproveExpertSkillMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ApproveExpertSkillMutation = { __typename?: "Mutation" } & {
  updateExpertSkill: { __typename?: "ExpertSkill" } & Pick<
    ExpertSkill,
    "id" | "isApproved"
  >;
};

export type DeleteExpertSkillMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteExpertSkillMutation = { __typename?: "Mutation" } & {
  deleteExpertSkill: { __typename?: "ExpertSkill" } & Pick<ExpertSkill, "id">;
};

export type AcceptExpertQuestionBatchReviewMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AcceptExpertQuestionBatchReviewMutation = {
  __typename?: "Mutation";
} & {
  acceptExpertQuestionBatchReview: {
    __typename?: "ExpertQuestionBatch";
  } & Pick<ExpertQuestionBatch, "id">;
};

export type ExpertListQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<OrderByMultiple>;
  isReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveExpert?: Maybe<Scalars["Boolean"]>;
  skillId?: Maybe<Scalars["ID"]>;
}>;

export type ExpertListQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    experts: Array<
      { __typename?: "Expert" } & Pick<
        Expert,
        | "id"
        | "createdAt"
        | "isReviewer"
        | "isActiveReviewer"
        | "isActiveExpert"
        | "status"
      > & {
          user: { __typename?: "User" } & Pick<User, "id" | "name" | "email">;
          expertSkills?: Maybe<
            Array<
              { __typename?: "ExpertSkill" } & Pick<
                ExpertSkill,
                "id" | "isApproved"
              > & {
                  skill: { __typename?: "TestGenSkill" } & Pick<
                    TestGenSkill,
                    "id" | "name"
                  >;
                }
            >
          >;
          expertQuestionBatches: Array<
            { __typename?: "ExpertQuestionBatch" } & Pick<
              ExpertQuestionBatch,
              "id" | "status" | "statusChangedAt" | "deadlineAt"
            > & {
                expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
                  ExpertChallenge,
                  "id" | "name" | "requiredQuestions"
                >;
                expertQuestions: Array<
                  { __typename?: "ExpertQuestion" } & Pick<ExpertQuestion, "id">
                >;
              }
          >;
        }
    >;
  };
};

export type UpdateExpertMutationVariables = Exact<{
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  linkedin?: Maybe<Scalars["String"]>;
  skills?: Maybe<Array<Scalars["ID"]>>;
  isReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveReviewer?: Maybe<Scalars["Boolean"]>;
  isActiveExpert?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<ExpertStatus>;
}>;

export type UpdateExpertMutation = { __typename?: "Mutation" } & {
  updateExpert?: Maybe<
    { __typename?: "Expert" } & Pick<
      Expert,
      | "id"
      | "linkedin"
      | "fullName"
      | "isReviewer"
      | "isActiveReviewer"
      | "isActiveExpert"
      | "status"
    > & {
        expertSkills?: Maybe<
          Array<
            { __typename?: "ExpertSkill" } & Pick<
              ExpertSkill,
              "id" | "isApproved"
            > & {
                skill: { __typename?: "TestGenSkill" } & Pick<
                  TestGenSkill,
                  "id" | "name"
                >;
              }
          >
        >;
      }
  >;
};

export type ExpertQuestionBatchQueryFragment = {
  __typename?: "ExpertQuestionBatch";
} & Pick<
  ExpertQuestionBatch,
  | "id"
  | "status"
  | "statusChangedAt"
  | "paidAt"
  | "deadlineAt"
  | "reviewersPaidAt"
  | "deadline"
> & {
    expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
      ExpertChallenge,
      "id" | "name"
    >;
    reviewers: { __typename?: "ExpertList" } & {
      nodes: Array<{ __typename?: "Expert" } & Pick<Expert, "id">>;
    };
  };

export type FetchExpertQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FetchExpertQuery = { __typename?: "Query" } & {
  expert?: Maybe<
    { __typename?: "Expert" } & Pick<
      Expert,
      | "id"
      | "fullName"
      | "linkedin"
      | "isReviewer"
      | "isActiveReviewer"
      | "isActiveExpert"
      | "status"
    > & {
        user: { __typename?: "User" } & Pick<User, "id" | "email" | "name">;
        expertSkills?: Maybe<
          Array<
            { __typename?: "ExpertSkill" } & Pick<
              ExpertSkill,
              "id" | "isApproved"
            > & {
                skill: { __typename?: "TestGenSkill" } & Pick<
                  TestGenSkill,
                  "id" | "name"
                >;
              }
          >
        >;
        expertQuestionBatches: Array<
          {
            __typename?: "ExpertQuestionBatch";
          } & ExpertQuestionBatchQueryFragment
        >;
        expertQuestionBatchesReviewed: Array<
          {
            __typename?: "ExpertQuestionBatch";
          } & ExpertQuestionBatchQueryFragment
        >;
      }
  >;
};

export type ExpertsDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type ExpertsDropdownQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    experts: Array<
      { __typename?: "Expert" } & Pick<
        Expert,
        "id" | "isReviewer" | "isActiveReviewer" | "isActiveExpert"
      > & {
          user: { __typename?: "User" } & Pick<User, "id" | "name">;
          expertSkills?: Maybe<
            Array<
              { __typename?: "ExpertSkill" } & Pick<
                ExpertSkill,
                "isApproved"
              > & {
                  skill: { __typename?: "TestGenSkill" } & Pick<
                    TestGenSkill,
                    "id"
                  >;
                }
            >
          >;
        }
    >;
  };
};

export type LockedPageQueryVariables = Exact<{ [key: string]: never }>;

export type LockedPageQuery = { __typename?: "Query" } & {
  me?: Maybe<
    { __typename?: "Me" } & Pick<
      Me,
      "id" | "name" | "email" | "role" | "isEmailVerified" | "intercomUserHash"
    >
  >;
};

export type NavbarQueryVariables = Exact<{ [key: string]: never }>;

export type NavbarQuery = { __typename?: "Query" } & {
  me?: Maybe<{ __typename?: "Me" } & Pick<Me, "id" | "name" | "role">>;
};

export type ProfileSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileSettingsQuery = { __typename?: "Query" } & {
  expert?: Maybe<
    { __typename?: "Expert" } & Pick<Expert, "id" | "linkedin"> & {
        user: { __typename?: "User" } & Pick<User, "id" | "name" | "email">;
      }
  >;
};

export type SaveProfileSettingsMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  linkedin?: Maybe<Scalars["String"]>;
}>;

export type SaveProfileSettingsMutation = { __typename?: "Mutation" } & {
  updateExpert?: Maybe<
    { __typename?: "Expert" } & Pick<Expert, "id" | "linkedin">
  >;
  updateMe: { __typename?: "Me" } & Pick<Me, "id" | "name">;
};

export type AcceptExpertChallengeMutationVariables = Exact<{
  id: Scalars["ID"];
  expertId?: Maybe<Scalars["ID"]>;
}>;

export type AcceptExpertChallengeMutation = { __typename?: "Mutation" } & {
  acceptExpertChallenge: { __typename?: "ExpertQuestionBatch" } & Pick<
    ExpertQuestionBatch,
    "id" | "deadline" | "requiredQuestions" | "status" | "statusChangedAt"
  > & {
      expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
        ExpertChallenge,
        "id"
      > & {
          expertChallengeSkills: Array<
            { __typename?: "ExpertChallengeSkill" } & Pick<
              ExpertChallengeSkill,
              "id"
            > & {
                skill: { __typename?: "TestGenSkill" } & Pick<
                  TestGenSkill,
                  "id" | "name"
                >;
              }
          >;
        };
    };
};

export type QuestionBatchHeaderQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type QuestionBatchHeaderQuery = { __typename?: "Query" } & {
  expertQuestionBatch?: Maybe<
    { __typename?: "ExpertQuestionBatch" } & Pick<
      ExpertQuestionBatch,
      | "id"
      | "createdAt"
      | "deadline"
      | "deadlineAt"
      | "requiredQuestions"
      | "status"
      | "statusChangedAt"
      | "paidAt"
      | "reviewersPaidAt"
    > & {
        expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
          ExpertChallenge,
          "id" | "testType"
        > & {
            expertChallengeSkills: Array<
              { __typename?: "ExpertChallengeSkill" } & Pick<
                ExpertChallengeSkill,
                "id"
              > & {
                  skill: { __typename?: "TestGenSkill" } & Pick<
                    TestGenSkill,
                    "id" | "name"
                  >;
                }
            >;
          };
        expertQuestions: Array<
          { __typename?: "ExpertQuestion" } & Pick<
            ExpertQuestion,
            "id" | "status" | "statusChangedAt"
          > & {
              testGenQuestion: { __typename?: "TestGenQuestion" } & Pick<
                TestGenQuestion,
                "difficulty"
              >;
            }
        >;
        expert: { __typename?: "Expert" } & Pick<Expert, "id"> & {
            user: { __typename?: "User" } & Pick<User, "id" | "name">;
          };
        reviewers: { __typename?: "ExpertList" } & {
          nodes: Array<{ __typename?: "Expert" } & Pick<Expert, "id">>;
        };
      }
  >;
  expert?: Maybe<{ __typename?: "Expert" } & Pick<Expert, "id">>;
};

export type QuestionBatchQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type QuestionBatchQuery = { __typename?: "Query" } & {
  expertQuestionBatch?: Maybe<
    { __typename?: "ExpertQuestionBatch" } & Pick<
      ExpertQuestionBatch,
      "id" | "requiredQuestions" | "status" | "statusChangedAt"
    > & {
        expert: { __typename?: "Expert" } & Pick<Expert, "id" | "status">;
        expertQuestions: Array<
          { __typename?: "ExpertQuestion" } & Pick<
            ExpertQuestion,
            "id" | "status" | "statusChangedAt"
          > & {
              comments?: Maybe<
                { __typename?: "ExpertQuestionCommentList" } & Pick<
                  ExpertQuestionCommentList,
                  "count"
                > & {
                    nodes: Array<
                      { __typename?: "ExpertQuestionComment" } & Pick<
                        ExpertQuestionComment,
                        "id" | "message"
                      >
                    >;
                  }
              >;
              testGenQuestion: { __typename?: "TestGenQuestion" } & Pick<
                TestGenQuestion,
                | "id"
                | "createdAt"
                | "updatedAt"
                | "description"
                | "isReviewed"
                | "questionType"
                | "state"
                | "difficulty"
                | "testType"
              >;
            }
        >;
        expertChallenge: { __typename?: "ExpertChallenge" } & {
          expertChallengeSkills: Array<
            { __typename?: "ExpertChallengeSkill" } & {
              skill: { __typename?: "TestGenSkill" } & Pick<
                TestGenSkill,
                "name"
              >;
            }
          >;
        };
      }
  >;
  expert?: Maybe<{ __typename?: "Expert" } & Pick<Expert, "id" | "status">>;
};

export type ExpertQuestionBatchesUnderReviewQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ExpertQuestionBatchesUnderReviewQuery = { __typename?: "Query" } & {
  expertQuestionBatches: Array<
    { __typename?: "ExpertQuestionBatch" } & Pick<
      ExpertQuestionBatch,
      "id" | "status"
    >
  >;
};

export type SubmitQuestionBatchMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SubmitQuestionBatchMutation = { __typename?: "Mutation" } & {
  submitExpertQuestionBatch: { __typename?: "ExpertQuestionBatch" } & Pick<
    ExpertQuestionBatch,
    "id"
  >;
};

export type ReviewExpertQuestionBatchMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ExpertQuestionBatchStatusInput;
  comment?: Maybe<Scalars["String"]>;
}>;

export type ReviewExpertQuestionBatchMutation = { __typename?: "Mutation" } & {
  reviewExpertQuestionBatch: { __typename?: "ExpertQuestionBatch" } & Pick<
    ExpertQuestionBatch,
    "id"
  >;
};

export type QuestionCommentsQueryVariables = Exact<{
  questionId: Scalars["ID"];
}>;

export type QuestionCommentsQuery = { __typename?: "Query" } & {
  expertQuestion?: Maybe<
    { __typename?: "ExpertQuestion" } & Pick<ExpertQuestion, "status"> & {
        comments?: Maybe<
          { __typename?: "ExpertQuestionCommentList" } & Pick<
            ExpertQuestionCommentList,
            "count"
          > & {
              nodes: Array<
                { __typename?: "ExpertQuestionComment" } & Pick<
                  ExpertQuestionComment,
                  "id" | "author" | "created_at" | "message" | "types"
                >
              >;
            }
        >;
      }
  >;
};

export type UpdateExpertQuestionCommentMutationVariables = Exact<{
  id: Scalars["ID"];
  message: Scalars["String"];
  types?: Maybe<Array<Maybe<QuestionCommentTypes>>>;
}>;

export type UpdateExpertQuestionCommentMutation = {
  __typename?: "Mutation";
} & {
  updateExpertQuestionComment?: Maybe<
    { __typename?: "ExpertQuestionComment" } & Pick<ExpertQuestionComment, "id">
  >;
};

export type UpdateQuestionFormQueryVariables = Exact<{
  questionId: Scalars["ID"];
}>;

export type UpdateQuestionFormQuery = { __typename?: "Query" } & {
  expertQuestion?: Maybe<
    { __typename?: "ExpertQuestion" } & Pick<
      ExpertQuestion,
      "id" | "status"
    > & {
        expertQuestionBatch: { __typename?: "ExpertQuestionBatch" } & Pick<
          ExpertQuestionBatch,
          "id"
        > & { expert: { __typename?: "Expert" } & Pick<Expert, "id"> };
        testGenQuestion: { __typename?: "TestGenQuestion" } & Pick<
          TestGenQuestion,
          | "id"
          | "questionType"
          | "title"
          | "description"
          | "difficulty"
          | "durationInSeconds"
          | "notes"
          | "testType"
        > & {
            oldTestGenQuestion?: Maybe<
              { __typename?: "TestGenQuestion" } & Pick<
                TestGenQuestion,
                | "flaggedAt"
                | "flagReason"
                | "negativeRating"
                | "averageScore"
                | "displayed"
              > & {
                  feedback?: Maybe<
                    Array<
                      { __typename?: "TestGenQuestionFeedback" } & Pick<
                        TestGenQuestionFeedback,
                        "message" | "types"
                      >
                    >
                  >;
                }
            >;
            options?: Maybe<
              Array<
                { __typename?: "TestGenQuestionOption" } & Pick<
                  TestGenQuestionOption,
                  "points" | "content" | "orderWeight"
                >
              >
            >;
            skill: { __typename?: "TestGenSkill" } & Pick<
              TestGenSkill,
              "id" | "name"
            >;
          };
        comments?: Maybe<
          { __typename?: "ExpertQuestionCommentList" } & Pick<
            ExpertQuestionCommentList,
            "count"
          >
        >;
      }
  >;
  expert?: Maybe<{ __typename?: "Expert" } & Pick<Expert, "id">>;
};

export type UpdateTestGenQuestionFormQueryVariables = Exact<{
  questionId: Scalars["ID"];
}>;

export type UpdateTestGenQuestionFormQuery = { __typename?: "Query" } & {
  testGenQuestion: { __typename?: "TestGenQuestion" } & Pick<
    TestGenQuestion,
    | "id"
    | "questionType"
    | "title"
    | "description"
    | "difficulty"
    | "durationInSeconds"
    | "notes"
    | "testType"
    | "flaggedAt"
    | "flagReason"
    | "negativeRating"
    | "averageScore"
    | "displayed"
  > & {
      options?: Maybe<
        Array<
          { __typename?: "TestGenQuestionOption" } & Pick<
            TestGenQuestionOption,
            "points" | "content" | "orderWeight"
          >
        >
      >;
      skill: { __typename?: "TestGenSkill" } & Pick<
        TestGenSkill,
        "id" | "name"
      >;
      feedback?: Maybe<
        Array<
          { __typename?: "TestGenQuestionFeedback" } & Pick<
            TestGenQuestionFeedback,
            "message" | "types"
          >
        >
      >;
    };
};

export type QuestionFormFeedbackQueryVariables = Exact<{
  questionId: Scalars["ID"];
}>;

export type QuestionFormFeedbackQuery = { __typename?: "Query" } & {
  testGenQuestion: { __typename?: "TestGenQuestion" } & Pick<
    TestGenQuestion,
    | "flaggedAt"
    | "flagReason"
    | "negativeRating"
    | "averageScore"
    | "displayed"
    | "testType"
  > & {
      skill: { __typename?: "TestGenSkill" } & Pick<TestGenSkill, "name">;
      feedback?: Maybe<
        Array<
          { __typename?: "TestGenQuestionFeedback" } & Pick<
            TestGenQuestionFeedback,
            "message" | "types"
          >
        >
      >;
    };
};

export type CreateQuestionFormQueryVariables = Exact<{
  batchId: Scalars["ID"];
}>;

export type CreateQuestionFormQuery = { __typename?: "Query" } & {
  expertQuestionBatch?: Maybe<
    { __typename?: "ExpertQuestionBatch" } & Pick<ExpertQuestionBatch, "id"> & {
        expertChallenge: { __typename?: "ExpertChallenge" } & Pick<
          ExpertChallenge,
          "id" | "testType"
        > & {
            expertChallengeSkills: Array<
              { __typename?: "ExpertChallengeSkill" } & Pick<
                ExpertChallengeSkill,
                "id"
              > & {
                  skill: { __typename?: "TestGenSkill" } & Pick<
                    TestGenSkill,
                    "id" | "name"
                  >;
                }
            >;
          };
      }
  >;
};

export type CreateQuestionMutationVariables = Exact<{
  batch: Scalars["ID"];
  question: NewTestGenQuestion;
}>;

export type CreateQuestionMutation = { __typename?: "Mutation" } & {
  createExpertQuestion: { __typename?: "ExpertQuestion" } & Pick<
    ExpertQuestion,
    "id"
  >;
};

export type UpdateQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
  question: NewTestGenQuestion;
}>;

export type UpdateQuestionMutation = { __typename?: "Mutation" } & {
  updateExpertQuestion: { __typename?: "ExpertQuestion" } & Pick<
    ExpertQuestion,
    "id"
  >;
};

export type SubmitQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SubmitQuestionMutation = { __typename?: "Mutation" } & {
  submitExpertQuestion: { __typename?: "ExpertQuestion" } & Pick<
    ExpertQuestion,
    "id" | "status" | "statusChangedAt"
  >;
};

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteQuestionMutation = { __typename?: "Mutation" } & {
  deleteExpertQuestion: { __typename?: "ExpertQuestion" } & Pick<
    ExpertQuestion,
    "id"
  > & {
      expertQuestionBatch: { __typename?: "ExpertQuestionBatch" } & Pick<
        ExpertQuestionBatch,
        "id"
      >;
    };
};

export type AddExpertQuestionCommentMutationVariables = Exact<{
  id: Scalars["ID"];
  message: Scalars["String"];
}>;

export type AddExpertQuestionCommentMutation = { __typename?: "Mutation" } & {
  addExpertQuestionComment?: Maybe<
    { __typename?: "ExpertQuestionComment" } & Pick<ExpertQuestionComment, "id">
  >;
};

export type UpdateTestGenQuestionQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
  question: NewTestGenQuestion;
}>;

export type UpdateTestGenQuestionQuestionMutation = {
  __typename?: "Mutation";
} & {
  updateTestGenQuestion: { __typename?: "TestGenQuestion" } & Pick<
    TestGenQuestion,
    "id"
  >;
};

export type QuestionReviewModalQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type QuestionReviewModalQuery = { __typename?: "Query" } & {
  expertQuestion?: Maybe<
    { __typename?: "ExpertQuestion" } & Pick<
      ExpertQuestion,
      "id" | "status"
    > & {
        testGenQuestion: { __typename?: "TestGenQuestion" } & Pick<
          TestGenQuestion,
          | "id"
          | "questionType"
          | "title"
          | "description"
          | "difficulty"
          | "durationInSeconds"
          | "notes"
          | "testType"
        > & {
            skill: { __typename?: "TestGenSkill" } & Pick<
              TestGenSkill,
              "id" | "name"
            >;
            options?: Maybe<
              Array<
                { __typename?: "TestGenQuestionOption" } & Pick<
                  TestGenQuestionOption,
                  "points" | "content" | "orderWeight"
                >
              >
            >;
            oldTestGenQuestion?: Maybe<
              { __typename?: "TestGenQuestion" } & Pick<
                TestGenQuestion,
                | "flaggedAt"
                | "flagReason"
                | "negativeRating"
                | "averageScore"
                | "displayed"
              > & {
                  feedback?: Maybe<
                    Array<
                      { __typename?: "TestGenQuestionFeedback" } & Pick<
                        TestGenQuestionFeedback,
                        "message" | "types"
                      >
                    >
                  >;
                }
            >;
          };
      }
  >;
};

export type ReviewExpertQuestionMutationVariables = Exact<{
  id: Scalars["ID"];
  status: ExpertQuestionStatus;
  feedback?: Maybe<ExpertQuestionCommentInput>;
}>;

export type ReviewExpertQuestionMutation = { __typename?: "Mutation" } & {
  reviewExpertQuestion: { __typename?: "ExpertQuestion" } & Pick<
    ExpertQuestion,
    "id"
  >;
};

export type QuestionReviewTableQueryVariables = Exact<{
  orderBy?: Maybe<OrderBy>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  difficulty?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  skillName?: Maybe<Scalars["String"]>;
  skillTypeName?: Maybe<Scalars["String"]>;
}>;

export type QuestionReviewTableQuery = { __typename?: "Query" } & {
  admin: { __typename?: "Admin" } & {
    flaggedQuestions: { __typename?: "FlaggedQuestionList" } & Pick<
      FlaggedQuestionList,
      "count"
    > & {
        nodes: Array<
          { __typename?: "TestGenQuestion" } & Pick<
            TestGenQuestion,
            | "id"
            | "createdAt"
            | "description"
            | "difficulty"
            | "flaggedAt"
            | "flagReason"
            | "negativeRating"
            | "averageScore"
            | "displayed"
          > & {
              skill: { __typename?: "TestGenSkill" } & Pick<
                TestGenSkill,
                "id" | "name"
              >;
              feedback?: Maybe<
                Array<
                  { __typename?: "TestGenQuestionFeedback" } & Pick<
                    TestGenQuestionFeedback,
                    "message"
                  >
                >
              >;
            }
        >;
      };
  };
};

export type CreateFlaggedQuestionBatchMutationVariables = Exact<{
  ids: Array<Scalars["ID"]>;
  reviewerID?: Maybe<Scalars["ID"]>;
  skillIDs: Array<Scalars["ID"]>;
  challengeName?: Maybe<Scalars["String"]>;
  deadline?: Maybe<Scalars["Int"]>;
}>;

export type CreateFlaggedQuestionBatchMutation = { __typename?: "Mutation" } & {
  createFlaggedQuestionBatch: { __typename?: "ExpertQuestionBatch" } & Pick<
    ExpertQuestionBatch,
    "id"
  >;
};

export type SkillTypesDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type SkillTypesDropdownQuery = { __typename?: "Query" } & {
  skillTypes: Array<
    { __typename?: "TestGenSkillType" } & Pick<TestGenSkillType, "id" | "name">
  >;
};

export type SkillsDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type SkillsDropdownQuery = { __typename?: "Query" } & {
  skills: Array<
    { __typename?: "TestGenSkill" } & Pick<TestGenSkill, "id" | "name">
  >;
};

export type CreateExpertMutationVariables = Exact<{
  name?: Maybe<Scalars["String"]>;
}>;

export type CreateExpertMutation = { __typename?: "Mutation" } & {
  createExpert?: Maybe<{ __typename?: "Expert" } & Pick<Expert, "id">>;
};

export const ExpertChallengeFieldsFragmentDoc = gql`
  fragment expertChallengeFields on ExpertChallenge {
    id
    state
    deadline
    requiredQuestions
    name
    isFirst
    testType
    visibility
    expertChallengeSkills {
      id
      skill {
        id
        name
      }
    }
    expertQuestionBatches {
      id
      status
      expert {
        id
      }
    }
    experts {
      id
      fullName
      user {
        name
      }
      expertQuestionBatches {
        id
        status
        expertChallenge {
          id
        }
        expertQuestions {
          status
          statusChangedAt
        }
      }
    }
  }
`;
export const ExpertQuestionBatchFieldsFragmentDoc = gql`
  fragment expertQuestionBatchFields on ExpertQuestionBatch {
    id
    status
    statusChangedAt
    deadlineAt
    isOldBatch
    expert {
      id
      fullName
      user {
        id
        name
      }
    }
    expertChallenge {
      id
      name
      requiredQuestions
      testType
    }
    reviewers {
      nodes {
        id
        fullName
        user {
          id
          name
        }
      }
    }
    expertQuestions {
      id
      status
    }
  }
`;
export const ExpertQuestionBatchFragmentDoc = gql`
  fragment expertQuestionBatch on ExpertQuestionBatch {
    id
    deadlineAt
    requiredQuestions
    status
    statusChangedAt
    expertChallenge {
      id
      requiredQuestions
      testType
      expertChallengeSkills {
        id
        skill {
          id
          name
        }
      }
    }
    expertQuestions {
      id
      status
      statusChangedAt
    }
  }
`;
export const ExpertQuestionBatchQueryFragmentDoc = gql`
  fragment expertQuestionBatchQuery on ExpertQuestionBatch {
    id
    status
    statusChangedAt
    paidAt
    deadlineAt
    reviewersPaidAt
    deadline
    expertChallenge {
      id
      name
    }
    reviewers {
      nodes {
        id
      }
    }
  }
`;
export const BatchReviewersDocument = gql`
  query BatchReviewers($id: ID!) {
    expertQuestionBatch(id: $id) {
      id
      reviewers {
        nodes {
          id
          user {
            name
          }
        }
      }
      expertChallenge {
        expertChallengeSkills {
          id
          skill {
            id
          }
        }
      }
    }
  }
`;

export function useBatchReviewersQuery(
  options: Omit<Urql.UseQueryArgs<BatchReviewersQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<BatchReviewersQuery>({
    query: BatchReviewersDocument,
    ...options,
  });
}
export const AssignReviewerDocument = gql`
  mutation AssignReviewer($batchID: ID!, $expertID: ID!) {
    assignReviewerToBatch(id: $batchID, expertID: $expertID) {
      id
    }
  }
`;

export function useAssignReviewerMutation() {
  return Urql.useMutation<
    AssignReviewerMutation,
    AssignReviewerMutationVariables
  >(AssignReviewerDocument);
}
export const AdminChallengeListSearchDocument = gql`
  query AdminChallengeListSearch($limit: Int, $offset: Int, $query: String) {
    admin {
      expertChallengesList(
        params: { query: $query, state: active, isFirst: false }
        first: $limit
        offset: $offset
      ) {
        count
        nodes {
          ...expertChallengeFields
        }
      }
    }
  }
  ${ExpertChallengeFieldsFragmentDoc}
`;

export function useAdminChallengeListSearchQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListSearchQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListSearchQuery>({
    query: AdminChallengeListSearchDocument,
    ...options,
  });
}
export const AdminChallengeListActiveDocument = gql`
  query AdminChallengeListActive($limit: Int, $offset: Int, $query: String) {
    admin {
      activeChallenges: expertChallengesList(
        params: { query: $query, state: active, isFirst: false }
        first: $limit
        offset: $offset
      ) {
        count
        nodes {
          ...expertChallengeFields
        }
      }
    }
  }
  ${ExpertChallengeFieldsFragmentDoc}
`;

export function useAdminChallengeListActiveQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListActiveQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListActiveQuery>({
    query: AdminChallengeListActiveDocument,
    ...options,
  });
}
export const AdminChallengeListInactiveDocument = gql`
  query AdminChallengeListInactive($limit: Int, $offset: Int, $query: String) {
    admin {
      inactiveChallenges: expertChallengesList(
        params: { query: $query, state: draft, isFirst: false }
        first: $limit
        offset: $offset
      ) {
        count
        nodes {
          ...expertChallengeFields
        }
      }
    }
  }
  ${ExpertChallengeFieldsFragmentDoc}
`;

export function useAdminChallengeListInactiveQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListInactiveQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListInactiveQuery>({
    query: AdminChallengeListInactiveDocument,
    ...options,
  });
}
export const AdminChallengeListArchivedDocument = gql`
  query AdminChallengeListArchived($limit: Int, $offset: Int, $query: String) {
    admin {
      archivedChallenges: expertChallengesList(
        params: { query: $query, state: archived, isFirst: false }
        first: $limit
        offset: $offset
      ) {
        count
        nodes {
          ...expertChallengeFields
        }
      }
    }
  }
  ${ExpertChallengeFieldsFragmentDoc}
`;

export function useAdminChallengeListArchivedQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListArchivedQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListArchivedQuery>({
    query: AdminChallengeListArchivedDocument,
    ...options,
  });
}
export const AdminChallengeListSearchBatchesDocument = gql`
  query AdminChallengeListSearchBatches(
    $limit: Int
    $offset: Int
    $query: String
  ) {
    admin {
      expertQuestionBatchesList(
        filter: { query: $query }
        first: $limit
        offset: $offset
      ) {
        count
        nodes {
          ...expertQuestionBatchFields
        }
      }
    }
  }
  ${ExpertQuestionBatchFieldsFragmentDoc}
`;

export function useAdminChallengeListSearchBatchesQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListSearchBatchesQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListSearchBatchesQuery>({
    query: AdminChallengeListSearchBatchesDocument,
    ...options,
  });
}
export const AdminChallengeListInReviewBatchesDocument = gql`
  query AdminChallengeListInReviewBatches(
    $limit: Int
    $offset: Int
    $query: String
  ) {
    admin {
      inReviewBatches: expertQuestionBatchesList(
        filter: { status: in_review, query: $query }
        first: $limit
        offset: $offset
        orderBy: { field: status_changed_at, direction: desc }
      ) {
        count
        nodes {
          ...expertQuestionBatchFields
        }
      }
    }
  }
  ${ExpertQuestionBatchFieldsFragmentDoc}
`;

export function useAdminChallengeListInReviewBatchesQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListInReviewBatchesQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListInReviewBatchesQuery>({
    query: AdminChallengeListInReviewBatchesDocument,
    ...options,
  });
}
export const AdminChallengeListInProgressBatchesDocument = gql`
  query AdminChallengeListInProgressBatches(
    $limit: Int
    $offset: Int
    $query: String
  ) {
    admin {
      inProgressBatches: expertQuestionBatchesList(
        filter: { status: in_progress, query: $query }
        first: $limit
        offset: $offset
        orderBy: { field: deadline_at, direction: desc }
      ) {
        count
        nodes {
          ...expertQuestionBatchFields
        }
      }
    }
  }
  ${ExpertQuestionBatchFieldsFragmentDoc}
`;

export function useAdminChallengeListInProgressBatchesQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListInProgressBatchesQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListInProgressBatchesQuery>({
    query: AdminChallengeListInProgressBatchesDocument,
    ...options,
  });
}
export const AdminChallengeListNeedsActionBatchesDocument = gql`
  query AdminChallengeListNeedsActionBatches(
    $limit: Int
    $offset: Int
    $query: String
  ) {
    admin {
      needsActionBatches: expertQuestionBatchesList(
        filter: { status: needs_action, query: $query, isOldBatch: false }
        first: $limit
        offset: $offset
      ) {
        count
        nodes {
          ...expertQuestionBatchFields
        }
      }
    }
  }
  ${ExpertQuestionBatchFieldsFragmentDoc}
`;

export function useAdminChallengeListNeedsActionBatchesQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListNeedsActionBatchesQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListNeedsActionBatchesQuery>({
    query: AdminChallengeListNeedsActionBatchesDocument,
    ...options,
  });
}
export const AdminChallengeListNeedsActionOldBatchesDocument = gql`
  query AdminChallengeListNeedsActionOldBatches(
    $limit: Int
    $offset: Int
    $query: String
  ) {
    admin {
      needsActionBatches: expertQuestionBatchesList(
        filter: { status: needs_action, query: $query, isOldBatch: true }
        first: $limit
        offset: $offset
      ) {
        count
        nodes {
          ...expertQuestionBatchFields
        }
      }
    }
  }
  ${ExpertQuestionBatchFieldsFragmentDoc}
`;

export function useAdminChallengeListNeedsActionOldBatchesQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminChallengeListNeedsActionOldBatchesQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminChallengeListNeedsActionOldBatchesQuery>({
    query: AdminChallengeListNeedsActionOldBatchesDocument,
    ...options,
  });
}
export const AdminDashboardChallengesDocument = gql`
  query AdminDashboardChallenges {
    admin {
      expertChallenges(params: { state: active, isFirst: false }, first: 5) {
        id
        state
        deadline
        requiredQuestions
        name
        testType
        visibility
        expertChallengeSkills {
          id
          skill {
            id
            name
          }
        }
        expertQuestionBatches {
          id
          status
          expert {
            id
          }
        }
        experts {
          id
          fullName
          user {
            name
          }
          expertQuestionBatches {
            id
            status
            expertChallenge {
              id
            }
            expertQuestions {
              status
              statusChangedAt
            }
          }
        }
      }
    }
  }
`;

export function useAdminDashboardChallengesQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminDashboardChallengesQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminDashboardChallengesQuery>({
    query: AdminDashboardChallengesDocument,
    ...options,
  });
}
export const AdminReviewBatchDocument = gql`
  mutation AdminReviewBatch($id: ID!, $status: ExpertQuestionBatchStatus!) {
    adminReviewExpertQuestionBatch(id: $id, status: $status) {
      id
    }
  }
`;

export function useAdminReviewBatchMutation() {
  return Urql.useMutation<
    AdminReviewBatchMutation,
    AdminReviewBatchMutationVariables
  >(AdminReviewBatchDocument);
}
export const RemoveReviewerDocument = gql`
  mutation RemoveReviewer($batchID: ID!, $expertID: ID!) {
    removeReviewerFromBatch(id: $batchID, expertID: $expertID) {
      id
    }
  }
`;

export function useRemoveReviewerMutation() {
  return Urql.useMutation<
    RemoveReviewerMutation,
    RemoveReviewerMutationVariables
  >(RemoveReviewerDocument);
}
export const MarkExpertAsPaidDocument = gql`
  mutation MarkExpertAsPaid($batchID: ID!) {
    markExpertQuestionBatchAsPaid(id: $batchID) {
      id
    }
  }
`;

export function useMarkExpertAsPaidMutation() {
  return Urql.useMutation<
    MarkExpertAsPaidMutation,
    MarkExpertAsPaidMutationVariables
  >(MarkExpertAsPaidDocument);
}
export const MarkReviewersAsPaidDocument = gql`
  mutation MarkReviewersAsPaid($batchID: ID!) {
    markReviewersAsPaid(id: $batchID) {
      id
    }
  }
`;

export function useMarkReviewersAsPaidMutation() {
  return Urql.useMutation<
    MarkReviewersAsPaidMutation,
    MarkReviewersAsPaidMutationVariables
  >(MarkReviewersAsPaidDocument);
}
export const AdminQuestionBatchDocument = gql`
  query AdminQuestionBatch($id: ID!) {
    expertQuestionBatch(id: $id) {
      id
      requiredQuestions
      status
      statusChangedAt
      comment
      paidAt
      reviewersPaidAt
      expertQuestions {
        id
        status
        statusChangedAt
        comments {
          count
          nodes {
            message
          }
        }
        testGenQuestion {
          id
          createdAt
          updatedAt
          description
          isReviewed
          questionType
          state
          difficulty
          testType
        }
      }
      reviewers {
        nodes {
          id
          user {
            id
            name
          }
        }
      }
      expertChallenge {
        expertChallengeSkills {
          id
          skill {
            id
            name
          }
        }
      }
    }
  }
`;

export function useAdminQuestionBatchQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminQuestionBatchQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<AdminQuestionBatchQuery>({
    query: AdminQuestionBatchDocument,
    ...options,
  });
}
export const DeleteBatchDocument = gql`
  mutation DeleteBatch($batchID: ID!) {
    deleteExpertQuestionBatch(id: $batchID)
  }
`;

export function useDeleteBatchMutation() {
  return Urql.useMutation<DeleteBatchMutation, DeleteBatchMutationVariables>(
    DeleteBatchDocument
  );
}
export const SaveBillingFormDocument = gql`
  mutation SaveBillingForm(
    $id: ID!
    $fullName: String
    $billingAddress: String
    $bankName: String
    $iban: String
    $bicSwift: String
  ) {
    updateExpert(
      id: $id
      input: {
        fullName: $fullName
        billingAddress: $billingAddress
        bankName: $bankName
        iban: $iban
        bicSwift: $bicSwift
      }
    ) {
      fullName
      billingAddress
      bankName
      iban
      bicSwift
    }
  }
`;

export function useSaveBillingFormMutation() {
  return Urql.useMutation<
    SaveBillingFormMutation,
    SaveBillingFormMutationVariables
  >(SaveBillingFormDocument);
}
export const BillingFormDocument = gql`
  query BillingForm {
    expert {
      id
      fullName
      billingAddress
      bankName
      iban
      bicSwift
    }
  }
`;

export function useBillingFormQuery(
  options: Omit<Urql.UseQueryArgs<BillingFormQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<BillingFormQuery>({
    query: BillingFormDocument,
    ...options,
  });
}
export const ExpertChallengeCreateDocument = gql`
  mutation ExpertChallengeCreate($input: NewExpertChallenge!) {
    createExpertChallenge(input: $input) {
      id
    }
  }
`;

export function useExpertChallengeCreateMutation() {
  return Urql.useMutation<
    ExpertChallengeCreateMutation,
    ExpertChallengeCreateMutationVariables
  >(ExpertChallengeCreateDocument);
}
export const ExpertChallengeDocument = gql`
  query ExpertChallenge($id: ID!) {
    expertChallenge(id: $id) {
      id
      name
      deadline
      requiredQuestions
      state
      visibility
      testType
      expertChallengeSkills {
        id
        skill {
          id
        }
      }
      experts {
        id
        user {
          id
          name
        }
      }
      expertQuestionBatches {
        id
        status
        statusChangedAt
        createdAt
        deadlineAt
        reviewers {
          nodes {
            user {
              name
            }
          }
        }
        paidAt
        reviewersPaidAt
        expertChallenge {
          id
          name
        }
        expert {
          id
          user {
            id
            name
          }
        }
      }
    }
  }
`;

export function useExpertChallengeQuery(
  options: Omit<Urql.UseQueryArgs<ExpertChallengeQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<ExpertChallengeQuery>({
    query: ExpertChallengeDocument,
    ...options,
  });
}
export const ExpertChallengeUpdateDocument = gql`
  mutation ExpertChallengeUpdate($id: ID!, $input: NewExpertChallenge!) {
    updateExpertChallenge(id: $id, input: $input) {
      id
      name
      deadline
      requiredQuestions
      state
      visibility
      testType
      expertChallengeSkills {
        id
        skill {
          id
        }
      }
    }
  }
`;

export function useExpertChallengeUpdateMutation() {
  return Urql.useMutation<
    ExpertChallengeUpdateMutation,
    ExpertChallengeUpdateMutationVariables
  >(ExpertChallengeUpdateDocument);
}
export const ExpertChallengeDeleteDocument = gql`
  mutation ExpertChallengeDelete($id: ID!) {
    deleteExpertChallenge(id: $id)
  }
`;

export function useExpertChallengeDeleteMutation() {
  return Urql.useMutation<
    ExpertChallengeDeleteMutation,
    ExpertChallengeDeleteMutationVariables
  >(ExpertChallengeDeleteDocument);
}
export const ExpertChallengeArchiveDocument = gql`
  mutation ExpertChallengeArchive($id: ID!) {
    archiveExpertChallenge(id: $id) {
      id
    }
  }
`;

export function useExpertChallengeArchiveMutation() {
  return Urql.useMutation<
    ExpertChallengeArchiveMutation,
    ExpertChallengeArchiveMutationVariables
  >(ExpertChallengeArchiveDocument);
}
export const CreateExpertByEmailDocument = gql`
  mutation CreateExpertByEmail(
    $email: String!
    $linkedin: String
    $name: String
    $skills: [ID!]
    $isReviewer: Boolean
    $status: ExpertStatus
  ) {
    createExpertByEmail(
      userEmail: $email
      input: {
        linkedin: $linkedin
        fullName: $name
        expertSkills: $skills
        isReviewer: $isReviewer
        status: $status
      }
    ) {
      id
      fullName
      linkedin
      user {
        email
      }
      expertSkills {
        id
        isApproved
        skill {
          id
          name
        }
      }
    }
  }
`;

export function useCreateExpertByEmailMutation() {
  return Urql.useMutation<
    CreateExpertByEmailMutation,
    CreateExpertByEmailMutationVariables
  >(CreateExpertByEmailDocument);
}
export const ExpertDashboardDocument = gql`
  query ExpertDashboard {
    expert {
      id
      status
      expertSkills {
        id
        isApproved
        skill {
          id
          name
        }
      }
      expertChallenges {
        id
        name
        state
        deadline
        requiredQuestions
        isFirst
        testType
        expertChallengeSkills {
          id
          skill {
            id
            name
          }
        }
      }
      expertQuestionBatches {
        ...expertQuestionBatch
      }
      expertQuestionBatchesForReview: expertQuestionBatches(
        filter: { reviewing: true }
      ) {
        ...expertQuestionBatch
      }
    }
  }
  ${ExpertQuestionBatchFragmentDoc}
`;

export function useExpertDashboardQuery(
  options: Omit<Urql.UseQueryArgs<ExpertDashboardQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<ExpertDashboardQuery>({
    query: ExpertDashboardDocument,
    ...options,
  });
}
export const ExpertDashboardAddSkillDocument = gql`
  mutation ExpertDashboardAddSkill($skillId: ID!) {
    addExpertSkill(skillID: $skillId) {
      id
      isApproved
      skill {
        id
        name
      }
    }
  }
`;

export function useExpertDashboardAddSkillMutation() {
  return Urql.useMutation<
    ExpertDashboardAddSkillMutation,
    ExpertDashboardAddSkillMutationVariables
  >(ExpertDashboardAddSkillDocument);
}
export const ApproveExpertSkillDocument = gql`
  mutation ApproveExpertSkill($id: ID!) {
    updateExpertSkill(id: $id, input: { isApproved: true }) {
      id
      isApproved
    }
  }
`;

export function useApproveExpertSkillMutation() {
  return Urql.useMutation<
    ApproveExpertSkillMutation,
    ApproveExpertSkillMutationVariables
  >(ApproveExpertSkillDocument);
}
export const DeleteExpertSkillDocument = gql`
  mutation DeleteExpertSkill($id: ID!) {
    deleteExpertSkill(id: $id) {
      id
    }
  }
`;

export function useDeleteExpertSkillMutation() {
  return Urql.useMutation<
    DeleteExpertSkillMutation,
    DeleteExpertSkillMutationVariables
  >(DeleteExpertSkillDocument);
}
export const AcceptExpertQuestionBatchReviewDocument = gql`
  mutation AcceptExpertQuestionBatchReview($id: ID!) {
    acceptExpertQuestionBatchReview(id: $id) {
      id
    }
  }
`;

export function useAcceptExpertQuestionBatchReviewMutation() {
  return Urql.useMutation<
    AcceptExpertQuestionBatchReviewMutation,
    AcceptExpertQuestionBatchReviewMutationVariables
  >(AcceptExpertQuestionBatchReviewDocument);
}
export const ExpertListDocument = gql`
  query ExpertList(
    $limit: Int
    $offset: Int
    $name: String
    $orderBy: OrderByMultiple
    $isReviewer: Boolean
    $isActiveReviewer: Boolean
    $isActiveExpert: Boolean
    $skillId: ID
  ) {
    admin {
      experts(
        first: $limit
        offset: $offset
        name: $name
        orderBy: $orderBy
        isReviewer: $isReviewer
        isActiveReviewer: $isActiveReviewer
        isActiveExpert: $isActiveExpert
        skillId: $skillId
      ) {
        id
        createdAt
        isReviewer
        isActiveReviewer
        isActiveExpert
        status
        user {
          id
          name
          email
        }
        expertSkills {
          id
          isApproved
          skill {
            id
            name
          }
        }
        expertQuestionBatches {
          id
          status
          statusChangedAt
          deadlineAt
          expertChallenge {
            id
            name
            requiredQuestions
          }
          expertQuestions {
            id
          }
        }
      }
    }
  }
`;

export function useExpertListQuery(
  options: Omit<Urql.UseQueryArgs<ExpertListQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<ExpertListQuery>({
    query: ExpertListDocument,
    ...options,
  });
}
export const UpdateExpertDocument = gql`
  mutation UpdateExpert(
    $id: ID!
    $name: String
    $linkedin: String
    $skills: [ID!]
    $isReviewer: Boolean
    $isActiveReviewer: Boolean
    $isActiveExpert: Boolean
    $status: ExpertStatus
  ) {
    updateExpert(
      id: $id
      input: {
        fullName: $name
        linkedin: $linkedin
        expertSkills: $skills
        isReviewer: $isReviewer
        isActiveReviewer: $isActiveReviewer
        isActiveExpert: $isActiveExpert
        status: $status
      }
    ) {
      id
      linkedin
      fullName
      isReviewer
      isActiveReviewer
      isActiveExpert
      status
      expertSkills {
        id
        isApproved
        skill {
          id
          name
        }
      }
    }
  }
`;

export function useUpdateExpertMutation() {
  return Urql.useMutation<UpdateExpertMutation, UpdateExpertMutationVariables>(
    UpdateExpertDocument
  );
}
export const FetchExpertDocument = gql`
  query FetchExpert($id: ID!) {
    expert(id: $id) {
      id
      fullName
      linkedin
      isReviewer
      isActiveReviewer
      isActiveExpert
      status
      user {
        id
        email
        name
      }
      expertSkills {
        id
        isApproved
        skill {
          id
          name
        }
      }
      expertQuestionBatches {
        ...expertQuestionBatchQuery
      }
      expertQuestionBatchesReviewed: expertQuestionBatches(
        filter: { reviewing: true }
      ) {
        ...expertQuestionBatchQuery
      }
    }
  }
  ${ExpertQuestionBatchQueryFragmentDoc}
`;

export function useFetchExpertQuery(
  options: Omit<Urql.UseQueryArgs<FetchExpertQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<FetchExpertQuery>({
    query: FetchExpertDocument,
    ...options,
  });
}
export const ExpertsDropdownDocument = gql`
  query ExpertsDropdown {
    admin {
      experts {
        id
        isReviewer
        isActiveReviewer
        isActiveExpert
        user {
          id
          name
        }
        expertSkills {
          isApproved
          skill {
            id
          }
        }
      }
    }
  }
`;

export function useExpertsDropdownQuery(
  options: Omit<Urql.UseQueryArgs<ExpertsDropdownQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<ExpertsDropdownQuery>({
    query: ExpertsDropdownDocument,
    ...options,
  });
}
export const LockedPageDocument = gql`
  query LockedPage {
    me {
      id
      name
      email
      role
      isEmailVerified
      intercomUserHash
    }
  }
`;

export function useLockedPageQuery(
  options: Omit<Urql.UseQueryArgs<LockedPageQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<LockedPageQuery>({
    query: LockedPageDocument,
    ...options,
  });
}
export const NavbarDocument = gql`
  query Navbar {
    me {
      id
      name
      role
    }
  }
`;

export function useNavbarQuery(
  options: Omit<Urql.UseQueryArgs<NavbarQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<NavbarQuery>({ query: NavbarDocument, ...options });
}
export const ProfileSettingsDocument = gql`
  query ProfileSettings {
    expert {
      id
      user {
        id
        name
        email
      }
      linkedin
    }
  }
`;

export function useProfileSettingsQuery(
  options: Omit<Urql.UseQueryArgs<ProfileSettingsQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<ProfileSettingsQuery>({
    query: ProfileSettingsDocument,
    ...options,
  });
}
export const SaveProfileSettingsDocument = gql`
  mutation SaveProfileSettings($id: ID!, $name: String!, $linkedin: String) {
    updateExpert(id: $id, input: { linkedin: $linkedin }) {
      id
      linkedin
    }
    updateMe(input: { name: $name }) {
      id
      name
    }
  }
`;

export function useSaveProfileSettingsMutation() {
  return Urql.useMutation<
    SaveProfileSettingsMutation,
    SaveProfileSettingsMutationVariables
  >(SaveProfileSettingsDocument);
}
export const AcceptExpertChallengeDocument = gql`
  mutation AcceptExpertChallenge($id: ID!, $expertId: ID) {
    acceptExpertChallenge(id: $id, expertID: $expertId) {
      id
      deadline
      requiredQuestions
      status
      statusChangedAt
      expertChallenge {
        id
        expertChallengeSkills {
          id
          skill {
            id
            name
          }
        }
      }
    }
  }
`;

export function useAcceptExpertChallengeMutation() {
  return Urql.useMutation<
    AcceptExpertChallengeMutation,
    AcceptExpertChallengeMutationVariables
  >(AcceptExpertChallengeDocument);
}
export const QuestionBatchHeaderDocument = gql`
  query QuestionBatchHeader($id: ID!) {
    expertQuestionBatch(id: $id) {
      id
      createdAt
      deadline
      deadlineAt
      requiredQuestions
      status
      statusChangedAt
      paidAt
      reviewersPaidAt
      expertChallenge {
        id
        testType
        expertChallengeSkills {
          id
          skill {
            id
            name
          }
        }
      }
      expertQuestions {
        id
        status
        statusChangedAt
        testGenQuestion {
          difficulty
        }
      }
      expert {
        id
        user {
          id
          name
        }
      }
      reviewers {
        nodes {
          id
        }
      }
    }
    expert {
      id
    }
  }
`;

export function useQuestionBatchHeaderQuery(
  options: Omit<
    Urql.UseQueryArgs<QuestionBatchHeaderQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<QuestionBatchHeaderQuery>({
    query: QuestionBatchHeaderDocument,
    ...options,
  });
}
export const QuestionBatchDocument = gql`
  query QuestionBatch($id: ID!) {
    expertQuestionBatch(id: $id) {
      id
      requiredQuestions
      status
      statusChangedAt
      expert {
        id
        status
      }
      expertQuestions {
        id
        status
        statusChangedAt
        comments {
          count
          nodes {
            id
            message
          }
        }
        testGenQuestion {
          id
          createdAt
          updatedAt
          description
          isReviewed
          questionType
          state
          difficulty
          testType
        }
      }
      expertChallenge {
        expertChallengeSkills {
          skill {
            name
          }
        }
      }
    }
    expert {
      id
      status
    }
  }
`;

export function useQuestionBatchQuery(
  options: Omit<Urql.UseQueryArgs<QuestionBatchQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<QuestionBatchQuery>({
    query: QuestionBatchDocument,
    ...options,
  });
}
export const ExpertQuestionBatchesUnderReviewDocument = gql`
  query ExpertQuestionBatchesUnderReview {
    expertQuestionBatches(filter: { status: under_review, reviewing: true }) {
      id
      status
    }
  }
`;

export function useExpertQuestionBatchesUnderReviewQuery(
  options: Omit<
    Urql.UseQueryArgs<ExpertQuestionBatchesUnderReviewQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<ExpertQuestionBatchesUnderReviewQuery>({
    query: ExpertQuestionBatchesUnderReviewDocument,
    ...options,
  });
}
export const SubmitQuestionBatchDocument = gql`
  mutation SubmitQuestionBatch($id: ID!) {
    submitExpertQuestionBatch(id: $id) {
      id
    }
  }
`;

export function useSubmitQuestionBatchMutation() {
  return Urql.useMutation<
    SubmitQuestionBatchMutation,
    SubmitQuestionBatchMutationVariables
  >(SubmitQuestionBatchDocument);
}
export const ReviewExpertQuestionBatchDocument = gql`
  mutation ReviewExpertQuestionBatch(
    $id: ID!
    $status: ExpertQuestionBatchStatusInput!
    $comment: String
  ) {
    reviewExpertQuestionBatch(id: $id, status: $status, comment: $comment) {
      id
    }
  }
`;

export function useReviewExpertQuestionBatchMutation() {
  return Urql.useMutation<
    ReviewExpertQuestionBatchMutation,
    ReviewExpertQuestionBatchMutationVariables
  >(ReviewExpertQuestionBatchDocument);
}
export const QuestionCommentsDocument = gql`
  query QuestionComments($questionId: ID!) {
    expertQuestion(id: $questionId) {
      status
      comments {
        count
        nodes {
          id
          author
          created_at
          message
          types
        }
      }
    }
  }
`;

export function useQuestionCommentsQuery(
  options: Omit<Urql.UseQueryArgs<QuestionCommentsQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<QuestionCommentsQuery>({
    query: QuestionCommentsDocument,
    ...options,
  });
}
export const UpdateExpertQuestionCommentDocument = gql`
  mutation UpdateExpertQuestionComment(
    $id: ID!
    $message: String!
    $types: [QuestionCommentTypes]
  ) {
    updateExpertQuestionComment(
      expertQuestionCommentID: $id
      message: $message
      types: $types
    ) {
      id
    }
  }
`;

export function useUpdateExpertQuestionCommentMutation() {
  return Urql.useMutation<
    UpdateExpertQuestionCommentMutation,
    UpdateExpertQuestionCommentMutationVariables
  >(UpdateExpertQuestionCommentDocument);
}
export const UpdateQuestionFormDocument = gql`
  query UpdateQuestionForm($questionId: ID!) {
    expertQuestion(id: $questionId) {
      id
      status
      expertQuestionBatch {
        id
        expert {
          id
        }
      }
      testGenQuestion {
        id
        questionType
        title
        description
        difficulty
        durationInSeconds
        notes
        testType
        oldTestGenQuestion {
          flaggedAt
          flagReason
          negativeRating
          averageScore
          displayed
          feedback {
            message
            types
          }
        }
        options {
          points
          content
          orderWeight
        }
        skill {
          id
          name
        }
      }
      comments {
        count
      }
    }
    expert {
      id
    }
  }
`;

export function useUpdateQuestionFormQuery(
  options: Omit<
    Urql.UseQueryArgs<UpdateQuestionFormQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<UpdateQuestionFormQuery>({
    query: UpdateQuestionFormDocument,
    ...options,
  });
}
export const UpdateTestGenQuestionFormDocument = gql`
  query UpdateTestGenQuestionForm($questionId: ID!) {
    testGenQuestion(id: $questionId) {
      id
      questionType
      title
      description
      difficulty
      durationInSeconds
      notes
      testType
      options {
        points
        content
        orderWeight
      }
      skill {
        id
        name
      }
      flaggedAt
      flagReason
      negativeRating
      averageScore
      displayed
      feedback {
        message
        types
      }
    }
  }
`;

export function useUpdateTestGenQuestionFormQuery(
  options: Omit<
    Urql.UseQueryArgs<UpdateTestGenQuestionFormQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<UpdateTestGenQuestionFormQuery>({
    query: UpdateTestGenQuestionFormDocument,
    ...options,
  });
}
export const QuestionFormFeedbackDocument = gql`
  query QuestionFormFeedback($questionId: ID!) {
    testGenQuestion(id: $questionId) {
      flaggedAt
      flagReason
      negativeRating
      averageScore
      displayed
      skill {
        name
      }
      feedback {
        message
        types
      }
      testType
    }
  }
`;

export function useQuestionFormFeedbackQuery(
  options: Omit<
    Urql.UseQueryArgs<QuestionFormFeedbackQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<QuestionFormFeedbackQuery>({
    query: QuestionFormFeedbackDocument,
    ...options,
  });
}
export const CreateQuestionFormDocument = gql`
  query CreateQuestionForm($batchId: ID!) {
    expertQuestionBatch(id: $batchId) {
      id
      expertChallenge {
        id
        testType
        expertChallengeSkills {
          id
          skill {
            id
            name
          }
        }
      }
    }
  }
`;

export function useCreateQuestionFormQuery(
  options: Omit<
    Urql.UseQueryArgs<CreateQuestionFormQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<CreateQuestionFormQuery>({
    query: CreateQuestionFormDocument,
    ...options,
  });
}
export const CreateQuestionDocument = gql`
  mutation CreateQuestion($batch: ID!, $question: NewTestGenQuestion!) {
    createExpertQuestion(batchID: $batch, question: $question) {
      id
    }
  }
`;

export function useCreateQuestionMutation() {
  return Urql.useMutation<
    CreateQuestionMutation,
    CreateQuestionMutationVariables
  >(CreateQuestionDocument);
}
export const UpdateQuestionDocument = gql`
  mutation UpdateQuestion($id: ID!, $question: NewTestGenQuestion!) {
    updateExpertQuestion(id: $id, question: $question) {
      id
    }
  }
`;

export function useUpdateQuestionMutation() {
  return Urql.useMutation<
    UpdateQuestionMutation,
    UpdateQuestionMutationVariables
  >(UpdateQuestionDocument);
}
export const SubmitQuestionDocument = gql`
  mutation SubmitQuestion($id: ID!) {
    submitExpertQuestion(id: $id) {
      id
      status
      statusChangedAt
    }
  }
`;

export function useSubmitQuestionMutation() {
  return Urql.useMutation<
    SubmitQuestionMutation,
    SubmitQuestionMutationVariables
  >(SubmitQuestionDocument);
}
export const DeleteQuestionDocument = gql`
  mutation DeleteQuestion($id: ID!) {
    deleteExpertQuestion(id: $id) {
      id
      expertQuestionBatch {
        id
      }
    }
  }
`;

export function useDeleteQuestionMutation() {
  return Urql.useMutation<
    DeleteQuestionMutation,
    DeleteQuestionMutationVariables
  >(DeleteQuestionDocument);
}
export const AddExpertQuestionCommentDocument = gql`
  mutation AddExpertQuestionComment($id: ID!, $message: String!) {
    addExpertQuestionComment(
      expertQuestionID: $id
      message: $message
      types: []
    ) {
      id
    }
  }
`;

export function useAddExpertQuestionCommentMutation() {
  return Urql.useMutation<
    AddExpertQuestionCommentMutation,
    AddExpertQuestionCommentMutationVariables
  >(AddExpertQuestionCommentDocument);
}
export const UpdateTestGenQuestionQuestionDocument = gql`
  mutation UpdateTestGenQuestionQuestion(
    $id: ID!
    $question: NewTestGenQuestion!
  ) {
    updateTestGenQuestion(id: $id, question: $question) {
      id
    }
  }
`;

export function useUpdateTestGenQuestionQuestionMutation() {
  return Urql.useMutation<
    UpdateTestGenQuestionQuestionMutation,
    UpdateTestGenQuestionQuestionMutationVariables
  >(UpdateTestGenQuestionQuestionDocument);
}
export const QuestionReviewModalDocument = gql`
  query QuestionReviewModal($id: ID!) {
    expertQuestion(id: $id) {
      id
      status
      testGenQuestion {
        id
        questionType
        title
        description
        difficulty
        durationInSeconds
        notes
        skill {
          id
          name
        }
        options {
          points
          content
          orderWeight
        }
        testType
        oldTestGenQuestion {
          flaggedAt
          flagReason
          negativeRating
          averageScore
          displayed
          feedback {
            message
            types
          }
        }
      }
    }
  }
`;

export function useQuestionReviewModalQuery(
  options: Omit<
    Urql.UseQueryArgs<QuestionReviewModalQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<QuestionReviewModalQuery>({
    query: QuestionReviewModalDocument,
    ...options,
  });
}
export const ReviewExpertQuestionDocument = gql`
  mutation ReviewExpertQuestion(
    $id: ID!
    $status: ExpertQuestionStatus!
    $feedback: ExpertQuestionCommentInput
  ) {
    reviewExpertQuestion(id: $id, status: $status, feedback: $feedback) {
      id
    }
  }
`;

export function useReviewExpertQuestionMutation() {
  return Urql.useMutation<
    ReviewExpertQuestionMutation,
    ReviewExpertQuestionMutationVariables
  >(ReviewExpertQuestionDocument);
}
export const QuestionReviewTableDocument = gql`
  query QuestionReviewTable(
    $orderBy: OrderBy
    $limit: Int
    $offset: Int
    $difficulty: String
    $description: String
    $skillName: String
    $skillTypeName: String
  ) {
    admin {
      flaggedQuestions(
        orderBy: $orderBy
        first: $limit
        offset: $offset
        difficulty: $difficulty
        description: $description
        skillName: $skillName
        skillTypeName: $skillTypeName
      ) {
        count
        nodes {
          id
          createdAt
          skill {
            id
            name
          }
          description
          difficulty
          flaggedAt
          flagReason
          negativeRating
          averageScore
          displayed
          feedback {
            message
          }
        }
      }
    }
  }
`;

export function useQuestionReviewTableQuery(
  options: Omit<
    Urql.UseQueryArgs<QuestionReviewTableQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<QuestionReviewTableQuery>({
    query: QuestionReviewTableDocument,
    ...options,
  });
}
export const CreateFlaggedQuestionBatchDocument = gql`
  mutation CreateFlaggedQuestionBatch(
    $ids: [ID!]!
    $reviewerID: ID
    $skillIDs: [ID!]!
    $challengeName: String
    $deadline: Int
  ) {
    createFlaggedQuestionBatch(
      ids: $ids
      reviewerID: $reviewerID
      skillIDs: $skillIDs
      challengeName: $challengeName
      deadline: $deadline
    ) {
      id
    }
  }
`;

export function useCreateFlaggedQuestionBatchMutation() {
  return Urql.useMutation<
    CreateFlaggedQuestionBatchMutation,
    CreateFlaggedQuestionBatchMutationVariables
  >(CreateFlaggedQuestionBatchDocument);
}
export const SkillTypesDropdownDocument = gql`
  query SkillTypesDropdown {
    skillTypes: testGenSkillTypes {
      id
      name
    }
  }
`;

export function useSkillTypesDropdownQuery(
  options: Omit<
    Urql.UseQueryArgs<SkillTypesDropdownQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<SkillTypesDropdownQuery>({
    query: SkillTypesDropdownDocument,
    ...options,
  });
}
export const SkillsDropdownDocument = gql`
  query SkillsDropdown {
    skills: testGenSkills {
      id
      name
    }
  }
`;

export function useSkillsDropdownQuery(
  options: Omit<Urql.UseQueryArgs<SkillsDropdownQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<SkillsDropdownQuery>({
    query: SkillsDropdownDocument,
    ...options,
  });
}
export const CreateExpertDocument = gql`
  mutation CreateExpert($name: String) {
    createExpert(input: { fullName: $name }) {
      id
    }
  }
`;

export function useCreateExpertMutation() {
  return Urql.useMutation<CreateExpertMutation, CreateExpertMutationVariables>(
    CreateExpertDocument
  );
}
