import config from "../../config";

const GOOGLE_RECAPTCHA_SCRIPT_URL = "https://www.google.com/recaptcha/api.js";

const GOOGLE_RECAPTCHA_SCRIPT_ID = "google-recaptcha-v3";

export const cleanGoogleRecaptcha = () => {
  (window as any).___grecaptcha_cfg = undefined;

  const script = document.querySelector(`#${GOOGLE_RECAPTCHA_SCRIPT_ID}`);
  if (script) {
    script.remove();
  }
};

const isScriptInjected = () =>
  !!document.querySelector(`#${GOOGLE_RECAPTCHA_SCRIPT_ID}`);

interface IInjectGoogleReCaptchaScriptParams {
  onLoad: () => void;
}

export const injectGoogleReCaptchaScript = ({
  onLoad,
}: IInjectGoogleReCaptchaScriptParams) => {
  if (isScriptInjected()) {
    onLoad();
    return;
  }

  const js = document.createElement("script");
  js.id = GOOGLE_RECAPTCHA_SCRIPT_ID;
  js.src = `${GOOGLE_RECAPTCHA_SCRIPT_URL}?render=${config.recaptchaSiteKey}`;
  js.onload = onLoad;
  js.async = true;

  const headElement = document.getElementsByTagName("head")[0];

  headElement.appendChild(js);
};
