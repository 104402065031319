import styled from "@emotion/styled";
import { Button, CheckmarkIcon, PlusWhiteIcon } from "@hundred5/design-system";
import { add, format, parseISO } from "date-fns";
import max from "lodash/max";
import { useRouter } from "next/router";
import React from "react";
import { Column, ReducerTableState } from "react-table";
import { CriticalIcon, WarningIcon } from "../../icons";
import {
  Expert,
  ExpertListDocument,
  ExpertListQuery,
  ExpertListQueryVariables,
  ExpertQuestionBatch,
  ExpertSkill,
  OrderBy,
  OrderByDirection,
  OrderByField,
} from "../../utils/graphql-queries";
import { Divider, Loader } from "../common";
import Table from "../table";
import { useStoreSortState, useTableData } from "../table/utils";
import SearchInput from "../seaerch-input";
import { DownshiftSearch } from "@togglhire/downshift-search";
import SkillsDropdown from "../skills-dropdown";

interface TableExpert {
  id: string;
  signedUp: Date;
  fullName: string;
  skills: string[];
  currentBatch: string;
  lastBatch: string;
  unapprovedSkills: string[];
  numAcceptedBatches: number;
  isReviewer: boolean;
  isActiveExpert: boolean;
  isActiveReviewer: boolean;
  status: "active" | "on_hold" | "inactive" | "suspended";
}

const sortFields: {
  [index: string]: { fields: OrderByField[]; coalesce: boolean };
} = {
  signedUp: { fields: [OrderByField.CreatedAt], coalesce: false },
  fullName: { fields: [OrderByField.Name, OrderByField.Email], coalesce: true },
};

const columns: Column<TableExpert>[] = [
  {
    Header: "Signed Up",
    accessor: "signedUp",
    style: { maxWidth: "140px" },
    sortType: "datetime",
    formatValue: (row: TableExpert) => format(row.signedUp, "dd/MM/yyyy"),
  },
  {
    Header: "Name",
    accessor: "fullName",
    style: { minWidth: "50px", maxWidth: "0", width: "33%" },
    formatValue: (row: TableExpert) => (
      <>
        {row.fullName}{" "}
        {row.isReviewer ? (
          <Badge>Reviewer</Badge>
        ) : (
          row.numAcceptedBatches < 3 &&
          row.status === "active" && <Badge>New</Badge>
        )}{" "}
        {row.status && row.status !== "active" && (
          <Badge>{row.status.replace("_", " ")}</Badge>
        )}
      </>
    ),
  },
  {
    Header: "Skills",
    accessor: "skills",
    style: {
      minWidth: "70px",
      maxWidth: "0",
      width: "33%",
      whiteSpace: "normal",
    },
    disableSortBy: true,
    formatValue: (row: TableExpert) =>
      row.skills.length > 0 ? (
        <>
          {row.unapprovedSkills.length > 0 && <WarningIcon />}
          {row.skills.map((skill) => (
            <Badge
              key={skill}
              unapproved={row.unapprovedSkills.includes(skill)}
            >
              {skill}
            </Badge>
          ))}
        </>
      ) : (
        <>
          <CriticalIcon /> No Skills
        </>
      ),
  },
  {
    Header: "Current Set",
    accessor: "currentBatch",
    style: { minWidth: "100px", maxWidth: "0", width: "33%" },
    disableSortBy: true,
  },
  {
    Header: "Last Set",
    accessor: "lastBatch",
    style: { minWidth: "140px", maxWidth: "140px" },
    disableSortBy: true,
  },
  {
    Header: "Active Expert",
    accessor: "isActiveExpert",
    style: {
      minWidth: "70px",
      maxWidth: "0",
      width: "33%",
      whiteSpace: "normal",
    },
    disableSortBy: true,
    formatValue: (row: TableExpert) =>
      row.isActiveExpert ? <CheckmarkIcon /> : null,
  },
  {
    Header: "Active Reviewer",
    accessor: "isActiveReviewer",
    style: {
      minWidth: "100px",
      maxWidth: "0",
      width: "33%",
      whiteSpace: "normal",
    },
    disableSortBy: true,
    formatValue: (row: TableExpert) =>
      row.isActiveReviewer ? <CheckmarkIcon /> : null,
  },
];

interface Props {
  limit?: number;
  dashboard?: boolean;
}

export default function ExpertTable({ limit = 20, dashboard = false }: Props) {
  const router = useRouter();
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [sortState, sortStateCallback] = useStoreSortState("expert", {
    id: "signedUp",
    desc: true,
  });
  const [filterReviewers, setFilterReviewers] = React.useState(false);
  const [filterActive, setFilterActive] = React.useState<
    "expert" | "reviewer" | ""
  >("");
  const [skillSearchValue, setSkillSearchValue] = React.useState<
    string | undefined
  >();

  const getQueryDataCallback = (data: ExpertListQuery): Expert[] | undefined =>
    data?.admin.experts as Expert[];
  const getQueryOptions = (
    offset: number,
    limit: number,
    orderBy: OrderBy
  ) => ({
    variables: {
      offset,
      limit,
      name: searchValue || undefined,
      isReviewer: filterReviewers ? true : null,
      isActiveReviewer: filterActive === "reviewer" ? true : null,
      isActiveExpert: filterActive === "expert" ? true : null,
      orderBy: dashboard
        ? { direction: OrderByDirection.Desc, fields: [OrderByField.CreatedAt] }
        : orderBy,
      skillId: skillSearchValue,
    },
  });
  const {
    setDataCache,
    setTableData,
    setOffset,
    canLoadMore,
    tableData,
    tableOptions,
    inViewRef,
  } = useTableData<
    ExpertListQuery,
    Expert,
    TableExpert,
    ExpertListQueryVariables
  >({
    getQueryDataCallback,
    parseTableItemsCallback: getExpertsForTable,
    queryDocument: ExpertListDocument,
    getQueryOptions,
    sortState,
    sortFields,
  });

  const stateCallback = React.useCallback(
    (newState: ReducerTableState<object>) => {
      setDataCache([]);
      setTableData([]);
      setOffset(0);
      sortStateCallback(newState);
    },
    [sortStateCallback, setDataCache, setOffset, setTableData]
  );

  return (
    <ContentContainer>
      <Inputs>
        <Button
          size="small"
          onClick={() =>
            router.push({
              pathname: "/admin/experts/new",
              query: { returnURL: router.pathname },
            })
          }
        >
          <PlusWhiteIcon /> Add Expert
        </Button>
        {dashboard && (
          <Button
            size="small"
            variant="secondary"
            onClick={() => router.push("/admin/experts")}
          >
            View all experts
          </Button>
        )}
        {!dashboard && (
          <SearchFilterContainer>
            <Button
              size="small"
              variant="secondary"
              onClick={() => setFilterReviewers(!filterReviewers)}
            >
              {filterReviewers ? "Show all" : "Filter Reviewers"}
            </Button>
            <SkillsDropdown
              onSelect={(skill) => {
                setSkillSearchValue(skill?.id);
                setDataCache([]);
                setOffset(0);
              }}
              placeholder="Filter by skill"
            />
            <DownshiftSearch
              items={[
                { id: "0", name: "active expert" },
                { id: "1", name: "active reviewer" },
              ]}
              onInputValueChange={() => {}}
              onSelect={(id) => {
                if (id) {
                  setFilterActive(id === "0" ? "expert" : "reviewer");
                  setDataCache([]);
                  setOffset(0);
                }
              }}
              inputValue={filterActive}
              onClear={() => {
                setFilterActive("");
                setDataCache([]);
                setOffset(0);
              }}
              placeholder="Filter by activity"
            />

            <SearchInput
              placeholder="Find expert"
              onSearch={(value) => {
                setSearchValue(value);
                setOffset(0);
                setDataCache([]);
              }}
            />
          </SearchFilterContainer>
        )}
      </Inputs>

      <Divider />
      <Table
        columns={columns}
        data={tableData}
        getRowProps={(row) => ({
          onClick: () =>
            router.push({
              pathname: `/admin/experts/${row.original.id}`,
              query: { returnURL: router.pathname },
            }),
        })}
        tableOptions={tableOptions}
        stateCallback={dashboard ? sortStateCallback : stateCallback}
      />
      {!dashboard && canLoadMore && (
        <LoadMore ref={inViewRef}>
          <Loader />
        </LoadMore>
      )}
    </ContentContainer>
  );
}

const getExpertsForTable = (experts: Expert[]): TableExpert[] => {
  return experts.map((expert) => {
    return {
      id: expert.id,
      signedUp: parseISO(expert.createdAt),
      fullName: expert.user.name || expert.user.email,
      skills: expert.expertSkills?.map((skill) => skill.skill.name) || [],
      currentBatch: currentBatchSummary(
        expert.expertQuestionBatches as ExpertQuestionBatch[]
      ),
      lastBatch: lastBatchDate(
        expert.expertQuestionBatches as ExpertQuestionBatch[]
      ),
      unapprovedSkills: unapprovedSkills(
        (expert.expertSkills || []) as ExpertSkill[]
      ),
      numAcceptedBatches: expert.expertQuestionBatches.filter(
        (batch) => batch.status === "accepted"
      ).length,
      isReviewer: expert.isReviewer ?? false,
      isActiveExpert: expert.isActiveExpert ?? false,
      isActiveReviewer: expert.isActiveReviewer ?? false,
      status: expert.status,
    };
  });
};

const currentBatchSummary = (batches: ExpertQuestionBatch[]) => {
  const currentBatch = batches.find((batch) => batch?.status === "in_progress");
  if (!currentBatch) {
    return "";
  }
  return `${currentBatch.expertQuestions.length}/${
    currentBatch.expertChallenge.requiredQuestions
  } ${currentBatch.expertChallenge.name}, due by ${format(
    parseISO(currentBatch.deadlineAt),
    "do  MMM yyyy"
  )}`;
};

const lastBatchDate = (batches: ExpertQuestionBatch[]) => {
  const batchDates = batches
    .filter((batch) => ["accepted", "rejected"].includes(batch?.status))
    .map((batch) => parseISO(batch.statusChangedAt));
  const lastBatch = max(batchDates);
  if (!lastBatch) {
    return "";
  }
  return format(lastBatch, "dd/MM/yyyy");
};

const unapprovedSkills = (skills: ExpertSkill[]) => {
  return skills
    .filter((skill) => skill.isApproved === false)
    .map((skill) => skill.skill.name);
};

const ContentContainer = styled.div`
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin: 20px 24px 24px 24px;
  padding: 20px 35px 35px 35px;
`;

const Inputs = styled.div`
  display: flex;
  button {
    height: 30px;
    :last-child {
      margin-right: 0;
    }
  }
  align-items: center;
`;

const SearchFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 12px;
`;

interface BadgeProps {
  unapproved?: boolean;
}
const Badge = styled.div<BadgeProps>`
  display: inline-block;
  background-color: ${(props) => (props.unapproved ? "#ffffff" : "#e5e5e5")};
  color: ${(props) => (props.unapproved ? "#989898" : "inherit")};
  border: 1px solid #e5e5e5;
  padding: 2px 6px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 150%;
  margin: 2px;
`;

const LoadMore = styled.div`
  svg {
    height: 30px;
  }
`;
