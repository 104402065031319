import React from "react";
import ExpertTable from "../expert-table";
import { AdminDashboardChallenges } from "../";
import styled from "@emotion/styled";

export default function AdminDashboard() {
  return (
    <Container>
      <AdminDashboardChallenges />
      <ExpertsHeader>Experts</ExpertsHeader>
      <ExpertTable dashboard limit={10} />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
`;

const ExpertsHeader = styled.h1`
  margin: 20px 24px 0px 24px;
`;
