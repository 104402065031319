import React, { useState, useRef } from "react";
import { Expert, useExpertsDropdownQuery } from "../../utils/graphql-queries";
import { find } from "lodash";
import { DownshiftSearch } from "@togglhire/downshift-search";

export interface DropdownExpert {
  id: string;
  name: string;
}

interface Props {
  onSelect: (expert: DropdownExpert | null) => void;
  error?: boolean;
  isStateless?: boolean;
  skillIDs: string[];
  initialExpertID?: string;
  reviewers?: boolean;
}

export default function Main(props: Props) {
  const query = useRef<ReturnType<typeof useExpertsDropdownQuery>>();

  query.current = useExpertsDropdownQuery({
    pause: query.current?.[0].data !== undefined, //Execute only once
  });

  const [{ data }] = query.current;
  const experts = data?.admin.experts;
  const [expertName, setExpertName] = useState("");
  const [selectedExpertId, setSelectedExpertId] = useState<string | null>(null);
  const [listItems, setListItems] = useState<{ id: string; name: string }[]>(
    []
  );

  React.useEffect(() => {
    if (experts) {
      setListItems(
        experts
          .filter(
            (expert) =>
              props.skillIDs.every((skillID) => {
                const expertSkill = expert.expertSkills?.find(
                  (expertSkill) => expertSkill.skill.id === skillID
                );
                return expertSkill?.isApproved;
              }) && (props.reviewers ? expert.isReviewer : true)
          )
          .map((expert) => {
            return { id: expert.id, name: expert.user.name };
          })
      );
    }
  }, [experts, props.skillIDs, props.reviewers]);

  React.useEffect(() => {
    if (props.initialExpertID && listItems) {
      const expert = find(listItems, {
        id: props.initialExpertID,
      }) as DropdownExpert;
      setExpertName(expert.name);
      setSelectedExpertId(expert.id);
    }
  }, [props.initialExpertID, listItems]);

  return (
    <DownshiftSearch
      items={listItems || []}
      inputValue={expertName}
      noResults="No experts found for this skill"
      onSelect={(id, downshift) => {
        if (id) {
          const expert = find(listItems, { id }) as DropdownExpert;

          if (props.isStateless) {
            setExpertName("");
            setSelectedExpertId(null);
            downshift?.clearSelection();
          } else {
            setExpertName(expert.name);
            setSelectedExpertId(id);
          }
          props.onSelect(expert);
        }
      }}
      onClear={() => {
        setExpertName("");
        setSelectedExpertId(null);

        props.onSelect(null);
      }}
      onBlur={() => {
        if (selectedExpertId) {
          const expert = find(experts, { id: selectedExpertId }) as Expert;
          setExpertName(expert.user.name);
        } else {
          setExpertName("");
        }
      }}
      onInputValueChange={(expertName, downshift) => {
        setExpertName(expertName);

        if (expertName === "") {
          setSelectedExpertId(null);
          downshift?.clearSelection();
        }
      }}
      error={props.error}
    />
  );
}
