import React, { useState } from "react";
import { format } from "date-fns";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";
import {
  ExpertQuestionComment,
  ExpertQuestionStatus,
  QuestionCommentsQuery,
  useQuestionCommentsQuery,
} from "../../utils/graphql-queries";
import { commentTypeLabels } from "../../utils/comment-type-labels";
import EditCommentModal from "./edit-comment-modal";
import { type } from "os";
import { ViewMode } from "../../types/viewMode";

//region Component
interface Props {
  questionId: string | null;
  label?: React.ReactNode;
  enableEditing?: boolean;
  viewMode?: ViewMode;
  isReviewer?: boolean;
}

export default function QuestionComments({
  questionId,
  label,
  enableEditing,
  viewMode,
  isReviewer,
}: Props) {
  const [{ data }] = useQuestionCommentsQuery({
    variables: { questionId: questionId! },
    pause: !questionId,
  });
  const [
    editedComment,
    setEditedComment,
  ] = useState<ExpertQuestionComment | null>();

  if (!data?.expertQuestion?.comments?.count) {
    return null;
  }

  const comments = data.expertQuestion.comments;

  const allowEditingToReviewer = (comment: ExpertQuestionComment): boolean => {
    return (
      !!enableEditing &&
      !!isReviewer &&
      data?.expertQuestion?.status === ExpertQuestionStatus.Feedback &&
      comment.author === "Me"
    );
  };

  const allowEditingToAdmin = !!enableEditing && viewMode === ViewMode.Admin;

  return (
    <>
      <Container>
        <CommentsLabel>{label || "Comments"}</CommentsLabel>

        {comments?.nodes?.map((comment) => (
          <Comment key={comment.id}>
            <Author>
              <strong>{comment.author}</strong>&nbsp;
              <CommentDate>
                ({format(new Date(comment.created_at), "MMM dd, yyyy")})
              </CommentDate>
              <strong>:</strong>&nbsp;
            </Author>

            <CommentContent>
              {comment.types.length ? (
                <Issues>
                  Issues:{" "}
                  {comment.types
                    .map((issue) => commentTypeLabels[issue].label)
                    .join(", ")}
                </Issues>
              ) : null}
              {(allowEditingToAdmin ||
                allowEditingToReviewer(comment as ExpertQuestionComment)) && (
                <EditComment
                  onClick={() =>
                    setEditedComment(comment as ExpertQuestionComment)
                  }
                >
                  Edit
                </EditComment>
              )}
              <ReactMarkdown>{comment.message}</ReactMarkdown>
            </CommentContent>
          </Comment>
        ))}
      </Container>
      {editedComment && (
        <EditCommentModal
          open={!!editedComment}
          onClose={() => {
            setEditedComment(undefined);
          }}
          comment={editedComment}
        />
      )}
    </>
  );
}
//endregion

//region Styles
const Container = styled.div`
  & + * {
    padding-top: 10px;
  }
`;

const Comment = styled.div`
  p:first-of-type {
    margin-top: 0;
  }
`;

const CommentsLabel = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
`;

const Issues = styled.div`
  font-style: italic;
  color: #eb5757;
  font-size: 12px;
`;

const Author = styled.div``;

const CommentContent = styled.div``;

const CommentDate = styled.span`
  font-size: 10px;
`;

const EditComment = styled.span`
  color: #989898;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    color: #333333;
  }
`;
//endregion
