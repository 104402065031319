import React from "react";

export default function HiddenIcon() {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.10526 2.94737C10.1389 2.94737 11.7895 4.59789 11.7895 6.63158C11.7895 7.11053 11.6937 7.56 11.5242 7.98L13.6758 10.1316C14.7884 9.20316 15.6653 8.0021 16.2032 6.63158C14.9284 3.39684 11.7821 1.10526 8.09789 1.10526C7.06632 1.10526 6.07895 1.28947 5.16526 1.62105L6.75684 3.21263C7.17684 3.04316 7.62632 2.94737 8.10526 2.94737ZM0.736842 0.935789L2.41684 2.61579L2.75579 2.95474C1.53263 3.90526 0.574737 5.17263 0 6.63158C1.27474 9.86632 4.42105 12.1579 8.10526 12.1579C9.24737 12.1579 10.3379 11.9368 11.3326 11.5389L11.6421 11.8484L13.8011 14L14.7368 13.0642L1.67263 0L0.736842 0.935789ZM4.81158 5.01053L5.95368 6.15263C5.91684 6.30737 5.89474 6.46947 5.89474 6.63158C5.89474 7.85474 6.88211 8.8421 8.10526 8.8421C8.26737 8.8421 8.42947 8.82 8.58421 8.78316L9.72632 9.92526C9.23263 10.1684 8.68737 10.3158 8.10526 10.3158C6.07158 10.3158 4.42105 8.66526 4.42105 6.63158C4.42105 6.04947 4.56842 5.50421 4.81158 5.01053ZM7.98737 4.43579L10.3084 6.75684L10.3232 6.63895C10.3232 5.41579 9.33579 4.42842 8.11263 4.42842L7.98737 4.43579Z"
        fill="#828282"
      />
    </svg>
  );
}
