import React, { useMemo } from "react";
import {
  ExpertQuestionBatch,
  ExpertQuestionBatchStatus,
  ExpertQuestionStatus,
  QuestionDifficulty,
  useQuestionBatchHeaderQuery,
} from "../../utils/graphql-queries";
import { add, endOfMonth, format, parseISO } from "date-fns";
import styled from "@emotion/styled";
import { PageHeader } from "..";
import { LinkButton } from "@hundred5/design-system";
import Link from "next/link";
import { ViewMode } from "../../types/viewMode";
import { testTypes } from "../../utils/test-types";

const STATUS_ALIASES: { [index: string]: string } = {
  in_progress: "Started",
};

interface Props {
  viewMode?: ViewMode;
  batchId: string;
  backHref: string;
}

const QuestionBatchHeader: React.FC<Props> = (props) => {
  const context = useMemo(
    () => ({ additionalTypenames: ["ExpertQuestion"] }),
    []
  );

  const [{ data }] = useQuestionBatchHeaderQuery({
    variables: { id: props.batchId },
    context,
  });

  const questionBatch = data?.expertQuestionBatch;
  if (!questionBatch) {
    return null;
  }

  const reviewModeOn =
    questionBatch.expert.id !== data?.expert?.id ||
    props.viewMode === ViewMode.Admin;

  // Payment deadline is 10 days after the next payment processing date.
  // Next payment processing date is the last day of the current month if submitted
  // at least 7 days in advance, or else is the last day of the next month.
  const paymentDue =
    questionBatch.status === ExpertQuestionBatchStatus.Accepted
      ? add(
          endOfMonth(add(parseISO(questionBatch.statusChangedAt), { days: 7 })),
          { days: 10 }
        )
      : undefined;

  const approvedQuestions = questionBatch.expertQuestions.filter(
    (question) => question.status === "approved"
  ).length;

  return (
    <PageHeader
      backHref={props.backHref}
      title={
        <>
          {questionBatch.requiredQuestions}{" "}
          {getSkillName(questionBatch as ExpertQuestionBatch)} Questions (#
          {questionBatch.id})
        </>
      }
      badges={
        <>
          {questionBatch.status === ExpertQuestionBatchStatus.Accepted && (
            <HeaderSummary variant="positive">Accepted</HeaderSummary>
          )}
          {questionBatch.status === ExpertQuestionBatchStatus.Rejected && (
            <HeaderSummary variant="negative">Rejected</HeaderSummary>
          )}
          {questionBatch.paidAt && props.viewMode === ViewMode.Admin && (
            <HeaderSummary variant="positive">Expert paid</HeaderSummary>
          )}
          {questionBatch.reviewersPaidAt &&
            props.viewMode === ViewMode.Admin && (
              <HeaderSummary variant="positive">Reviewer paid</HeaderSummary>
            )}
          {props.viewMode === ViewMode.Expert && (
            <>
              {questionBatch.status !== ExpertQuestionBatchStatus.Accepted &&
                questionBatch.status !== ExpertQuestionBatchStatus.Rejected &&
                questionBatch.status !==
                  ExpertQuestionBatchStatus.UnderReview && (
                  <HeaderSummary>
                    Deadline:
                    <b>
                      {format(parseISO(questionBatch.deadlineAt), "MMM do")}
                    </b>
                  </HeaderSummary>
                )}
              {questionBatch.status === "under_review" && (
                <HeaderSummary>
                  Deadline:{" "}
                  <b>{format(parseISO(questionBatch.deadlineAt), "MMM do")}</b>
                </HeaderSummary>
              )}
            </>
          )}
          {props.viewMode === ViewMode.Admin && (
            <>
              <Link href={`/admin/experts/${questionBatch.expert.id}`} passHref>
                <LinkButton>{questionBatch.expert.user.name}</LinkButton>
              </Link>
              {questionBatch.status ===
              ExpertQuestionBatchStatus.UnderReview ? (
                <HeaderSummary>
                  Deadline:{" "}
                  <b>{format(parseISO(questionBatch.deadlineAt), "MMM do")}</b>
                </HeaderSummary>
              ) : (
                <HeaderSummary>
                  {STATUS_ALIASES[questionBatch.status] || questionBatch.status}
                  :{" "}
                  <b>
                    {format(parseISO(questionBatch.statusChangedAt), "MMM do")}
                  </b>
                </HeaderSummary>
              )}
              {questionBatch.status === ExpertQuestionBatchStatus.Accepted && (
                <>
                  {questionBatch.paidAt && (
                    <HeaderSummary>
                      {"Paid: "}
                      <b>{format(parseISO(questionBatch.paidAt), "MMM do")}</b>
                    </HeaderSummary>
                  )}
                  <HeaderSummary>
                    {"Approved standard questions: "}
                    <b>
                      {getAcceptedQuestionsCount(
                        questionBatch as ExpertQuestionBatch,
                        QuestionDifficulty.Standard
                      )}
                    </b>
                  </HeaderSummary>
                  <HeaderSummary>
                    {"Approved hard questions: "}
                    <b>
                      {getAcceptedQuestionsCount(
                        questionBatch as ExpertQuestionBatch,
                        QuestionDifficulty.Hard
                      )}
                    </b>
                  </HeaderSummary>
                </>
              )}
            </>
          )}
          <HeaderSummary>
            Questions:{" "}
            <b>
              {reviewModeOn
                ? approvedQuestions
                : questionBatch.expertQuestions.length}
              /{questionBatch.requiredQuestions}
            </b>
          </HeaderSummary>
          <HeaderSummary>
            Test type:{" "}
            <b>
              {questionBatch.expertChallenge.testType
                ? testTypes[questionBatch.expertChallenge.testType!!]
                : testTypes.quiz}
            </b>
          </HeaderSummary>
        </>
      }
    />
  );
};

export default QuestionBatchHeader;

const getSkillName = (questionBatch: ExpertQuestionBatch) => {
  return questionBatch.expertChallenge.expertChallengeSkills[0].skill.name;
};

const getAcceptedQuestionsCount = (
  questionBatch: ExpertQuestionBatch,
  difficulty: QuestionDifficulty
) => {
  return questionBatch.expertQuestions.filter(
    (question) =>
      question.testGenQuestion.difficulty === difficulty &&
      question.status === ExpertQuestionStatus.Approved
  ).length;
};

interface HeaderSummaryProps {
  variant?: "plain" | "negative" | "positive";
}
const HeaderSummary = styled.div<HeaderSummaryProps>`
  background: ${(props) =>
    props.variant === "positive"
      ? "#1DC488"
      : props.variant === "negative"
      ? "#EB5757"
      : "#f5f5f5"};
  color: ${(props) =>
    props.variant && "positive, negative".includes(props.variant)
      ? "#FFFFFF"
      : "#1A1A1A"};

  text-transform: uppercase;
  font-size: 10px;
  padding: 4px;
  border: 1px solid
    ${(props) =>
      props.variant === "positive"
        ? "#1DC488"
        : props.variant === "negative"
        ? "#EB5757"
        : "#f5f5f5"};
  border-radius: 6px;
`;
