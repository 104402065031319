import React from "react";
import {
  CreateQuestionFormQuery,
  TestGenSkill,
  TestType,
  useCreateQuestionFormQuery,
  useCreateQuestionMutation,
} from "../../utils/graphql-queries";
import { schema, Values, valuesToQuestion } from "./values";
import { emptyOptionsState } from "@togglhire/question-editor";
import { RichTextState } from "@togglhire/text-editor";
import { useFlashMessages } from "../../utils/flash-messages";
import { useRouter } from "next/router";
import { Formik, useFormikContext } from "formik";
import { Button, LinkButton } from "@hundred5/design-system";
import QuestionForm from "./question-form";
import { QuestionBatchHeader } from "../";
import { defaultTimeChoiceQuestion } from "../../utils/questions";
import { ViewMode } from "../../types/viewMode";

interface Props {
  batchId: string;
}

export default function CreateQuestionForm(props: Props) {
  const [{ data, fetching, error }] = useCreateQuestionFormQuery({
    variables: { batchId: props.batchId },
  });

  const skill = getSkill(data);
  const testType = getTestType(data);
  const saveQuestion = useSaveQuestion(props.batchId);

  if (fetching || error != null) {
    return null;
  }

  if (skill === undefined) {
    throw new Error("Skill is needed to create the question");
  }

  const initialValues = getInitialValues(skill, testType);

  return (
    <>
      <QuestionBatchHeader
        viewMode={ViewMode.Expert}
        batchId={props.batchId}
        backHref={`/batches/${props.batchId}`}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => saveQuestion(values)}
        validationSchema={schema}
      >
        <QuestionForm
          title={
            skill ? `Create a ${skill.name} question` : "Create a question"
          }
          testType={testType}
          footer={
            <>
              <CancelButton {...props} />
              <SaveButton />
            </>
          }
        />
      </Formik>
    </>
  );
}

const getInitialValues = (skill: TestGenSkill, testType: TestType): Values => ({
  description: RichTextState.create(""),
  notes: RichTextState.create(""),
  reply: RichTextState.create(""),
  difficulty: "standard",
  duration: defaultTimeChoiceQuestion,
  durationSetting: "default",
  type: "single-choice",
  options: emptyOptionsState(),
  skillId: skill.id,
  testType: testType,
});

const useSaveQuestion = (
  batchId: string
): ((values: Values) => Promise<void>) => {
  const [_, saveQuestion] = useCreateQuestionMutation();
  const flashMessages = useFlashMessages();
  const router = useRouter();

  return async (values: Values) => {
    const created = valuesToQuestion(values);
    const result = await saveQuestion({ batch: batchId, question: created });
    if (result.error) return;

    flashMessages.addMessage({ type: "question_saved" });
    await router.push(`/batches/${batchId}`);
  };
};

const getSkill = (
  data: CreateQuestionFormQuery | undefined
): TestGenSkill | undefined => {
  return data?.expertQuestionBatch?.expertChallenge.expertChallengeSkills[0]
    .skill;
};

const getTestType = (data: CreateQuestionFormQuery | undefined): TestType => {
  return data?.expertQuestionBatch?.expertChallenge.testType || TestType.Quiz;
};

const SaveButton = () => {
  const form = useFormikContext<Values>();

  return (
    <Button type="submit" variant="primary" disabled={form.isSubmitting}>
      {form.isSubmitting ? "Creating..." : "Create question"}
    </Button>
  );
};

const CancelButton = (props: Props) => {
  const router = useRouter();

  return (
    <LinkButton
      type="button"
      variant="secondary"
      onClick={() => {
        router.push(`/batches/${props.batchId}`);
      }}
    >
      Cancel
    </LinkButton>
  );
};
