import React from "react";
import {
  ExpertQuestionStatus,
  TestType,
  UpdateQuestionFormQuery,
  useAddExpertQuestionCommentMutation,
  useSubmitQuestionMutation,
  useUpdateQuestionFormQuery,
  useUpdateQuestionMutation,
} from "../../utils/graphql-queries";
import { difficultyToValue, schema, Values, valuesToQuestion } from "./values";
import { createOptionsState } from "@togglhire/question-editor";
import { QuestionOption } from "@togglhire/question-editor/dist/types";
import { RichTextState } from "@togglhire/text-editor";
import { useFlashMessages } from "../../utils/flash-messages";
import { Formik, useFormikContext } from "formik";
import { Button, LinkButton } from "@hundred5/design-system";
import QuestionForm from "./question-form";
import DeleteButton from "./delete-button";
import { useRouter } from "next/router";
import { QuestionBatchHeader, QuestionComments } from "..";
import { ViewMode } from "../../types/viewMode";

interface Props {
  viewMode?: ViewMode;
  questionId: string;
}

export type ExpertQuestionData = NonNullable<
  UpdateQuestionFormQuery["expertQuestion"]
>;

export default function UpdateQuestionForm({
  viewMode = ViewMode.Expert,
  questionId,
}: Props) {
  const [{ data, fetching }] = useUpdateQuestionFormQuery({
    variables: { questionId: questionId },
  });

  const question = data?.expertQuestion ?? null;
  const saveQuestion = useSaveQuestion(questionId, question, viewMode);
  const saveAndSubmitQuestion = useSaveAndSubmitQuestion(
    questionId,
    question,
    viewMode
  );

  const testType =
    data?.expertQuestion?.testGenQuestion.testType || TestType.Quiz;

  if (fetching || question == null) {
    return null;
  }

  const isReviewer =
    data?.expertQuestion?.expertQuestionBatch.expert.id !== data?.expert?.id &&
    viewMode !== ViewMode.Admin;
  const isReviewerOrAdmin = isReviewer || viewMode === ViewMode.Admin;

  const values = getInitialValues(question);
  const isQuestionDraftOrFeedback =
    question.status !== ExpertQuestionStatus.Draft &&
    question.status !== ExpertQuestionStatus.Feedback;
  const buttonsDisabled =
    viewMode === ViewMode.Expert && isQuestionDraftOrFeedback;

  return (
    <>
      <QuestionBatchHeader
        viewMode={viewMode}
        batchId={question.expertQuestionBatch.id}
        backHref={getReturnUrl(viewMode, question.expertQuestionBatch.id)}
      />
      <Formik
        initialValues={values}
        onSubmit={async (values) => {
          if (values.action === "submit") {
            await saveAndSubmitQuestion(values);
          } else if (values.action === "save") {
            await saveQuestion(values);
          }
        }}
        validationSchema={schema}
      >
        <QuestionForm
          title={`Edit a ${question.testGenQuestion.skill.name} question`}
          actions={
            <DeleteButton questionId={questionId} disabled={buttonsDisabled} />
          }
          testType={testType}
          comments={
            question.comments?.count && (
              <QuestionComments
                questionId={questionId}
                label="Reviewer feedback"
                viewMode={viewMode}
                isReviewer={isReviewer}
                enableEditing
              />
            )
          }
          footer={
            <>
              <CancelButton question={question} viewMode={viewMode} />
              {isReviewer ? (
                <SaveButton
                  draft={question.status === ExpertQuestionStatus.Draft}
                />
              ) : viewMode === ViewMode.Admin ? (
                <>
                  <SaveButton
                    draft={question.status === ExpertQuestionStatus.Draft}
                  />
                  <SubmitButton disabled={isQuestionDraftOrFeedback} />
                </>
              ) : (
                viewMode === ViewMode.Expert && (
                  <>
                    <SaveButton disabled={buttonsDisabled} draft />
                    <SubmitButton disabled={buttonsDisabled} />
                  </>
                )
              )}
            </>
          }
          isReviewer={isReviewerOrAdmin}
          oldTestGenQuestion={
            question.testGenQuestion.oldTestGenQuestion
              ? question.testGenQuestion.oldTestGenQuestion
              : undefined
          }
        />
      </Formik>
    </>
  );
}

const getInitialValues = (data: ExpertQuestionData): Values => {
  const question = data.testGenQuestion;

  return {
    description: RichTextState.create(question?.description ?? ""),
    notes: RichTextState.create(question?.notes ?? ""),
    reply: RichTextState.create(""),
    type: question?.questionType ?? "",
    difficulty: question ? difficultyToValue(question.difficulty) : "standard",
    duration: String(question?.durationInSeconds || 30),
    durationSetting:
      (question?.durationInSeconds ?? 0) > 0 ? "custom" : "default",
    options: createOptionsState(
      question?.options?.map(
        (option): QuestionOption => ({
          id: null,
          content: option.content,
          points: option.points,
          weight: option.orderWeight,
        })
      ) ?? []
    ),
    skillId: question.skill.id,
    testType: question.testType || TestType.Quiz,
  };
};

const useSaveQuestion = (
  id: string,
  data: ExpertQuestionData | null,
  viewMode: ViewMode
): ((values: Values) => Promise<void>) => {
  const [_, saveQuestion] = useUpdateQuestionMutation();
  const flashMessages = useFlashMessages();
  const router = useRouter();

  return async (values: Values) => {
    if (data == null) {
      throw new Error("Data is missing");
    }

    const updated = valuesToQuestion(values);
    const result = await saveQuestion({ id: id, question: updated });
    if (result.error) return;

    flashMessages.addMessage({ type: "question_saved" });
    router.push(getReturnUrl(viewMode, data.expertQuestionBatch.id));
  };
};

const useSaveAndSubmitQuestion = (
  id: string,
  data: ExpertQuestionData | null,
  viewMode: ViewMode
): ((values: Values) => Promise<void>) => {
  const [_, saveQuestion] = useUpdateQuestionMutation();
  const [__, submitQuestion] = useSubmitQuestionMutation();
  const [___, addComment] = useAddExpertQuestionCommentMutation();
  const flashMessages = useFlashMessages();
  const router = useRouter();

  return async (values: Values) => {
    if (data == null) {
      throw new Error("Data is missing");
    }

    const updated = valuesToQuestion(values);

    const saveResult = await saveQuestion({ id, question: updated });
    if (saveResult.error) return;

    if (values.reply.markdown()) {
      const addCommentResult = await addComment({
        id,
        message: values.reply.markdown(),
      });
      if (addCommentResult.error) return;
    }

    const submitResult = await submitQuestion({ id });
    if (submitResult.error) return;

    flashMessages.addMessage({ type: "question_submitted" });
    router.push(getReturnUrl(viewMode, data.expertQuestionBatch.id));
  };
};

const SaveButton = ({
  disabled,
  draft,
}: {
  disabled?: boolean;
  draft?: boolean;
}) => {
  const form = useFormikContext<Values>();

  return (
    <Button
      type="submit"
      variant="secondary"
      disabled={disabled || form.isSubmitting}
      data-tooltip={
        disabled ? "You are not allowed to modify submitted questions" : null
      }
      onClick={() => form.setFieldValue("action", "save")}
      style={{
        position: "relative",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      {form.isSubmitting ? "Saving..." : draft ? "Save draft" : "Save"}
    </Button>
  );
};

const SubmitButton = ({ disabled }: { disabled?: boolean }) => {
  const form = useFormikContext<Values>();

  return (
    <Button
      type="submit"
      variant="primary"
      disabled={disabled || form.isSubmitting}
      data-tooltip={
        disabled ? "You are not allowed to modify submitted questions" : null
      }
      onClick={() => form.setFieldValue("action", "submit")}
      style={{
        position: "relative",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      {form.isSubmitting ? "Submitting..." : "Submit question"}
    </Button>
  );
};

const CancelButton: React.FC<{
  question: ExpertQuestionData;
  viewMode: ViewMode;
}> = (props) => {
  const router = useRouter();

  return (
    <LinkButton
      type="button"
      variant="secondary"
      onClick={() => {
        const batchId = props.question.expertQuestionBatch.id;
        router.push(getReturnUrl(props.viewMode, batchId));
      }}
    >
      Cancel
    </LinkButton>
  );
};

const getReturnUrl = (viewMode: ViewMode, batchId: string) => {
  return viewMode === ViewMode.Admin
    ? `/admin/batches/${batchId}`
    : `/batches/${batchId}`;
};
