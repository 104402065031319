import { QuestionCommentTypes } from "./graphql-queries";

export const commentTypeLabels: Record<
  QuestionCommentTypes,
  { label: string; description: string }
> = {
  [QuestionCommentTypes.Formal]: {
    label: "Formal",
    description: "grammar, spelling, ...",
  },
  [QuestionCommentTypes.Topic]: {
    label: "Topic",
    description: "not relevant to the skill being tested",
  },
  [QuestionCommentTypes.WrongAnswers]: {
    label: "Incorrect answers",
    description: "problem with answers marked as correct",
  },
  [QuestionCommentTypes.ObviousAnswers]: {
    label: "Answers too obvious ",
    description: "too short / long, ...",
  },
  [QuestionCommentTypes.Time]: {
    label: "Time settings",
    description: "too short / long",
  },
  [QuestionCommentTypes.Difficulty]: {
    label: "Difficulty not right",
    description: "too easy / hard",
  },
  [QuestionCommentTypes.Other]: {
    label: "Other",
    description: "",
  },
};
