import config from "../config";

export const getAuthHeaders = (
  token: string | null
): Record<string, string> | undefined => {
  return config.sharedAuthEnabled
    ? undefined
    : { Authorization: `Bearer ${token}` };
};

export const getCredentialsConfig = (): Record<string, string> | undefined => {
  return config.sharedAuthEnabled
    ? {
        credentials: "include",
      }
    : undefined;
};
