import React from "react";
import styled from "@emotion/styled";
import {
  ExpertQuestionBatch,
  ExpertQuestionBatchStatus,
  useAdminReviewBatchMutation,
  useAdminQuestionBatchQuery,
  useRemoveReviewerMutation,
  useMarkExpertAsPaidMutation,
  useMarkReviewersAsPaidMutation,
  useSubmitQuestionBatchMutation,
  useDeleteBatchMutation,
} from "../../utils/graphql-queries";
import { Button, PlusWhiteIcon } from "@hundred5/design-system";
import { Divider } from "../common";

import {
  ConfirmModal,
  QuestionBatchHeader,
  QuestionBatchTable,
  QuestionReviewModal,
} from "..";
import AddReviewer from "../add-reviewer";
import ExpertTag from "../expert-tag/expert-tag";
import { useRouter } from "next/router";
import Link from "next/link";
import { LinkButton } from "@hundred5/design-system";
import { ViewMode } from "../../types/viewMode";
import { useFlashMessages } from "../../utils/flash-messages";

interface Props {
  id: string;
}

export default function ViewBatch({ id }: Props) {
  const router = useRouter();
  const flashMessages = useFlashMessages();
  const [reviewerModalState, setReviewerModalState] = React.useState(false);
  const [reviewModalState, setReviewModalState] = React.useState({
    id: "",
    open: false,
  });
  const [batchDeleteModalState, setBatchDeleteModalState] = React.useState<
    boolean
  >(false);
  const [questionBatchQuery, fetchQuestionBatch] = useAdminQuestionBatchQuery({
    variables: { id: id },
  });
  const [_, adminReviewQuestionBatch] = useAdminReviewBatchMutation();
  const [__, removeReviewer] = useRemoveReviewerMutation();
  const [___, deleteBatch] = useDeleteBatchMutation();
  const [____, submitQuestionBatch] = useSubmitQuestionBatchMutation();
  const [_____, markExpertAdPaid] = useMarkExpertAsPaidMutation();
  const [______, markReviewersAsPaid] = useMarkReviewersAsPaidMutation();

  const { data } = questionBatchQuery;
  const questionBatch = data?.expertQuestionBatch;
  if (!questionBatch) {
    return null;
  }

  const acceptDisabled = !questionBatch.expertQuestions.some(
    (question) => question.status === "approved"
  );

  const onDeleteReviewer = (reviewerID: string) => {
    removeReviewer(
      { batchID: id, expertID: reviewerID },
      { additionalTypenames: ["ExpertQuestionBatch"] }
    );
    if (questionBatch.status === "under_review") {
      adminReviewQuestionBatch({
        id: id,
        status: ExpertQuestionBatchStatus.Submitted,
      });
    }
    //TODO: Flash message
  };

  const onDeleteBatch = async () => {
    const result = await deleteBatch({ batchID: id });
    if (result.error) return;

    flashMessages.addMessage({ type: "question_batch_deleted" });

    router.push("/admin/challenges/list");
  };

  return (
    <>
      <QuestionBatchHeader
        viewMode={ViewMode.Admin}
        batchId={id}
        backHref={(router.query.returnURL || "/admin") as string}
      />
      <ContentContainer>
        <Buttons>
          {questionBatch.status === ExpertQuestionBatchStatus.InProgress && (
            <BatchButton
              size="small"
              variant="secondary"
              onClick={async () => {
                const result = await submitQuestionBatch({ id });

                if (result.error) return;
                flashMessages.addMessage({
                  type: "question_batch_submitted",
                  amountRequired: questionBatch.requiredQuestions,
                  skill:
                    questionBatch.expertChallenge.expertChallengeSkills[0].skill
                      .name,
                });
              }}
            >
              Submit for review
            </BatchButton>
          )}
          {questionBatch.status === ExpertQuestionBatchStatus.Submitted ||
          questionBatch.status === ExpertQuestionBatchStatus.Reviewed ||
          questionBatch.status === ExpertQuestionBatchStatus.UnderReview ? (
            <>
              <BatchButton
                size="small"
                onClick={() => {
                  setReviewerModalState(true);
                }}
                disabled={questionBatch.reviewers.nodes.length > 0}
              >
                <PlusWhiteIcon /> Add Reviewer
              </BatchButton>
              {questionBatch.reviewers.nodes.map((reviewer) => (
                <ExpertTag
                  key={reviewer.id}
                  name={reviewer.user.name}
                  onDelete={() => onDeleteReviewer(reviewer.id)}
                />
              ))}
              <RightAlignedButtons>
                <BatchButton
                  size="small"
                  onClick={() => {
                    adminReviewQuestionBatch({
                      id,
                      status: ExpertQuestionBatchStatus.Accepted,
                    });
                  }}
                  color="#1DC488"
                  disabled={acceptDisabled}
                  data-tooltip={
                    acceptDisabled
                      ? "At least one question must be accepted to accept a batch"
                      : undefined
                  }
                >
                  Accept Batch
                </BatchButton>
                <BatchButton
                  size="small"
                  onClick={() => {
                    adminReviewQuestionBatch({
                      id,
                      status: ExpertQuestionBatchStatus.Rejected,
                    });
                  }}
                  color="#EB5757"
                >
                  Reject Batch
                </BatchButton>
                <BatchButton
                  size="small"
                  onClick={() => {
                    setBatchDeleteModalState(true);
                  }}
                  color="#FFACA2"
                >
                  Delete Batch
                </BatchButton>
              </RightAlignedButtons>
            </>
          ) : (
            (questionBatch.status === ExpertQuestionBatchStatus.Accepted ||
              questionBatch.status === ExpertQuestionBatchStatus.Rejected) && (
              <>
                <ReviewersListLabel>{"Reviewers:"}</ReviewersListLabel>
                {questionBatch.reviewers.nodes.map((reviewer) => (
                  <Link
                    href={`/admin/experts/${reviewer.id}`}
                    key={reviewer.id}
                    passHref
                  >
                    <LinkButton>{reviewer.user.name}</LinkButton>
                  </Link>
                ))}
                {questionBatch.status ===
                  ExpertQuestionBatchStatus.Accepted && (
                  <RightAlignedButtons>
                    {!questionBatch.paidAt && (
                      <BatchButton
                        size="small"
                        onClick={() => {
                          markExpertAdPaid({
                            batchID: id,
                          });
                        }}
                        color="#1DC488"
                      >
                        Mark EXPERT as paid
                      </BatchButton>
                    )}

                    {!questionBatch.reviewersPaidAt && (
                      <BatchButton
                        size="small"
                        onClick={() => {
                          markReviewersAsPaid({
                            batchID: id,
                          });
                        }}
                        color="#1DC488"
                      >
                        Mark REVIEWER as paid
                      </BatchButton>
                    )}
                  </RightAlignedButtons>
                )}
              </>
            )
          )}
        </Buttons>
        <Divider />
        <QuestionBatchTable
          batch={questionBatch as ExpertQuestionBatch}
          getRowProps={(row) => ({
            onClick: () =>
              setReviewModalState({ id: row.original.id, open: true }),
          })}
        />
      </ContentContainer>
      {questionBatch?.comment && (
        <ContentContainer>
          <b>Comment</b>
          <Divider />
          {questionBatch.comment}
        </ContentContainer>
      )}
      <AddReviewer
        open={reviewerModalState}
        onClose={() => {
          setReviewerModalState(false);
          fetchQuestionBatch();
        }}
        batch={questionBatch as ExpertQuestionBatch}
      />
      <QuestionReviewModal
        viewMode={ViewMode.Admin}
        open={reviewModalState.open}
        id={reviewModalState.id}
        onClose={() => setReviewModalState({ id: "", open: false })}
      />
      <ConfirmModal
        open={batchDeleteModalState}
        action="Delete"
        onConfirm={onDeleteBatch}
        onClose={() => setBatchDeleteModalState(false)}
      >
        <h3>Are you sure?</h3>
        <p>Do you really want to delete this batch?</p>
      </ConfirmModal>
    </>
  );
}

const ContentContainer = styled.div`
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  margin: 30px 24px;
  padding: 20px 35px 35px 35px;
`;

const Buttons = styled.div`
  display: flex;
  button {
    margin-right: 15px;
    :last-child {
      margin-right: 0;
    }
  }
`;

const RightAlignedButtons = styled.div`
  margin-left: auto;
`;
interface BatchButtonProps {
  variant?: "secondary" | "primary";
  color?: string;
}
const BatchButton = styled(Button)<BatchButtonProps>`
  position: relative;
  height: 30px;
  background: ${(props) =>
    props.color || (props.variant === "secondary" ? "#FFFFFF" : "#FF897A")};

  :disabled {
    opacity: 100%;
    color: ${(props) => (props.variant === "secondary" ? "#bdbdbd" : "#fffff")};
    border-color: #bdbdbd;
    background-color: ${(props) =>
      props.variant === "secondary" ? "#fffff" : "#bdbdbd"};
  }

  &[data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    width: 160px;
    bottom: 50%;
    left: 90%;
    padding: 5px 10px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 10px;
    line-height: 150%;
    color: #2c1338;
    text-align: left;
    font-weight: normal;
    opacity: 0;
    pointer-events: none;
    transition: 200ms opacity;
  }

  &[data-tooltip]:hover::after {
    opacity: 1;
  }
`;

const ReviewersListLabel = styled.span`
  line-height: 1.2;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
`;
