import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, LinkButton, Modal } from "@hundred5/design-system";
import { Skill } from "../../types/skill";
import { SkillsDropdown } from "../index";

interface Props {
  open: boolean;
  onClose: () => void;
  onPick: (skill: Skill) => void;
}

export default function SkillPicker(props: Props) {
  const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);

  return (
    <Modal open={props.open} onClose={props.onClose} size={"small"}>
      <Header>Add a skill</Header>
      <Content>
        <label>Skill name:</label>
        <SkillsDropdown onSelect={setSelectedSkill} />
      </Content>
      <Footer>
        <LinkButton variant="secondary" onClick={props.onClose}>
          Cancel
        </LinkButton>
        <Button
          disabled={selectedSkill === null}
          onClick={() => selectedSkill && props.onPick(selectedSkill)}
        >
          Add skill
        </Button>
      </Footer>
    </Modal>
  );
}

const Header = styled.h1`
  padding: 0 26px;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  margin: 0;
`;

const Content = styled.div`
  padding: 24px 36px 52px;

  label {
    margin-bottom: 4px;
    font-weight: bold;
    display: block;
  }
`;

const Footer = styled.div`
  padding: 0 36px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${LinkButton} {
    margin-right: 15px;
  }
`;
