import React from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";

//region Component
export default function CreateChallengeButton() {
  const router = useRouter();

  return (
    <Container
      onClick={() =>
        router.push({
          pathname: "/admin/challenges/new",
          query: { returnURL: router.pathname },
        })
      }
    >
      Create new challenge
    </Container>
  );
}
//endregion

//region Styles
const Container = styled.button`
  width: 165px;
  height: 170px;
  padding: 22px;
  border: 1px dashed #989898;
  background: none;
  position: relative;
  top: 2px;
  font-size: 10px;
  font-weight: 500;
  color: #0084ca;
  text-transform: uppercase;
  line-height: 1.5;
  cursor: pointer;
`;
//endregion
