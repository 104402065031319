import React from "react";
import styled from "@emotion/styled";
import { Button, LinkButton, Modal } from "@hundred5/design-system";
import { ExpertsDropdown } from "../index";
import {
  useAssignReviewerMutation,
  ExpertQuestionBatch,
} from "../../utils/graphql-queries";

interface Props {
  open: boolean;
  onClose: () => void;
  batch: ExpertQuestionBatch;
}

export default function AddReviewer(props: Props) {
  const [selectedReviewer, setSelectedReviewer] = React.useState<string | null>(
    null
  );
  const [__, assignReviewer] = useAssignReviewerMutation();

  const onSubmit = () => {
    if (selectedReviewer) {
      assignReviewer(
        { batchID: props.batch.id, expertID: selectedReviewer },
        { additionalTypenames: ["ExpertQuestionBatch"] }
      );
      // TODO: Flash Message
    }
    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={props.onClose} size={"small"}>
      <Header>Add Reviewer</Header>
      <Content>
        <label>Reviewer:</label>
        <ExpertsDropdown
          skillIDs={
            props.batch?.expertChallenge.expertChallengeSkills.map(
              (skill) => skill.skill.id
            ) || []
          }
          onSelect={(reviewer) => {
            setSelectedReviewer(reviewer?.id || null);
          }}
          reviewers
        />
      </Content>
      <Footer>
        <LinkButton variant="secondary" onClick={props.onClose}>
          Cancel
        </LinkButton>
        <Button disabled={selectedReviewer === null} onClick={onSubmit}>
          Save changes
        </Button>
      </Footer>
    </Modal>
  );
}

const Header = styled.h1`
  padding: 0 26px;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  margin: 0;
`;

const Content = styled.div`
  padding: 24px 36px 52px;

  label {
    margin-bottom: 4px;
    font-weight: bold;
    display: block;
  }
`;

const Footer = styled.div`
  padding: 0 36px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${LinkButton} {
    margin-right: 15px;
  }
`;
