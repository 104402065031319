import { getAuthHeaders, getCredentialsConfig } from "./helpers";
import config from "../config";
import { NetworkError, parseApiError } from "./errors";

export async function updateEmail(
  authToken: string | null,
  values: {
    id: string;
    email: string;
  }
) {
  let response: Response;
  try {
    response = await fetch(
      `${config.apiUrl}/api/v1/support/db/users/${values.id}`,
      {
        method: "PATCH",
        ...getCredentialsConfig(),
        headers: {
          "content-type": "application/json",
          ...getAuthHeaders(authToken),
        },
        body: JSON.stringify({ email: values.email }),
      }
    );
  } catch (error) {
    throw new NetworkError("email");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}
