import React from "react";

export default function NavigationIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="17.5" stroke="#989898" />
      <path
        d="M17.1648 22.1619L18.1534 21.1733L15.8736 18.902H21.9545V17.4616H15.8736L18.1534 15.1818L17.1648 14.2017L13.1847 18.1818L17.1648 22.1619Z"
        fill="#989898"
      />
    </svg>
  );
}
