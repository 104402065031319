import React from "react";
import styled from "@emotion/styled";
import { ExpertChallengeForm } from "../index";
import { useRouter } from "next/router";
import { useExpertChallengeCreateMutation } from "../../utils/graphql-queries";
import { useFlashMessages } from "../../utils/flash-messages";

//region Component
export default function ExpertChallengeCreate() {
  const router = useRouter();
  const { addMessage } = useFlashMessages();
  const [_, createExpertChallenge] = useExpertChallengeCreateMutation();

  return (
    <Container>
      <Header>
        <h1>Create challenge</h1>
      </Header>

      <ExpertChallengeForm
        onSubmit={async (challenge) => {
          await createExpertChallenge({ input: challenge });
          addMessage({ type: "challenge_created" });
          router.push((router.query.returnURL || "/admin") as string);
        }}
        onCancel={() =>
          router.push((router.query.returnURL || "/admin") as string)
        }
      />
    </Container>
  );
}
//endregion

//region Styles
const Container = styled.article`
  width: 500px;
  background: #ffffff;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 36px;
  height: 80px;
  border-bottom: 1px solid #e7e7e7;
`;
//endregion
