import React from "react";
import styled from "@emotion/styled";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Button } from "@hundred5/design-system";
import { requestPasswordReset } from "../../api/authentication";
import { checkClientError } from "../../api/errors";
import { useApiError } from "../../hooks/api-error";
import { FormikTextField } from "../";
import { useGoogleReCaptchaToken } from "../../hooks/google-recaptcha";

export default function ForgottenPasswordForm() {
  const handleApiError = useApiError();
  const {
    googleRecaptchaToken,
    refreshGoogleRecaptchaToken,
  } = useGoogleReCaptchaToken("forgotten_password");

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, form) => {
        if (!googleRecaptchaToken) {
          return;
        }
        try {
          await requestPasswordReset(values.email, googleRecaptchaToken);
          form.setStatus("success");
        } catch (error) {
          if (checkClientError(error, "EmailNotFound")) {
            form.setFieldError(
              "email",
              "Sorry, you entered non-existing email."
            );
          } else {
            handleApiError(error);
          }
        } finally {
          refreshGoogleRecaptchaToken();
        }
      }}
    >
      {(props) =>
        props.status === "success" ? (
          <>
            <h1>Success</h1>
            <Message>
              An email with password reset instructions is on the way. <br />{" "}
              <br />
              If the email doesn't show up soon, check your spam folder. We send
              all our emails from{" "}
              <a href="mailto:hello@hire.toggl.com">hello@hire.toggl.com</a>
            </Message>
          </>
        ) : (
          <>
            <h1>Reset your password</h1>
            <Message>
              No sweat! Input your email below and we’ll send you instructions
              to reset it.
            </Message>
            <Form>
              <FormikTextField
                id="email"
                name="email"
                label="Email:"
                type="email"
                required
              />

              <SubmitButton
                type="submit"
                disabled={props.isSubmitting || !googleRecaptchaToken}
              >
                {props.isSubmitting ? "Sending..." : "Send instructions"}
              </SubmitButton>
            </Form>
          </>
        )
      }
    </Formik>
  );
}

const Message = styled.p`
  margin-bottom: 30px;
`;

const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
`;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email cannot be empty")
    .email("Enter a valid email"),
});
