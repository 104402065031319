export class NetworkError extends Error {
  constructor(endpoint: string) {
    super(`Network error: ${endpoint}`);
  }
}

export class ServerError extends Error {
  public type: string;

  constructor(type: string) {
    super(`Server error: ${type}`);
    this.type = type;
  }
}

export class ClientError extends Error {
  public type: string;

  constructor(type: string) {
    super(`Client error: ${type}`);
    this.type = type;
  }
}

export class AuthError extends Error {
  constructor() {
    super("Authentication error");
  }
}

export const parseApiError = async (
  response: Response
): Promise<ServerError | ClientError | AuthError> => {
  let type: string | null = null;

  try {
    const data = await response.json();
    type = data?.error?.type ?? null;
  } catch (error) {}

  if (response.status >= 500) {
    return new ServerError(type ?? "UnknownError");
  } else if (response.status === 401) {
    return new AuthError();
  } else {
    return new ClientError(type ?? "UnknownError");
  }
};

export const checkClientError = (
  error: any,
  type: string
): error is ClientError => {
  return error instanceof ClientError && error.type === type;
};
