import React from "react";
import { ClientError } from "../api/errors";

export interface NetworkErrorMessage {
  type: "network_error";
}

export interface ServerErrorMessage {
  type: "server_error";
}

export interface ClientErrorMessage {
  type: "client_error";
  error: ClientError;
}

export interface ForbiddenErrorMessage {
  type: "forbidden_error";
}

export interface NotFoundErrorMessage {
  type: "not_found_error";
}

export interface InvalidInputErrorMessage {
  type: "invalid_input_error";
}

export interface LoggedInMessage {
  type: "logged_in";
}

export interface VerificationEmailSentMessage {
  type: "verification_email_sent";
}

export interface EmailVerificationMessage {
  type: "email_verification";
  error?: "invalid_token" | "already_verified";
}

export interface ThirdPartyLoginErrorMessage {
  type: "third_party_login_error";
  error: string;
  meta?: { provider: string };
}

export interface PasswordChangedMessage {
  type: "password_changed";
}

export interface CloseAccountMessage {
  type: "close_account";
  success: boolean;
  error?: string;
}

export interface QuestionSavedMessage {
  type: "question_saved";
}

export interface QuestionSubmittedMessage {
  type: "question_submitted";
}

export interface QuestionDeletedMessage {
  type: "question_deleted";
}

export interface ImageUploadErrorMessage {
  type: "image_upload_error";
  error: "unsupported_file_format" | "file_too_large";
}

export interface EmailChangedMessage {
  type: "email_changed";
}

export interface ExpertSavedMessage {
  type: "expert_saved";
}

export interface ExpertCreatedMessage {
  type: "expert_created";
}

export interface ChallengeSavedMessage {
  type: "challenge_saved";
}

export interface ChallengeCreatedMessage {
  type: "challenge_created";
}

export interface ChallengeDeletedMessage {
  type: "challenge_deleted";
}

export interface ChallengeArchivedMessage {
  type: "challenge_archived";
}

export interface ChallengeActivatedMessage {
  type: "challenge_activated";
}

export interface QuestionAcceptedMessage {
  type: "question_accepted";
}

export interface QuestionRejectedMessage {
  type: "question_rejected";
}

export interface QuestionReviewRequestedMessage {
  type: "question_review_requested";
}

export interface QuestionBatchReviewedMessage {
  type: "question_batch_reviewed";
}

export interface QuestionBatchRejectedMessage {
  type: "question_batch_rejected";
}

export interface QuestionBatchSubmittedMessage {
  type: "question_batch_submitted";
  amountRequired?: number;
  skill?: string;
}

export interface QuestionBatchDeletedMessage {
  type: "question_batch_deleted";
}

export interface QuestionBatchReminderMessage {
  type: "question_batch_reminder";
}

export interface QuestionCommentUpdatedMessage {
  type: "question_comment_updated";
}

export interface ReviewBatchCreated {
  type: "review_batch_created";
}

export type Content =
  | NetworkErrorMessage
  | ServerErrorMessage
  | ClientErrorMessage
  | ForbiddenErrorMessage
  | NotFoundErrorMessage
  | InvalidInputErrorMessage
  | LoggedInMessage
  | VerificationEmailSentMessage
  | ThirdPartyLoginErrorMessage
  | EmailVerificationMessage
  | PasswordChangedMessage
  | CloseAccountMessage
  | QuestionSavedMessage
  | QuestionSubmittedMessage
  | QuestionDeletedMessage
  | ImageUploadErrorMessage
  | EmailChangedMessage
  | ExpertSavedMessage
  | ExpertCreatedMessage
  | ChallengeSavedMessage
  | ChallengeCreatedMessage
  | ChallengeDeletedMessage
  | ChallengeArchivedMessage
  | ChallengeActivatedMessage
  | QuestionAcceptedMessage
  | QuestionRejectedMessage
  | QuestionReviewRequestedMessage
  | QuestionBatchReviewedMessage
  | QuestionBatchRejectedMessage
  | QuestionBatchSubmittedMessage
  | QuestionBatchDeletedMessage
  | QuestionBatchReminderMessage
  | QuestionCommentUpdatedMessage
  | ReviewBatchCreated;

export interface FlashMessage {
  id: number;
  content: Content;
}

interface State {
  messages: FlashMessage[];
  nextId: number;
}

interface AddAction {
  type: "add";
  content: Content;
}

interface RemoveAction {
  type: "remove";
  id: number;
}

const reducer = (state: State, action: AddAction | RemoveAction): State => {
  switch (action.type) {
    case "add":
      return {
        ...state,
        messages: [
          ...state.messages,
          { id: state.nextId, content: action.content },
        ],
        nextId: state.nextId + 1,
      };
    case "remove":
      return {
        ...state,
        messages: state.messages.filter((message) => message.id !== action.id),
      };
    default:
      return state;
  }
};

const initialState: State = {
  messages: [],
  nextId: 1,
};

interface ContextValue {
  messages: FlashMessage[];
  addMessage(content: Content): void;
  removeMessage(id: number): void;
}

const FlashMessageContext = React.createContext<ContextValue | null>(null);

export const FlashMessageProvider: React.FC = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const addMessage = React.useCallback(
    (content: Content) => dispatch({ type: "add", content }),
    [dispatch]
  );

  const removeMessage = React.useCallback(
    (id: number) => dispatch({ type: "remove", id }),
    [dispatch]
  );

  return (
    <FlashMessageContext.Provider
      value={{
        messages: state.messages,
        addMessage,
        removeMessage,
      }}
    >
      {props.children}
    </FlashMessageContext.Provider>
  );
};

export const useFlashMessages = (): ContextValue => {
  const value = React.useContext(FlashMessageContext);

  if (value == null) {
    throw new Error("Flash message provider is missing");
  }

  return value;
};
