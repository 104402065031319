import * as React from "react";
import styled from "@emotion/styled";

interface Props {
  fullScreen?: boolean;
}

const Loader: React.FC<Props> = (props) => (
  <Container {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <title>Loading</title>
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke="#ff897a"
        strokeWidth={10}
        r={35}
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  </Container>
);

const Container = styled.div<Props>`
  width: 100%;
  height: ${(props) => (props.fullScreen ? "100vh" : "100%")};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
  }
`;

export default Loader;
