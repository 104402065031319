import styled from "@emotion/styled";
import React from "react";
import ThumbDownIcon from "../../icons/ThumbDownIcon";
import { commentTypeLabels } from "../../utils/comment-type-labels";
import { OldTestGenQuestionData } from "./question-form";
import { TestGenQuestionData } from "./update-test-gen-question-form";

export default function Feedback({
  testGenQuestion,
}: {
  testGenQuestion?: TestGenQuestionData | OldTestGenQuestionData;
}) {
  return (
    <Container>
      <Label>Reason for review</Label>
      <Reasons>
        {testGenQuestion?.negativeRating && (
          <Rating>
            {testGenQuestion?.negativeRating} <ThumbDownIcon />
          </Rating>
        )}
        {testGenQuestion?.averageScore && (
          <div>{`${testGenQuestion.averageScore}%`}</div>
        )}
        {testGenQuestion?.displayed && (
          <div>{`Displayed ${testGenQuestion.displayed}x`}</div>
        )}
      </Reasons>
      {testGenQuestion?.feedback && testGenQuestion.feedback.length > 0 && (
        <>
          <Label>Candidates feedback</Label>
          {testGenQuestion.feedback.map((feedback) => (
            <FeedbackContainer key={feedback.message}>
              <Issues>
                {feedback.types
                  ?.map((issue) => commentTypeLabels[issue].label)
                  .join(", ")}
              </Issues>
              {feedback.message}
            </FeedbackContainer>
          ))}
        </>
      )}
    </Container>
  );
}
const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const Container = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
`;

const Reasons = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-bottom: 10px;
`;

const Issues = styled.div`
  font-style: italic;
  color: #eb5757;
  font-size: 12px;
`;

const FeedbackContainer = styled.div`
  margin-bottom: 10px;
`;
