import styled from "@emotion/styled";
import { ExpertQuestionBatch } from "../../utils/graphql-queries";
import { useRouter } from "next/router";
import { getDeadlineInDays } from "../../utils/question-batch";
import { testTypes } from "../../utils/test-types";

interface Props {
  batch: ExpertQuestionBatch;
}

export default function AdminListBatch({ batch }: Props) {
  const router = useRouter();
  return (
    <Container>
      <ExpertName>{batch.expert.user.name || batch.expert.fullName}</ExpertName>
      <Name>{batch.expertChallenge.name}</Name>
      <StatsContainer>
        <Stats>
          <div>
            Questions:{" "}
            <strong>{batch.expertChallenge.requiredQuestions}</strong>
          </div>
          <div>
            Deadline: <strong>{getDeadlineInDays(batch)} days</strong>
          </div>
          <div>
            Test type:{" "}
            <strong>
              {batch.expertChallenge.testType
                ? testTypes[batch.expertChallenge.testType]
                : testTypes.quiz}
            </strong>
          </div>
          <PaddedStat>
            {batch.status === "in_progress" ? (
              <>
                {"Progress: "} <strong>{getProgress(batch)}</strong>
              </>
            ) : batch.status === "under_review" &&
              batch.reviewers.nodes.length > 0 ? (
              <>
                Reviewer:{" "}
                <ReviewerName>
                  {batch.reviewers.nodes[0].user.name ||
                    batch.reviewers.nodes[0].fullName}
                </ReviewerName>
              </>
            ) : undefined}
            <ActionButton
              hide={
                (batch.status === "under_review" &&
                  batch.reviewers.nodes.length > 0) ||
                batch.status === "in_progress"
              }
              onClick={() =>
                router.push({
                  pathname: `/admin/batches/${batch.id}`,
                  query: { returnURL: router.pathname },
                })
              }
            >
              {getButtonLabel(batch)}
            </ActionButton>
          </PaddedStat>
        </Stats>
      </StatsContainer>
    </Container>
  );
}

const getProgress = (questionBatch: ExpertQuestionBatch): string => {
  const submitted = questionBatch.expertQuestions.length;
  const approved = questionBatch.expertQuestions.filter(
    (question) => question.status === "approved"
  ).length;

  return `${submitted} / ${approved}`;
};

const getButtonLabel = (questionBatch: ExpertQuestionBatch): string => {
  if (
    questionBatch.status === "submitted" &&
    questionBatch.reviewers.nodes.length > 0
  ) {
    return "Not Accepted";
  } else if (
    (questionBatch.status === "submitted" ||
      questionBatch.status === "under_review") &&
    questionBatch.reviewers.nodes.length === 0
  ) {
    return "Assign Reviewer";
  }
  if (questionBatch.status === "reviewed") {
    return "Accept Batch";
  }
  return "View Batch";
};

const ActionButton = styled.button<{ hide?: boolean }>`
  font-size: 10px;
  font-weight: bold;
  height: 25px;
  background: #2c1338;
  border-radius: 3px;
  border: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: all 0.2s ease-in-out;
  position: absolute;
  bottom: ${(props) => (props.hide ? "-8px" : "-24px;")};

  :active {
    transform: scale(0.96);
  }
`;

const ExpertName = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: 10px;
  color: #0084ca;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 18px;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ReviewerName = styled.span`
  font-weight: 500;
  font-size: 10px;
  color: #0084ca;
  text-transform: uppercase;
  width: 50px;
  height: 11px;
  overflow: hidden;
  display: inline-block;
`;

const Name = styled.h2`
  height: 50px;
  margin-top: 26px;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Stats = styled.div`
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  text-transform: uppercase;
`;

const PaddedStat = styled.div`
  padding-top: 8px;
`;

const Container = styled.div`
  position: relative;
  width: 165px;
  height: 170px;
  padding: 0 18px;
  text-align: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  transition: background-color 0.2s ease-in-out;
  background: #fefaee;

  ${ExpertName} {
    color: #0084ca;
  }

  &:hover {
    background: #fcefca;

    ${PaddedStat} ${ActionButton} {
      opacity: 1;
    }

    ${PaddedStat} span {
      opacity: 0;
    }
  }
`;

const StatsContainer = styled.div`
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
`;
