import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import {
  Button,
  CloseModalButton,
  LinkButton,
  Modal,
} from "@hundred5/design-system";
import {
  ExpertQuestionBatchStatusInput,
  useQuestionReviewModalQuery,
  useReviewExpertQuestionBatchMutation,
} from "../../utils/graphql-queries";
import { ViewMode } from "../../types/viewMode";
import { FormikMarkdownField } from "..";
import { Form, Formik } from "formik";
import { RichTextState } from "@togglhire/text-editor";
import { Content, useFlashMessages } from "../../utils/flash-messages";

//region Component
interface Props {
  viewMode?: ViewMode;
  open: boolean;
  id: string;
  onClose: () => void;
  actionType: "submit" | "reject" | "";
}

export default function ReviewComment({
  viewMode,
  open,
  id,
  onClose,
  actionType,
}: Props) {
  const router = useRouter();
  const [_, reviewBatch] = useReviewExpertQuestionBatchMutation();
  const { addMessage } = useFlashMessages();

  let reviewStatus = ExpertQuestionBatchStatusInput.Submitted;
  let message: Content = { type: "question_batch_reviewed" };
  if (actionType === "reject") {
    reviewStatus = ExpertQuestionBatchStatusInput.Rejected;
    message = { type: "question_batch_rejected" };
  }

  if (actionType === "") {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose} size="large">
      <Container>
        <Header>
          {actionType === "submit" && "Submit batch"}
          {actionType === "reject" && "Reject batch"}
          <CloseButton onClick={onClose} />
        </Header>
        <Formik
          initialValues={{
            message: RichTextState.create(""),
          }}
          onSubmit={async (values) => {
            const result = await reviewBatch({
              id,
              status: reviewStatus,
              comment: values.message.markdown(),
            });

            if (result.error) return;

            addMessage(message);
            router.push("/");
          }}
        >
          <Form>
            <FormikMarkdownField
              id="review-comment"
              name="message"
              label="Comment:"
              noteLabel="Private feedback for Toggl Hire Admin"
            />

            <Buttons>
              <LinkButton variant="secondary" type="button" onClick={onClose}>
                Back
              </LinkButton>
              <BatchButton type="submit" subVariant={actionType}>
                {actionType === "submit" && "Submit batch"}
                {actionType === "reject" && "Reject batch"}
              </BatchButton>
            </Buttons>
          </Form>
        </Formik>
      </Container>
    </Modal>
  );
}
//endregion

//region Styles

const Container = styled.div`
  padding: 24px;
  background: #ffffff;
`;

const Header = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
`;

interface BatchButtonProps {
  subVariant?: "submit" | "reject" | "";
}
const BatchButton = styled(Button)<BatchButtonProps>`
  position: relative;
  height: 30px;
  background: ${(props) =>
    props.subVariant === "submit"
      ? "#1DC488"
      : props.subVariant === "reject"
      ? "#EB5757"
      : null};

  :disabled {
    opacity: 100%;
    color: #fffff;
    border-color: #bdbdbd;
    background-color: #bdbdbd;
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 0 20px 15px;

  @media (min-width: 768px) {
    padding: 0 40px 15px;
  }
`;

const Tag = styled.div`
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #f5f5f5;
  text-transform: uppercase;
  margin-right: 10px;
  padding: 0 6px;
  font-size: 10px;
  font-weight: 500;
`;

const CommentsContainer = styled.div`
  & > * {
    border-top: 1px solid #e7e7e7;
    padding: 25px 20px 0;
    margin-bottom: 25px;

    @media (min-width: 768px) {
      padding: 25px 40px 0;
    }
  }
`;

const EditButton = styled(Button)`
  height: 30px;
  margin-left: 15px;
`;

const CloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
//endregion
