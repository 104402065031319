import React from "react";
import { useIntercom } from "react-use-intercom";

interface Props {
  userId: string;
  email: string;
  name: string;
  userHash?: string;
}

const Booter: React.FC<Props> = (props) => {
  const { userId, email, name, userHash } = props;
  const intercom = useIntercom();

  React.useEffect(() => {
    intercom.boot({
      userId,
      email,
      name,
      userHash,
    });

    return () => {
      intercom.shutdown();
    };
  }, [intercom, userId, email, name, userHash]);

  return null;
};

export default Booter;
