import React from "react";
import { Field, Select } from "@hundred5/design-system";
import { useField } from "formik";
import styled from "@emotion/styled";

interface Props {
  id: string;
  name: string;
  label: string;
  required?: boolean;
  children: React.ReactNode;
}

export default function FormikSelectField(props: Props) {
  const [field, meta] = useField(props.name);

  return (
    <StyledField
      for={props.id}
      label={props.label}
      required={props.required}
      error={meta.touched ? meta.error : undefined}
      input={
        <Select
          {...field}
          id={props.id}
          error={meta.touched && meta.error != null}
        >
          {props.children}
        </Select>
      }
    />
  );
}

const StyledField = styled(Field)`
  margin-bottom: 20px;
`;
