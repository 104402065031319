import { deleteMe, fetchMe, updateMe } from "./me";
import { sendVerificationEmail, updatePassword } from "./authentication";
import { uploadImage } from "./images";
import { updateEmail } from "./admin";
import { logout } from "./togglAccounts";

type TokenizedFunction<Fn> = Fn extends (
  token: string,
  ...args: infer Args
) => infer Ret
  ? (...args: Args) => Ret
  : never;

export interface ApiClient {
  fetchMe: TokenizedFunction<typeof fetchMe>;
  updateMe: TokenizedFunction<typeof updateMe>;
  deleteMe: TokenizedFunction<typeof deleteMe>;
  sendVerificationEmail: TokenizedFunction<typeof sendVerificationEmail>;
  updatePassword: TokenizedFunction<typeof updatePassword>;
  uploadImage: TokenizedFunction<typeof uploadImage>;
  updateEmail: TokenizedFunction<typeof updateEmail>;
  logout: typeof logout;
}

export function createClient(token: string | null): ApiClient {
  return {
    fetchMe: fetchMe.bind(null, token),
    updateMe: updateMe.bind(null, token),
    deleteMe: deleteMe.bind(null, token),
    sendVerificationEmail: sendVerificationEmail.bind(null, token),
    updatePassword: updatePassword.bind(null, token),
    uploadImage: uploadImage.bind(null, token),
    updateEmail: updateEmail.bind(null, token),
    logout: logout,
  };
}
