import { useEffect, useContext, useState, useCallback } from "react";
import { GoogleReCaptchaContext } from "../components/google-recaptcha-provider";
import config from "../config";

export const useGoogleReCaptchaToken = (action: string) => {
  const { executeRecaptcha } = useContext(GoogleReCaptchaContext);
  const [token, setToken] = useState<string | null>(null);

  const refreshGoogleRecaptchaToken = useCallback(() => {
    if (!executeRecaptcha) {
      return;
    }
    if (config.nodeEnv === "production") {
      executeRecaptcha(action).then((token) => {
        setToken(token);
      });
    } else {
      setToken("dev-token");
    }
  }, [executeRecaptcha, action]);

  useEffect(() => {
    refreshGoogleRecaptchaToken();
  }, [refreshGoogleRecaptchaToken]);
  return { googleRecaptchaToken: token, refreshGoogleRecaptchaToken };
};
