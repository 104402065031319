import styled from "@emotion/styled";
import { Button, LinkButton, Modal } from "@hundred5/design-system";
import { MarkdownState, RichTextState } from "@togglhire/text-editor";
import { Form, Formik } from "formik";
import { map, keys, pickBy } from "lodash";
import React from "react";
import { commentTypeLabels } from "../../utils/comment-type-labels";
import { useFlashMessages } from "../../utils/flash-messages";
import {
  ExpertQuestionComment,
  QuestionCommentTypes,
  useUpdateExpertQuestionCommentMutation,
} from "../../utils/graphql-queries";
import { FormikCheckboxField, FormikMarkdownField } from "../index";
import * as yup from "yup";

interface Props {
  open: boolean;
  onClose: () => void;
  comment: ExpertQuestionComment | null;
}

interface Values {
  message: RichTextState | MarkdownState;
  formal: boolean;
  topic: boolean;
  wrong_answers: boolean;
  obvious_answers: boolean;
  time: boolean;
  difficulty: boolean;
  other: boolean;
}

export default function EditCommentModal(props: Props) {
  const [_, updateComment] = useUpdateExpertQuestionCommentMutation();
  const { addMessage } = useFlashMessages();

  const handleSubmit = async (values: Values) => {
    if (!props.comment) return;

    const result = await updateComment({
      id: props.comment.id,
      message: values.message.markdown(),
      types: keys(
        pickBy(values, (value) => value === true)
      ) as QuestionCommentTypes[],
    });
    if (result.error) return;

    props.onClose();
    addMessage({ type: "question_comment_updated" });
  };

  return (
    <Modal open={props.open} onClose={props.onClose} size={"large"}>
      <Header>Edit comment</Header>
      <Content>
        {props.comment && (
          <Formik
            initialValues={{
              formal: props.comment.types.includes(QuestionCommentTypes.Formal),
              topic: props.comment.types.includes(QuestionCommentTypes.Topic),
              wrong_answers: props.comment.types.includes(
                QuestionCommentTypes.WrongAnswers
              ),
              obvious_answers: props.comment.types.includes(
                QuestionCommentTypes.ObviousAnswers
              ),
              time: props.comment.types.includes(QuestionCommentTypes.Time),
              difficulty: props.comment.types.includes(
                QuestionCommentTypes.Difficulty
              ),
              other: props.comment.types.includes(QuestionCommentTypes.Other),
              message: RichTextState.create(
                props.comment.message ? props.comment.message : ""
              ),
            }}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={validationSchema}
          >
            <Form>
              <Issues>
                <Label>Issues</Label>
                {map(commentTypeLabels, (value, key) => (
                  <FormikCheckboxField
                    id={key}
                    name={key}
                    key={key}
                    label={
                      <>
                        {value.label}&nbsp;
                        {value.description ? (
                          <IssueDescription>
                            ({value.description})
                          </IssueDescription>
                        ) : null}
                      </>
                    }
                  />
                ))}
              </Issues>
              <MarkdownField
                id="message"
                name="message"
                label="Comment"
                required
              />
              <Footer>
                <LinkButton variant="secondary" onClick={props.onClose}>
                  Cancel
                </LinkButton>
                <Button type="submit">Save changes</Button>
              </Footer>
            </Form>
          </Formik>
        )}
      </Content>
    </Modal>
  );
}

const validationSchema = yup.object().shape({
  message: yup
    .object<RichTextState | MarkdownState>()
    .test(
      "content",
      "Comment can not be empty!",
      (value) => (value?.markdown().trim() ?? "") !== ""
    ),
});

const Header = styled.h1`
  padding: 0 26px;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  margin: 0;
`;

const Content = styled.div`
  padding: 24px 36px 44px 36px;
`;

const MarkdownField = styled(FormikMarkdownField)`
  label {
    margin-bottom: 4px;
    font-weight: bold;
    display: block;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${LinkButton} {
    margin-right: 15px;
  }
`;

const Issues = styled.div`
  & > * {
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
`;

const IssueDescription = styled.span`
  font-size: 12px;
`;

const Label = styled.span`
  margin-bottom: 4px;
  font-weight: bold;
  display: block;
`;
