import React from "react";

export default function WarningIcon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57156 11.5685C8.57156 11.72 8.45435 11.8462 8.30367 11.8462H6.69633C6.54565 11.8462 6.42844 11.72 6.42844 11.5685V9.96995C6.42844 9.81851 6.54565 9.69231 6.69633 9.69231H8.30367C8.45435 9.69231 8.57156 9.81851 8.57156 9.96995V11.5685ZM8.55481 8.42188C8.54644 8.53125 8.42087 8.61539 8.27018 8.61539H6.72145C6.56239 8.61539 6.43682 8.53125 6.43682 8.42188L6.2945 4.57692C6.2945 4.52644 6.31961 4.44231 6.37821 4.40024C6.42844 4.35817 6.50379 4.30769 6.57913 4.30769H8.42087C8.49621 4.30769 8.57156 4.35817 8.62179 4.40024C8.68039 4.44231 8.7055 4.50962 8.7055 4.5601L8.55481 8.42188ZM8.43761 0.563703C8.25344 0.218751 7.89346 0 7.5 0C7.10654 0 6.74656 0.218751 6.56239 0.563703L0.133055 12.4099C-0.0511187 12.738 -0.0427472 13.1418 0.149798 13.47C0.342343 13.7981 0.693947 14 1.07067 14H13.9293C14.3061 14 14.6577 13.7981 14.8502 13.47C15.0427 13.1418 15.0511 12.738 14.8669 12.4099L8.43761 0.563703Z"
        fill="#FFDE91"
      />
    </svg>
  );
}
