import React from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import {
  ExpertChallenge,
  ExpertChallengeState,
} from "../../utils/graphql-queries";
import { Modal } from "@hundred5/design-system";
import QuestionBatchForm from "../question-batch-form";
import uniq from "lodash/uniq";
import { HiddenIcon } from "../../icons";
import { ViewMode } from "../../types/viewMode";
import { testTypes } from "../../utils/test-types";

//region Component
interface Props {
  expertChallenge?: ExpertChallenge;
  isEmpty?: boolean;
  viewMode?: ViewMode;
  disabled?: boolean;
}

const EMPTY_CHALLENGE: ExpertChallenge = {
  id: "0",
  name: "",
  requiredQuestions: 10,
  deadline: 10,
  state: ExpertChallengeState.Draft,
  expertChallengeSkills: [],
  experts: [],
  expertQuestionBatches: [],
};

export default function ExpertChallengeComponent({
  expertChallenge = EMPTY_CHALLENGE,
  isEmpty,
  viewMode = ViewMode.Expert,
  disabled = false,
}: Props) {
  const router = useRouter();
  const [modalOpen, setModalOpen] = React.useState(false);

  const numExperts = React.useMemo(
    () => viewMode === ViewMode.Admin && getNumExperts(expertChallenge),
    [expertChallenge, viewMode]
  );
  return (
    <Container
      disabled={
        expertChallenge.state !== ExpertChallengeState.Active || disabled
      }
      data-tooltip={getChallengeTooltip(
        expertChallenge,
        expertChallenge?.isFirst || false,
        isEmpty,
        viewMode
      )}
    >
      {viewMode === ViewMode.Expert && expertChallenge?.isFirst && (
        <Badge>First Challenge</Badge>
      )}

      <BatchBadge>
        {expertChallenge.expertQuestionBatches?.length || 0}
      </BatchBadge>
      <HiddenBadge>
        {viewMode === ViewMode.Admin &&
          expertChallenge.visibility === "private" && <HiddenIcon />}
      </HiddenBadge>
      {viewMode === ViewMode.Admin && expertChallenge.isFirst && (
        <ExpertName>
          {expertChallenge?.experts?.[0].fullName ||
            expertChallenge?.experts?.[0].user.name}
        </ExpertName>
      )}
      <NameContainer>
        <Name title={expertChallenge.name}>
          {isEmpty ? "First [new skill] challenge" : expertChallenge.name}
        </Name>
      </NameContainer>
      <StatsContainer>
        <Stats>
          <div>
            Questions: <strong>{expertChallenge.requiredQuestions}</strong>
          </div>
          <div>
            Deadline: <strong>{expertChallenge.deadline} days</strong>
          </div>
          <div>
            Test type:{" "}
            <strong>
              {expertChallenge.testType
                ? testTypes[expertChallenge.testType!!]
                : testTypes.quiz}
            </strong>
          </div>
          {viewMode === ViewMode.Admin &&
          expertChallenge.state === "active" &&
          expertChallenge.expertQuestionBatches ? (
            <ExpertContainer>
              Experts:{" "}
              {numExperts > 0 ? (
                <strong>{numExperts}</strong>
              ) : (
                <ZeroBadge>0</ZeroBadge>
              )}
            </ExpertContainer>
          ) : null}
        </Stats>

        {viewMode === ViewMode.Admin ? (
          <ActionButton
            onClick={() =>
              router.push({
                pathname: `/admin/challenges/${expertChallenge.id}`,
                query: { returnURL: router.pathname },
              })
            }
          >
            View challenge
          </ActionButton>
        ) : expertChallenge.state === ExpertChallengeState.Active &&
          !disabled ? (
          <ActionButton onClick={() => setModalOpen(true)}>
            Accept challenge
          </ActionButton>
        ) : null}
      </StatsContainer>
      <Modal open={modalOpen} size="large" onClose={() => setModalOpen(false)}>
        <QuestionBatchForm
          challenge={expertChallenge}
          close={() => setModalOpen(false)}
        />
      </Modal>
    </Container>
  );
}

const getChallengeTooltip = (
  expertChallenge: ExpertChallenge,
  isFirst?: boolean,
  isEmpty?: boolean,
  viewMode?: ViewMode
): string | null => {
  const skills = expertChallenge.expertChallengeSkills
    .map((skill) => skill.skill.name)
    .join(", ");

  if (viewMode === ViewMode.Admin) {
    return null;
  } else if (isFirst) {
    return `Complete this challenge to get certified in ${skills}`;
  } else if (isEmpty) {
    return "Add a skill first to unlock this challenge";
  } else {
    return null;
  }
};

const getNumExperts = (expertChallenge: ExpertChallenge): number => {
  return uniq(
    expertChallenge.expertQuestionBatches.map((batch) => batch.expert.id)
  ).length;
};
//endregion

//region Styles
const ActionButton = styled.button`
  font-size: 10px;
  font-weight: bold;
  height: 25px;
  background: #2c1338;
  border-radius: 3px;
  border: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.2s ease-in-out;

  :active {
    transform: scale(0.96);
  }
`;

const Stats = styled.div`
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  text-transform: uppercase;
`;

const NameContainer = styled.div`
  display: flex;
  margin-top: 26px;
  margin-bottom: 2px;
  svg {
    align-self: flex-start;
  }
`;

const Name = styled.h2`
  height: 42px;
  margin: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  transition: height 0.2s ease-in-out;
  font-size: 14px;
`;

const ExpertName = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: 10px;
  color: #0084ca;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 18px;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 165px;
  height: 170px;
  padding: 0 18px;
  text-align: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  transition: background-color 0.2s ease-in-out;
  background: ${(props) =>
    props.disabled ? "#E5E5E5" : "rgba(44, 19, 56, 0.2)"};

  ${ExpertName} {
    color: ${(props) => (props.disabled ? "#828282" : "#0084ca")};
  }

  &:hover {
    background: rgba(44, 19, 56, 0.4);

    ${ActionButton} {
      opacity: 1;
    }

    ${Stats} {
      opacity: 0;
    }

    ${Name} {
      height: 84px;
    }
  }

  &[disabled] {
    color: #828282;

    ${ActionButton} {
      background: #ffffff;
      color: #989898;
    }

    &:hover {
      background: #989898;

      ${Name}, ${ExpertName} {
        color: #ffffff;
      }
    }
  }
`;

const Badge = styled.div`
  background: #ffde91;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 4px 7px;
`;

const BatchBadge = styled.div`
  background: #eb5757;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 4px 7px;
`;

const HiddenBadge = styled.div`
  position: absolute;
  top: 6px;
  right 6px;
  z-index: 1;

`;

const ZeroBadge = styled.div`
  display: inline-block;
  background: #eb5757;
  color: #ffffff;
  border-radius: 100px;
  width: 19px;
  height: 15px;
`;

const StatsContainer = styled.div`
  position: absolute;
  bottom: 18px;
  text-transform: uppercase;
  font-size: 10px;
`;

const ExpertContainer = styled.div`
  padding-top: 8px;
`;
//endregion
