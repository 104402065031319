import React from "react";
import { useField } from "formik";
import { Radio, InlineField } from "@hundred5/design-system";
import styled from "@emotion/styled";

type Props = {
  id: string;
  name: string;
  label: React.ReactNode;
  value: string;
  required?: boolean;
  hideErrorMessage?: boolean;
};

const FormikCheckboxField = (props: Props) => {
  const [field, meta] = useField<any>({
    name: props.name,
    type: "radio",
    value: props.value,
  });

  return (
    <StyledField
      for={props.id}
      required={props.required}
      label={props.label}
      error={meta.touched ? meta.error : undefined}
      input={<Radio type="radio" id={props.id} {...field} />}
    />
  );
};

const StyledField = styled(InlineField)`
  [class*="Error"] {
    margin-top: 10px;
  }
`;

export default FormikCheckboxField;
