import {
  MutationConfig,
  MutationFunction,
  MutationResultPair,
  QueryConfig,
  QueryKey,
  QueryResult,
  TypedQueryFunction,
  TypedQueryFunctionArgs,
} from "react-query";
import { useMutation, useQuery } from "react-query";
import { useApiError } from "./api-error";
import { AuthError } from "../api/errors";

export function useApiQuery<
  TResult,
  TError,
  TArgs extends TypedQueryFunctionArgs
>(
  key: QueryKey,
  fn: TypedQueryFunction<TResult, TArgs>,
  config?: QueryConfig<TResult, TError>
): QueryResult<TResult, TError> {
  const handleApiError = useApiError();

  return useQuery(key, fn, {
    retry: shouldRetry,
    onError: handleApiError,
    ...config,
  });
}

export function useApiMutation<
  TResult,
  TError = unknown,
  TVariables = undefined,
  TSnapshot = unknown
>(
  fn: MutationFunction<TResult, TVariables>,
  config?: MutationConfig<TResult, TError, TVariables, TSnapshot>
): MutationResultPair<TResult, TError, TVariables, TSnapshot> {
  const handleApiError = useApiError();
  return useMutation(fn, { onError: handleApiError, ...config });
}

const FAILURE_RETRIES = 3;

function shouldRetry(failureCount: number, error: any) {
  if (error instanceof AuthError) {
    return false;
  } else {
    return failureCount < FAILURE_RETRIES;
  }
}
