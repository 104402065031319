import React, { useEffect, useState, useRef } from "react";
import { useSkillsDropdownQuery } from "../../utils/graphql-queries";
import { Skill } from "../../types/skill";
import { find } from "lodash";
import { DownshiftSearch } from "@togglhire/downshift-search";

interface Props {
  onSelect: (skill: Skill | null) => void;
  initialSkillId?: string;
  error?: boolean;
  isStateless?: boolean;
  placeholder?: string;
}

export default function SkillsDropdown(props: Props) {
  const query = useRef<ReturnType<typeof useSkillsDropdownQuery>>();

  query.current = useSkillsDropdownQuery({
    pause: query.current?.[0].data !== undefined, //Execute only once
  });

  const [{ data }] = query.current;
  const skills = data?.skills;
  const [skillName, setSkillName] = useState("");
  const [selectedSkillId, setSelectedSkillId] = useState<string | null>(null);

  useEffect(() => {
    if (props.initialSkillId && skills) {
      const skill = find(skills, { id: props.initialSkillId }) as Skill;
      setSkillName(skill.name);
      setSelectedSkillId(skill.id);
    }
  }, [props.initialSkillId, skills]);

  return (
    <DownshiftSearch
      items={data?.skills || []}
      inputValue={skillName}
      onSelect={(id) => {
        if (id) {
          const skill = find(skills, { id }) as Skill;

          if (props.isStateless) {
            setSkillName("");
            setSelectedSkillId(null);
          } else {
            setSkillName(skill.name);
            setSelectedSkillId(id);
          }

          props.onSelect(skill);
        }
      }}
      onClear={() => {
        setSkillName("");
        setSelectedSkillId(null);

        props.onSelect(null);
      }}
      onBlur={() => {
        if (selectedSkillId) {
          const skill = find(skills, { id: selectedSkillId }) as Skill;
          setSkillName(skill.name);
        } else {
          setSkillName("");
        }
      }}
      onInputValueChange={(skillName, downshift) => {
        setSkillName(skillName);

        if (skillName === "") {
          setSelectedSkillId(null);
          downshift?.clearSelection();
        }
      }}
      error={props.error}
      placeholder={props.placeholder}
    />
  );
}
