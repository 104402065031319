import getConfig from "next/config";

interface Config {
  nodeEnv?: string;
  apiUrl: string;
  sentryDsn: string | undefined;
  sentryEnvironment: string | undefined;
  intercomAppId: string | undefined;
  recaptchaSiteKey?: string;
  sharedAuthEnabled: boolean;
  togglAccountsUrl: string;
}

let config: Config;

if (process.env.NODE_ENV === "test") {
  config = {
    nodeEnv: undefined,
    apiUrl: "http://api.test",
    sentryDsn: undefined,
    sentryEnvironment: undefined,
    intercomAppId: undefined,
    recaptchaSiteKey: "recaptch_key",
    sharedAuthEnabled: false,
    togglAccountsUrl: "http://toggl.test",
  };
} else {
  const { publicRuntimeConfig } = getConfig();

  config = {
    nodeEnv: publicRuntimeConfig?.nodeEnv ?? undefined,
    apiUrl: publicRuntimeConfig?.apiUrl ?? "",
    sentryDsn: publicRuntimeConfig?.sentryDsn ?? undefined,
    sentryEnvironment: publicRuntimeConfig?.sentryEnvironment ?? undefined,
    intercomAppId: publicRuntimeConfig?.intercomAppId ?? undefined,
    recaptchaSiteKey: publicRuntimeConfig?.recaptchaSiteKey ?? undefined,
    sharedAuthEnabled: publicRuntimeConfig?.sharedAuthEnabled === "true",
    togglAccountsUrl: publicRuntimeConfig?.togglAccountsUrl ?? "",
  };
}

export default config;
