import React from "react";
import { Form, Formik } from "formik";
import styled from "@emotion/styled";
import { Button, LinkButton } from "@hundred5/design-system";
import { MarkdownState, RichTextState } from "@togglhire/text-editor";
import { FormikMarkdownField } from "../";
import * as yup from "yup";
import {
  ExpertQuestionStatus,
  useReviewExpertQuestionMutation,
} from "../../utils/graphql-queries";
import { useFlashMessages } from "../../utils/flash-messages";

//region Component
interface Props {
  id: string;
  onBack: () => void;
  onSubmit: () => void;
}

export default function AcceptCommentForm({ id, onSubmit, onBack }: Props) {
  const [_, reviewExpertQuestion] = useReviewExpertQuestionMutation();
  const { addMessage } = useFlashMessages();

  return (
    <Container>
      <Formik
        initialValues={{
          message: RichTextState.create(""),
        }}
        onSubmit={async (formikValues) => {
          await reviewExpertQuestion({
            id,
            status: ExpertQuestionStatus.Approved,
            feedback: {
              message: formikValues.message.markdown(),
              types: [],
            },
          });

          addMessage({
            type: "question_accepted",
          });

          onSubmit();
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            <FormikMarkdownField
              id="message"
              name="message"
              label="Comments:"
              required
            />

            <Buttons>
              <LinkButton variant="secondary" type="button" onClick={onBack}>
                Back
              </LinkButton>
              <Button type="submit">Accept Question</Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

const validationSchema = yup.object().shape({
  message: yup
    .object<RichTextState | MarkdownState>()
    .test(
      "content",
      "You must provide a comment!",
      (value) => (value?.markdown().trim() ?? "") !== ""
    ),
});
//endregion

//region Styles
const Container = styled.div``;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
`;
//endregion
