import React from "react";
import styled from "@emotion/styled";
import { Radio } from "@hundred5/design-system";
import { useField } from "formik";
import { hardDifficulty, standardDifficulty } from "../../utils/questions";

const DifficultyField: React.FC = () => (
  <div>
    <Label id="question-difficulty">Difficulty level</Label>
    <div role="group" aria-labelledby="question-difficulty">
      <Option>
        <DifficultyRadio value={standardDifficulty} /> Standard
      </Option>
      <Option>
        <DifficultyRadio value={hardDifficulty} /> Hard
      </Option>
    </div>
  </div>
);

export default DifficultyField;

const DifficultyRadio: React.FC<{ value: string }> = (props) => {
  const [field] = useField({
    name: "difficulty",
    value: props.value,
    type: "radio",
  });

  return <Radio {...field} type="radio" />;
};

const Label = styled.div`
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
`;

const Option = styled.label`
  display: grid;
  margin-bottom: 5px;
  grid-template-columns: min-content max-content;
  grid-column-gap: 5px;
  align-items: center;
`;
