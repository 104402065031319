import React from "react";

export default function CheckmarkIcon({ color }: { color?: string }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7031 5.52344C11.7031 5.67839 11.6484 5.82422 11.5391 5.93359L6.58984 10.8828C6.48047 10.9922 6.32552 11.056 6.17057 11.056C6.02474 11.056 5.86979 10.9922 5.76042 10.8828L2.46094 7.58333C2.35156 7.47396 2.29687 7.32812 2.29687 7.17318C2.29687 7.01823 2.35156 6.86328 2.46094 6.75391L3.29036 5.93359C3.39974 5.82422 3.54557 5.76042 3.70052 5.76042C3.85547 5.76042 4.0013 5.82422 4.11068 5.93359L6.17057 7.99349L9.88932 4.28385C9.9987 4.17448 10.1445 4.11068 10.2995 4.11068C10.4544 4.11068 10.6003 4.17448 10.7096 4.28385L11.5391 5.10417C11.6484 5.21354 11.7031 5.36849 11.7031 5.52344ZM14 7C14 3.13542 10.8646 0 7 0C3.13542 0 0 3.13542 0 7C0 10.8646 3.13542 14 7 14C10.8646 14 14 10.8646 14 7Z"
        fill={color || "#BDBDBD"}
      />
    </svg>
  );
}
