import React from "react";
import styled from "@emotion/styled";
import DeleteIcon from "./icons/delete-icon";
import AcceptIcon from "./icons/accept-icon";
import RejectIcon from "./icons/reject-icon";

//region Component
interface Props {
  name: string;
  isApproved?: boolean;
  onDelete: () => void;
  onAccept: () => void;
  onReject: () => void;
}

export default function SkillTag({
  name,
  isApproved = true,
  onAccept,
  onDelete,
  onReject,
}: Props) {
  return (
    <Container isApproved={isApproved}>
      <Name>{name}</Name>
      <Action>
        {isApproved ? (
          <DeleteIcon onClick={onDelete} />
        ) : (
          <>
            <RejectIcon onClick={onReject} />
            <AcceptIcon onClick={onAccept} />
          </>
        )}
      </Action>
    </Container>
  );
}
//endregion

const Container = styled.div<{ isApproved: boolean }>`
  display: flex;
  align-items: center;
  height: 25px;
  border-radius: 6px;
  user-select: none;
  white-space: nowrap;
  background: ${(props) => (props.isApproved ? "#F5F5F5" : "#ffffff")};
  border: ${(props) => (props.isApproved ? "none" : "1px solid #E7E7E7")};
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  min-width: 25px;
  height: 100%;
  padding: 0 6px 0 9px;
  flex: 1;
  text-transform: uppercase;
  border-right: 1px solid #e7e7e7;
  font-size: 10px;
  font-weight: 500;
`;

const Action = styled.div`
  padding: 0 9px 0 6px;
  min-width: 25px;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;
