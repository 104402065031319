import React from "react";

export default function ThumbDownIcon({ color }: { color?: string }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75004 1.16669H3.50004C3.01587 1.16669 2.60171 1.45835 2.42671 1.87835L0.665041 5.99085C0.612541 6.12502 0.583374 6.26502 0.583374 6.41669V7.58335C0.583374 8.22502 1.10837 8.75002 1.75004 8.75002H5.43087L4.87671 11.4159L4.85921 11.6025C4.85921 11.8417 4.95837 12.0634 5.11587 12.2209L5.73421 12.8334L9.57254 8.98919C9.78837 8.77919 9.91671 8.48752 9.91671 8.16669V2.33335C9.91671 1.69169 9.39171 1.16669 8.75004 1.16669ZM8.75004 8.16669L6.21837 10.6984L6.86587 7.58335H1.75004V6.41669L3.50004 2.33335H8.75004V8.16669ZM13.4167 1.16669H11.0834V8.16669H13.4167V1.16669Z"
        fill={color || "#989898"}
      />
    </svg>
  );
}
