import React from "react";

export default function PictureIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9078 10.9839L10.4078 3.98416C10.274 3.71729 10.0141 3.53617 9.71746 3.50555C9.42171 3.47143 9.12859 3.59655 8.94221 3.82841L5.94272 7.57679L3.98535 6.27221C3.78585 6.14008 3.53998 6.09371 3.3081 6.14621C3.07448 6.19871 2.87235 6.34483 2.74985 6.54957L0.124862 10.9244C-0.0378877 11.1948 -0.0413877 11.5308 0.113487 11.8064C0.268361 12.0811 0.559736 12.25 0.874735 12.25H13.1247C13.4283 12.25 13.7092 12.0925 13.8693 11.8344C14.0294 11.5763 14.0426 11.2543 13.9078 10.9839Z"
        fill="#BDBDBD"
      />
      <path
        d="M5.25 3.5C6.2165 3.5 7 2.7165 7 1.75C7 0.783502 6.2165 0 5.25 0C4.2835 0 3.5 0.783502 3.5 1.75C3.5 2.7165 4.2835 3.5 5.25 3.5Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}
