import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "@emotion/styled";
import {
  Content,
  PictureOption,
  PictureOptionsAnswer,
  Question,
  TextOption,
  TextOptionsAnswer,
} from "@hundred5/design-system";
import { TestGenQuestion } from "../../utils/graphql-queries";
import {
  codeInputQuestion,
  freeTextQuestion,
  multipleChoiceQuestion,
  numericInputQuestion,
  pictureQuestion,
  singleChoiceQuestion,
} from "../../utils/questions";
import Feedback from "../question-form/feedback";

//region Component
interface Props {
  question?: Partial<TestGenQuestion>;
}

export default function QuestionPreview({ question }: Props) {
  if (!question) return null;

  return (
    <>
      <Question
        description={
          <Content>
            {question.oldTestGenQuestion && (
              <Feedback
                testGenQuestion={question.oldTestGenQuestion as TestGenQuestion}
              />
            )}
            <SectionTitle>Question</SectionTitle>
            <ReactMarkdown>{question.description || ""}</ReactMarkdown>
          </Content>
        }
        answer={<Answer question={question} />}
      />
      {question?.notes && (
        <NotesContainer>
          <Content>
            <SectionTitle>Notes</SectionTitle>
            <ReactMarkdown>{question?.notes || ""}</ReactMarkdown>
          </Content>
        </NotesContainer>
      )}
    </>
  );
}

const Answer = ({ question }: { question: Partial<TestGenQuestion> }) => {
  switch (question.questionType) {
    case freeTextQuestion:
    case codeInputQuestion:
      return null;

    case numericInputQuestion:
      return (
        <>
          <SectionTitle>Numeric answer</SectionTitle>
          {question.options?.[0]?.content}
        </>
      );

    case singleChoiceQuestion:
    case multipleChoiceQuestion:
      return (
        <>
          <SectionTitle>Choices</SectionTitle>
          <TextOptionsAnswer>
            {question.options?.map((option, index) => (
              <TextOption
                key={index}
                name="question-option"
                id={`question-option-${index}`}
                type={optionTypes[question.questionType]}
                selected={option.points > 0}
                disabled
              >
                <OptionContent>
                  <ReactMarkdown>{option.content}</ReactMarkdown>
                </OptionContent>
              </TextOption>
            ))}
          </TextOptionsAnswer>
        </>
      );

    case pictureQuestion:
      return (
        <>
          <SectionTitle>Picture choices</SectionTitle>
          <PictureOptionsAnswer>
            {question.options?.map((option, index) => (
              <PictureOption
                key={index}
                name="question-option"
                id={`question-option-${index}`}
                selected={option.points > 0}
                url={option.content}
                disabled
              />
            ))}
          </PictureOptionsAnswer>
        </>
      );

    default:
      return null;
  }
};

const optionTypes: { [type: string]: "single" | "multiple" } = {
  "single-choice": "single",
  "multiple-choice": "multiple",
};
//endregion

//region Styles
const OptionContent = styled(Content)`
  margin: -10px 0;
`;

const SectionTitle = styled.div`
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const NotesContainer = styled.div`
  padding: 25px 20px 0;
  border-bottom: 1px solid #e7e7e7;

  @media (min-width: 768px) {
    padding: 25px 40px 0;
  }
`;

//endregion
