import config from "../config";
import { NetworkError, parseApiError } from "./errors";
import { getCredentialsConfig } from "./helpers";

export async function logout() {
  let response: Response;
  try {
    response = await fetch(`${config.togglAccountsUrl}/api/sessions`, {
      method: "DELETE",
      ...getCredentialsConfig(),
      headers: {
        "content-type": "application/json",
      },
    });
  } catch (error) {
    throw new NetworkError("togglAccounts/logout");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}
