import React from "react";

export default function NumericIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.375 1.75H3.5V0.875C3.5 0.35 3.15 0 2.625 0C2.1 0 1.75 0.35 1.75 0.875V1.75H0.875C0.35 1.75 0 2.1 0 2.625C0 3.15 0.35 3.5 0.875 3.5H1.75V4.375C1.75 4.9 2.1 5.25 2.625 5.25C3.15 5.25 3.5 4.9 3.5 4.375V3.5H4.375C4.9 3.5 5.25 3.15 5.25 2.625C5.25 2.1 4.9 1.75 4.375 1.75Z"
        fill="#BDBDBD"
      />
      <path
        d="M4.9875 7.2625C4.6375 6.9125 4.1125 6.9125 3.7625 7.2625L2.625 8.4L1.4875 7.2625C1.1375 6.9125 0.6125 6.9125 0.2625 7.2625C-0.0875 7.6125 -0.0875 8.1375 0.2625 8.4875L1.4 9.625L0.2625 10.7625C-0.0875 11.1125 -0.0875 11.6375 0.2625 11.9875C0.4375 12.1625 1.05 12.425 1.4875 11.9875L2.625 10.85L3.7625 11.9875C4.2 12.425 4.8125 12.1625 4.9875 11.9875C5.3375 11.6375 5.3375 11.1125 4.9875 10.7625L3.85 9.625L4.9875 8.4875C5.3375 8.1375 5.3375 7.6125 4.9875 7.2625Z"
        fill="#BDBDBD"
      />
      <path
        d="M8.75 3.5H13.125C13.65 3.5 14 3.15 14 2.625C14 2.1 13.65 1.75 13.125 1.75H8.75C8.225 1.75 7.875 2.1 7.875 2.625C7.875 3.15 8.225 3.5 8.75 3.5Z"
        fill="#BDBDBD"
      />
      <path
        d="M13.125 7H8.75C8.225 7 7.875 7.35 7.875 7.875C7.875 8.4 8.225 8.75 8.75 8.75H13.125C13.65 8.75 14 8.4 14 7.875C14 7.35 13.65 7 13.125 7Z"
        fill="#BDBDBD"
      />
      <path
        d="M13.125 10.5H8.75C8.225 10.5 7.875 10.85 7.875 11.375C7.875 11.9 8.225 12.25 8.75 12.25H13.125C13.65 12.25 14 11.9 14 11.375C14 10.85 13.65 10.5 13.125 10.5Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}
