import React from "react";
import { useFormikContext } from "formik";
import { Values } from "./values";
import {
  Content,
  PictureOption,
  PictureOptionsAnswer,
  Question,
  QuestionTitle,
  TextAnswer,
  TextOption,
  TextOptionsAnswer,
} from "@hundred5/design-system";
import ReactMarkdown from "react-markdown";
import {
  codeInputQuestion,
  freeTextQuestion,
  multipleChoiceQuestion,
  numericInputQuestion,
  pictureQuestion,
  singleChoiceQuestion,
} from "../../utils/questions";
import { getOptions, getSingleOptionContent } from "@togglhire/question-editor";
import styled from "@emotion/styled";

const Preview: React.FC = () => {
  const { values } = useFormikContext<Values>();

  return (
    <Question
      header={<QuestionTitle>Preview</QuestionTitle>}
      description={
        <Content>
          <ReactMarkdown>{values.description.markdown()}</ReactMarkdown>
        </Content>
      }
      answer={<Answer />}
    />
  );
};

export default Preview;

const Answer: React.FC = () => {
  const { values } = useFormikContext<Values>();

  switch (values.type) {
    case freeTextQuestion:
    case codeInputQuestion:
      return (
        <TextAnswer placeholder="Write answer here..." value="" disabled />
      );

    case numericInputQuestion:
      return (
        <TextAnswer
          placeholder="Write answer here..."
          value={getSingleOptionContent(values.options) ?? ""}
          disabled
        />
      );

    case singleChoiceQuestion:
    case multipleChoiceQuestion:
      return (
        <TextOptionsAnswer>
          {getOptions(values.options).map((option, index) => (
            <TextOption
              key={index}
              name="question-option"
              id={`question-option-${index}`}
              type={optionTypes[values.type]}
              selected={option.points > 0}
              disabled
            >
              <OptionContent>
                <ReactMarkdown>{option.content}</ReactMarkdown>
              </OptionContent>
            </TextOption>
          ))}
        </TextOptionsAnswer>
      );

    case pictureQuestion:
      return (
        <PictureOptionsAnswer>
          {getOptions(values.options).map((option, index) => (
            <PictureOption
              key={index}
              name="question-option"
              id={`question-option-${index}`}
              selected={option.points > 0}
              url={option.content}
              disabled
            />
          ))}
        </PictureOptionsAnswer>
      );

    default:
      return null;
  }
};

const optionTypes: { [type: string]: "single" | "multiple" } = {
  [singleChoiceQuestion]: "single",
  [multipleChoiceQuestion]: "multiple",
};

const OptionContent = styled(Content)`
  margin: -10px 0;
`;
