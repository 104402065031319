import styled from "@emotion/styled";

const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  & > * {
    margin: 0 8px 16px;
  }
`;

export default FlexGrid;
