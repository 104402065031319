import React from "react";
import { useDeleteQuestionMutation } from "../../utils/graphql-queries";
import { useFlashMessages } from "../../utils/flash-messages";
import { useRouter } from "next/router";
import { ConfirmModal } from "../index";
import styled from "@emotion/styled";

interface Props {
  questionId: string;
  disabled?: boolean;
}

const DeleteButton = (props: Props) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [{ fetching }, deleteQuestion] = useDeleteQuestionMutation();
  const flashMessages = useFlashMessages();
  const router = useRouter();

  const handleConfirm = async () => {
    const result = await deleteQuestion({ id: props.questionId });
    if (result.error) return;

    flashMessages.addMessage({ type: "question_deleted" });

    const batchId = result.data?.deleteExpertQuestion.expertQuestionBatch.id;
    router.push(batchId ? `/batches/${batchId}` : "/");
  };

  return (
    <>
      <ConfirmModal
        open={modalOpen}
        action="Delete"
        onConfirm={handleConfirm}
        onClose={() => setModalOpen(false)}
      >
        <h3>Are you sure?</h3>
        <p>Do you really want to delete this question?</p>
      </ConfirmModal>
      <IconButton
        type="button"
        disabled={props.disabled || fetching}
        data-tooltip={
          props.disabled
            ? "You are not allowed to delete submitted questions"
            : null
        }
        onClick={() => setModalOpen(true)}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};

export default DeleteButton;

const IconButton = styled.button`
  display: block;
  position: relative;
  padding: 10px;
  margin: -10px;
  -webkit-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    display: block;
  }
`;

const DeleteIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.478 17H3.516L2.553 3.245h12.962L14.478 17zM1 3.245h16M10.705 1H7.372L6.63 3.244h4.814L10.705 1zM9 5.417v9.267M12.407 5.417l-.667 9.267M5.666 5.417l.593 9.267"
      stroke="#000000"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
