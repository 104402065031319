import { Button, CloseModalButton, Modal } from "@hundred5/design-system";
import React from "react";
import styled from "@emotion/styled";

interface Props {
  open: boolean;
  action: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmModal: React.FC<Props> = (props) => {
  return (
    <Modal open={props.open} size="small" onClose={props.onClose}>
      <Content>
        {props.children}

        <Buttons>
          <Button type="button" onClick={props.onConfirm}>
            {props.action}
          </Button>
          <Button type="button" variant="secondary" onClick={props.onClose}>
            Cancel
          </Button>
        </Buttons>

        <CloseButton onClick={props.onClose} />
      </Content>
    </Modal>
  );
};

export default ConfirmModal;

const Content = styled.div`
  padding: 10px 20px;
  position: relative;
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  grid-column-gap: 10px;
  margin: 10px 0;
`;

const CloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
