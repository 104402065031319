import React from "react";
import { useField, useFormikContext } from "formik";
import {
  ChoiceAnswerEditor,
  emptyOptionsState,
  getMovedOption,
  handleMovedOption,
  NumericAnswerEditor,
  OptionsState,
} from "@togglhire/question-editor";
import { DragDropContext } from "react-beautiful-dnd";
import { Values } from "./values";
import {
  isChoiceQuestionType,
  multipleChoiceQuestion,
  numericInputQuestion,
  pictureQuestion,
  singleChoiceQuestion,
} from "../../utils/questions";
import styled from "@emotion/styled";
import { Field } from "@hundred5/design-system";
import { usePrevious } from "react-use";
import { useImageUpload } from "../../hooks/image-upload";

const AnswerField: React.FC = () => {
  const {
    values: { type: currentType },
    setFieldValue,
  } = useFormikContext<Values>();

  const previousType = usePrevious(currentType);

  React.useEffect(() => {
    if (
      previousType != null && // it is not the initial render
      previousType !== currentType && // question type has changed
      !(isChoiceQuestionType(previousType) && isChoiceQuestionType(currentType)) // it is not a simple change between choice types
    ) {
      setFieldValue("options", emptyOptionsState());
    }
  }, [setFieldValue, currentType, previousType]);

  if (currentType === singleChoiceQuestion) {
    return <ChoiceAnswerField type="single" />;
  } else if (currentType === multipleChoiceQuestion) {
    return <ChoiceAnswerField type="multiple" />;
  } else if (currentType === pictureQuestion) {
    return <ChoiceAnswerField type="picture" />;
  } else if (currentType === numericInputQuestion) {
    return <NumericAnswerField />;
  } else {
    return null;
  }
};

export default AnswerField;

const DragDropHandler: React.FC = (props) => {
  const [field, , helpers] = useOptionsField();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const option = getMovedOption(result);

        if (option != null) {
          helpers.setValue(handleMovedOption(field.value, option));
        }
      }}
    >
      {props.children}
    </DragDropContext>
  );
};

interface ChoiceProps {
  type: "single" | "multiple" | "picture";
}

const ChoiceAnswerField: React.FC<ChoiceProps> = (props) => {
  const [field, meta, helpers] = useOptionsField();
  const uploadImage = useImageUpload();

  return (
    <DragDropHandler>
      <StyledField
        for="answer"
        label="Choices"
        error={meta.touched ? meta.error : undefined}
        input={
          <ChoiceAnswerEditor
            id="question"
            type={props.type}
            uploadImage={uploadImage}
            state={field.value}
            onChange={(state) => helpers.setValue(state)}
          />
        }
      />
    </DragDropHandler>
  );
};

const NumericAnswerField: React.FC = (props) => {
  const [field, meta, helpers] = useOptionsField();

  return (
    <StyledField
      for="answer"
      label="Correct answer"
      error={meta.touched ? meta.error : undefined}
      input={
        <NumericAnswerEditor
          id="answer"
          name="answer"
          state={field.value}
          onChange={(state) => helpers.setValue(state)}
        />
      }
    />
  );
};

const useOptionsField = () => useField<OptionsState>("options");

const StyledField = styled(Field)`
  margin-bottom: 20px;
`;
