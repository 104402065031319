import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "../../types/nav-link";
import Link from "next/link";
import { useLogout } from "../../utils/authentication";
import useOnClickOutside from "use-onclickoutside";
import { useNavbarQuery } from "../../utils/graphql-queries";
import { HorizontalLogo, ProfileIcon } from "../../icons";
import { useRouter } from "next/router";

const NavItems: NavLink[] = [
  {
    path: "/settings/profile",
    label: "Edit Profile",
    isPrimary: false,
  },
];

const navBarHeight = "48px";
const navBarColor = "#2C1338";

interface Props {
  isAdminContent?: boolean;
}

export default function Navbar({ isAdminContent }: Props) {
  const [{ data, fetching }] = useNavbarQuery();
  const logout = useLogout();
  const [menuOpen, setMenuOpen] = useState(false);
  const [keyActivation, setKeyActivation] = useState(false);
  const outsideRef = React.useRef(null);
  useOnClickOutside(outsideRef, () => setMenuOpen(false));
  const router = useRouter();

  return (
    <NavigationBar>
      <Link href={isAdminContent ? "/admin" : ""} passHref>
        <Logo>
          <HorizontalLogo />
        </Logo>
      </Link>
      {isAdminContent && (
        <>
          <Link href={"/admin/experts"} passHref>
            <NavigationLink active={router.pathname === "/admin/experts"}>
              Experts
            </NavigationLink>
          </Link>
          <Link href={"/admin/challenges/list"} passHref>
            <NavigationLink
              active={router.pathname === "/admin/challenges/list"}
            >
              Challenges
            </NavigationLink>
          </Link>
          <Link href={"/admin/test-gen-questions"} passHref>
            <NavigationLink
              active={router.pathname === "/admin/test-gen-questions"}
            >
              Questions
            </NavigationLink>
          </Link>
        </>
      )}

      <Menu ref={outsideRef} menuOpen={menuOpen}>
        <UserInfo
          onClick={(e) => {
            if (!keyActivation) {
              e.currentTarget.blur();
            }
            setKeyActivation(false);
            setMenuOpen(!menuOpen);
          }}
          onKeyPress={(e) => {
            switch (e.key) {
              case " ":
              case "Enter":
                setKeyActivation(true);
            }
          }}
        >
          <ProfileImage />
          <span>{fetching ? "" : data?.me?.name}</span>
        </UserInfo>

        {data?.me?.role === "EXPERT" &&
          NavItems.map((ni, i) => (
            <Link key={i} href={ni.path} passHref>
              <MenuItem
                secondary={ni.isPrimary}
                onClick={() => setMenuOpen(false)}
                tabIndex={menuOpen ? 0 : -1}
              >
                {ni.label}
              </MenuItem>
            </Link>
          ))}

        <MenuItemBtn
          secondary
          onClick={() => logout()}
          tabIndex={menuOpen ? 0 : -1}
        >
          Logout
        </MenuItemBtn>
      </Menu>
    </NavigationBar>
  );
}

const NavigationBar = styled.div`
  height: ${navBarHeight};
  display: flex;
  background-color: ${navBarColor};
`;

type NavigationLinkProps = {
  active: Boolean;
};

const NavigationLink = styled.a`
  display: flex;
  color: ${(props: NavigationLinkProps) =>
    props.active ? "#ffde91" : "white"};
  margin: 0 20px;
  align-items: center;
  &:hover {
    color: #ff897a;
  }
`;

const Logo = styled.a`
  margin-left: 24px;
  margin-right: 20px;
`;

const UserInfo = styled.button`
  width: 100%;
  display: flex;
  align-items: center;

  border: 0;
  background: none;
  color: #fff;

  padding: 12px;

  cursor: pointer;

  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &::after {
    content: "";

    transition: transform 0.5s ease;

    margin-left: 39px;

    opacity: 50%;

    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #fff;
  }
`;

type MenuProps = {
  menuOpen: boolean;
};
const Menu = styled.div`
  position: absolute;
  z-index: 1;
  display: inline-block;
  right: 0;
  overflow: hidden;

  min-width: 170px;
  max-width: 240px;

  max-height: ${(props: MenuProps) =>
    props.menuOpen ? "300px" : navBarHeight};
  transition: max-height
    ${(props: MenuProps) => (props.menuOpen ? ".3s" : ".2s")} ease-in;

  color: #fff;

  background-color: ${navBarColor};

  ${UserInfo} {
    &::after {
      transform: rotate(
        ${(props: MenuProps) => (props.menuOpen ? "180" : "0")}deg
      );
    }
  }

  @media (min-width: 500px) {
    max-width: 390px;
  }
`;

const ProfileImage = styled(ProfileIcon)`
  display: block;
  margin-left: auto;
  margin-right: 7px;
`;

type MenuItemProps = {
  secondary?: Boolean;
};
const MenuItem = styled.a`
  display: block;
  padding: 10px 0 10px 19px;
  margin: 6px;

  color: white;

  cursor: pointer;

  &:hover {
    background-color: #4d2866;
    text-decoration: underline;
  }

  opacity: ${(props: MenuItemProps) => (props.secondary ? "50%" : "100%")};
`;

const MenuItemBtn = styled.button`
  display: block;
  width: 100%;
  padding: 10px 0 10px 19px;
  margin: 6px;

  color: white;

  // removeBtnStyling
  border: 0;
  background: none;
  text-align: left;
  font-size: 14px;

  cursor: pointer;

  &:hover {
    background-color: #4d2866;
    text-decoration: underline;
  }

  opacity: ${(props: MenuItemProps) => (props.secondary ? "50%" : "100%")};
`;
