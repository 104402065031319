import React, { useCallback, useEffect } from "react";
import { useApiError } from "../../hooks/api-error";
import { useRouter } from "next/router";
import { useFlashMessages } from "../../utils/flash-messages";
import { verifyEmail } from "../../api/authentication";
import { checkClientError } from "../../api/errors";
import { useAuthToken } from "../../utils/authentication";
import { Loader } from "../common";
import { useGoogleReCaptchaToken } from "../../hooks/google-recaptcha";

interface Props {
  token: string;
}

export default function EmailVerification(props: Props) {
  const handleApiError = useApiError();
  const router = useRouter();
  const authToken = useAuthToken();
  const { addMessage } = useFlashMessages();
  const {
    googleRecaptchaToken,
    refreshGoogleRecaptchaToken,
  } = useGoogleReCaptchaToken("verify_email");
  const verify = useCallback(async () => {
    if (!googleRecaptchaToken) {
      return;
    }
    try {
      await verifyEmail(authToken, props.token, googleRecaptchaToken);
      addMessage({ type: "email_verification" });
    } catch (error) {
      if (checkClientError(error, "InvalidToken")) {
        addMessage({ type: "email_verification", error: "invalid_token" });
      } else if (checkClientError(error, "AlreadyVerified")) {
        addMessage({ type: "email_verification", error: "already_verified" });
      } else {
        handleApiError(error);
      }
    } finally {
      refreshGoogleRecaptchaToken();
    }

    return router.push("/");
  }, [
    addMessage,
    authToken,
    handleApiError,
    router,
    props.token,
    googleRecaptchaToken,
    refreshGoogleRecaptchaToken,
  ]);

  useEffect(() => {
    void verify();
  }, [verify]);

  return <Loader fullScreen />;
}
