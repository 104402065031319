import React, { useMemo } from "react";
import { pick } from "lodash";
import { Formik, Form, FormikHelpers } from "formik";
import styled from "@emotion/styled";
import { Button, Field, LinkButton } from "@hundred5/design-system";
import { format, parseISO } from "date-fns";
import {
  Expert,
  ExpertQuestionBatch,
  ExpertStatus,
  FetchExpertQuery,
  useApproveExpertSkillMutation,
  useDeleteExpertSkillMutation,
} from "../../utils/graphql-queries";
import {
  BatchSummaries,
  FormikRadioField,
  FormikTextField,
  SkillsDropdown,
  SkillTag,
  FormikCheckboxField,
} from "../index";
import { Skill } from "../../types/skill";
import * as yup from "yup";
import {
  BatchSummaryViewType,
  TableBatchItem,
} from "../batch-summaries/batch-summaries";
import { getDeadlineDate } from "../../utils/question-batch";

//region Component
interface Props {
  expert?: FetchExpertQuery["expert"];
  onSubmit: (
    expert: ExpertFormValues,
    formikHelpers: FormikHelpers<ExpertFormValues>,
    initialValues: ExpertFormValues
  ) => void;
  onCancel: () => void;
}

type ExtendedSkill = Skill & { expertSkillID?: string; isApproved?: boolean };

export type ExpertFormValues = {
  email: string;
  fullName: string;
  linkedin: string;
  expertSkills: ExtendedSkill[];
  isReviewer: string;
  status: ExpertStatus;
  isActiveReviewer: boolean | null | undefined;
  isActiveExpert: boolean | null | undefined;
};

export default function ExpertForm({ expert, onSubmit, onCancel }: Props) {
  const [_, approveExpertSkill] = useApproveExpertSkillMutation();
  const [__, deleteExpertSkill] = useDeleteExpertSkillMutation();

  const formValues = useMemo<ExpertFormValues>(() => {
    if (expert) {
      return {
        ...pick(expert, [
          "fullName",
          "linkedin",
          "expertSkills",
          "isReviewer",
          "status",
          "isActiveReviewer",
          "isActiveExpert",
        ]),
        email: expert.user?.email || "",
        isReviewer: expert.isReviewer ? "true" : "false",
        isActiveReviewer: expert.isActiveReviewer,
        isActiveExpert: expert.isActiveExpert,
        expertSkills:
          expert.expertSkills?.map((skill) => {
            return {
              ...skill.skill,
              expertSkillID: skill.id,
              isApproved: skill.isApproved,
            } as ExtendedSkill;
          }) || [],
      };
    } else {
      return INITIAL_VALUES;
    }
  }, [expert]);

  const parsedBatches = useMemo<TableBatchItem[]>(() => {
    const batchesCreatedAndReviewed =
      expert &&
      expert?.expertQuestionBatches
        .concat(expert?.expertQuestionBatchesReviewed)
        .sort(function (a, b) {
          return parseInt(b.id) - +parseInt(a.id);
        });
    const parsedBatches = [];
    if (expert && batchesCreatedAndReviewed) {
      for (const batch of batchesCreatedAndReviewed) {
        const parsedBatch: TableBatchItem | undefined = batch && {
          id: batch.id,
          challengeName: batch?.expertChallenge?.name,
          status: batch.status,
          statusChangedAt: parseISO(batch.statusChangedAt),
          isReviewer: batch.reviewers.nodes
            .map((reviewer) => reviewer.id)
            .includes(expert.id),
          isExpertPaid: !!batch.paidAt,
          isReviewerPaid: !!batch.reviewersPaidAt,
          dueOn: getDeadlineDate(batch as ExpertQuestionBatch),
        };
        parsedBatch && parsedBatches.push(parsedBatch);
      }
    }

    return parsedBatches;
  }, [expert]);

  return (
    <Formik<ExpertFormValues>
      initialValues={formValues}
      onSubmit={(expert, formikHelpers) =>
        onSubmit(expert, formikHelpers, formValues)
      }
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(form) => {
        return (
          <Form>
            <Container>
              <Inputs>
                <FormikTextField
                  id="expert-email"
                  name="email"
                  label="E-mail address:"
                  required
                />
                <FormikTextField
                  id="expert-name"
                  name="fullName"
                  label="Name:"
                  required
                />
                <FormikTextField
                  id="expert-linkedin"
                  name="linkedin"
                  label="LinkedIn profile:"
                />
                <Field
                  for="skills"
                  label="Skills:"
                  required
                  input={
                    <>
                      <Skills>
                        {form.values.expertSkills.map((skill) => (
                          <SkillTag
                            key={skill.id}
                            name={skill.name}
                            isApproved={skill.isApproved}
                            onDelete={() => {
                              skill.expertSkillID &&
                                deleteExpertSkill({ id: skill.expertSkillID });
                              form.setFieldValue(
                                "expertSkills",
                                form.values.expertSkills?.filter(
                                  (searchSkill) => skill.id !== searchSkill.id
                                )
                              );
                            }}
                            onReject={() => {
                              skill.expertSkillID &&
                                deleteExpertSkill({ id: skill.expertSkillID });
                              form.setFieldValue(
                                "expertSkills",
                                form.values.expertSkills?.filter(
                                  (searchSkill) => skill.id !== searchSkill.id
                                )
                              );
                            }}
                            onAccept={() => {
                              skill.expertSkillID &&
                                approveExpertSkill({ id: skill.expertSkillID });
                            }}
                          />
                        ))}
                      </Skills>

                      <SkillsDropdown
                        isStateless
                        onSelect={(skill) =>
                          skill &&
                          form.setFieldValue(
                            "expertSkills",
                            form.values.expertSkills?.concat([skill])
                          )
                        }
                      />
                    </>
                  }
                />
                <RadioGroup>
                  <b>Can review:</b>
                  <FormikRadioField
                    id="expert-isreviewer-true"
                    name="isReviewer"
                    label="No"
                    value="false"
                  />
                  <FormikRadioField
                    id="expert-isreviewer-false"
                    name="isReviewer"
                    label="Yes"
                    value="true"
                  />
                </RadioGroup>
                <RadioGroup>
                  <b>Status:</b>
                  <FormikRadioField
                    id="expert-status-active"
                    name="status"
                    label="Active"
                    value="active"
                  />
                  <FormikRadioField
                    id="expert-status-onhold"
                    name="status"
                    label="On Hold"
                    value="on_hold"
                  />
                  <FormikRadioField
                    id="expert-status-inactive"
                    name="status"
                    label="Inactive"
                    value="inactive"
                  />
                  <FormikRadioField
                    id="expert-status-suspended"
                    name="status"
                    label="Suspended"
                    value="suspended"
                  />
                </RadioGroup>
                <CheckboxGroup>
                  <b>Tags:</b>
                  <FormikCheckboxField
                    id="is-active-expert"
                    name="isActiveExpert"
                    label="Active expert"
                  />
                  <FormikCheckboxField
                    id="is-active-reviewer"
                    name="isActiveReviewer"
                    label="Active reviewer"
                  />
                </CheckboxGroup>
              </Inputs>
              {expert && (
                <div>
                  <b>Batches:</b>
                  {parsedBatches && parsedBatches.length > 0 ? (
                    <BatchSummaries
                      viewType={BatchSummaryViewType.Expert}
                      batches={parsedBatches}
                    />
                  ) : (
                    <p>No batches</p>
                  )}
                </div>
              )}
              <Buttons>
                <LinkButton
                  variant="secondary"
                  onClick={onCancel}
                  type="button"
                >
                  Cancel
                </LinkButton>
                <Button type="submit">
                  {expert ? "Save changes" : "Add expert"}
                </Button>
              </Buttons>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}

const INITIAL_VALUES: ExpertFormValues = {
  email: "",
  fullName: "",
  linkedin: "",
  expertSkills: [],
  isReviewer: "false",
  isActiveReviewer: false,
  isActiveExpert: false,
  status: ExpertStatus.Active,
};

const linkedInExpr = /^(https:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/in\/.+$/;

const validationSchema = yup.object().shape({
  fullName: yup.string().required("Please enter the expert's billing name"),
  email: yup
    .string()
    .email("Please enter an email address, for example alex@gmail.com"),
  linkedin: yup
    .string()
    .matches(
      linkedInExpr,
      "Please enter a LinkedIn URL, for example https://linkedin.com/in/alex"
    ),
});

//endregion

//region Styles
const Container = styled.div`
  padding: 24px 36px 0;
`;

const Inputs = styled.div``;

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin: 0 10px 10px 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;

  & > :first-of-type {
    margin-right: 15px;
  }
`;

const RadioGroup = styled.div`
  display: inline-block;
  width: 33%;
  margin-top: 20px;
  vertical-align: top;
`;

const CheckboxGroup = styled.div`
  display: inline-block;
  width: 33%;
  margin-top: 20px;
  vertical-align: top;
`;
//endregion
