import { ReactNode, FC } from "react";
import Link from "next/link";
import styled from "@emotion/styled";
import NavigationIcon from "../../icons/NavigationIcon";

interface Props {
  title: ReactNode;
  badges?: ReactNode;
  content?: ReactNode;
  backHref?: string;
}

const PageHeader: FC<Props> = (props) => {
  return (
    <HeaderContainer>
      <HeaderTitle>
        {props.backHref != null ? (
          <Link href={props.backHref} passHref>
            <BackLink>
              <NavigationIcon />
            </BackLink>
          </Link>
        ) : null}

        <h1>{props.title}</h1>

        {props.badges}
      </HeaderTitle>

      {props.content != null ? (
        <HeaderContent>{props.content}</HeaderContent>
      ) : null}
    </HeaderContainer>
  );
};

export default PageHeader;

const HeaderContainer = styled.header`
  position: sticky;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  background: #ffffff;
  padding: 0 24px;
  box-sizing: border-box;
`;

const HeaderTitle = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  padding: 20px 0;

  h1 {
    margin: 0;
  }
`;

const BackLink = styled.a`
  svg {
    display: block;
  }
`;

const HeaderContent = styled.div``;
