import React from "react";

export default function FreeTextIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.625 0H14V1.75H9.625V0Z" fill="#BDBDBD" />
      <path d="M9.625 3.5H14V5.25H9.625V3.5Z" fill="#BDBDBD" />
      <path d="M9.625 7H14V8.75H9.625V7Z" fill="#BDBDBD" />
      <path d="M0 10.5H14V12.25H0V10.5Z" fill="#BDBDBD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 7.875H6.00735L5.27179 6.125H2.60321L1.86765 7.875H0L2.94271 0.875H4.93229L7.875 7.875ZM3.93832 2.95039L3.33958 4.37483H4.53706L3.93832 2.95039Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}
