import { Me } from "../types/me";
import { getAuthHeaders, getCredentialsConfig } from "./helpers";
import config from "../config";
import { NetworkError, parseApiError } from "./errors";
import { camelizeKeys } from "humps";

export const fetchMe = async (token: string | null): Promise<Me> => {
  let response: Response;

  try {
    response = await fetch(`${config.apiUrl}/api/v1/me`, {
      ...getCredentialsConfig(),
      headers: { ...getAuthHeaders(token) },
    });
  } catch (error) {
    throw new NetworkError("me");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }

  const data = await response.json();
  const { id, ...rest } = camelizeKeys(data as object) as Me;

  return {
    id: String(id),
    ...rest,
  };
};

export async function updateMe(authToken: string | null, values: Me) {
  let response: Response;
  try {
    response = await fetch(`${config.apiUrl}/api/v1/me?app=expert`, {
      method: "PUT",
      ...getCredentialsConfig(),
      headers: {
        "content-type": "application/json",
        ...getAuthHeaders(authToken),
      },
      body: JSON.stringify(values),
    });
  } catch (error) {
    throw new NetworkError("me");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}

export async function deleteMe(authToken: string | null) {
  let response: Response;
  try {
    response = await fetch(`${config.apiUrl}/api/v1/me`, {
      method: "DELETE",
      ...getCredentialsConfig(),
      headers: {
        "content-type": "application/json",
        ...getAuthHeaders(authToken),
      },
    });
  } catch (error) {
    throw new NetworkError("me");
  }

  if (!response.ok) {
    throw await parseApiError(response);
  }
}
