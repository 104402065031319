import styled from "@emotion/styled";
import { LinkButton } from "@hundred5/design-system";
import { useState } from "react";
import useInfiniteQuery, {
  UsePaginatedQueryArgs,
} from "../../hooks/infinite-query";
import { ViewMode } from "../../types/viewMode";
import {
  ExpertQuestionBatch,
  ExpertChallenge as ExpertChallengeType,
} from "../../utils/graphql-queries";
import AdminListBatch from "../admin-list-batch";
import { FlexGrid } from "../common";
import ExpertChallenge from "../expert-challenge";

interface ListProps {
  type: "batch" | "challenge";
  title: string;
  emptyState: string;
  queryArgs: UsePaginatedQueryArgs<ExpertQuestionBatch | ExpertChallengeType>;
  firstElement?: React.ReactNode;
  collapsible?: boolean;
}

function List({
  type,
  title,
  emptyState,
  queryArgs,
  firstElement,
  collapsible,
}: ListProps) {
  const [collapsed, setCollapsed] = useState(collapsible);
  const { data, fetchMore, hasMore, total } = useInfiniteQuery<
    ExpertQuestionBatch | ExpertChallengeType
  >({ pause: collapsed, ...queryArgs });
  return (
    <ListContainer>
      <ListContainerHeader>
        <h3>{title}</h3>
        {collapsible && (
          <CollapseButton
            onClick={() => setCollapsed((prevValue) => !prevValue)}
          >
            {collapsed ? "Show" : "Hide"}
          </CollapseButton>
        )}
      </ListContainerHeader>
      {collapsed ? null : (
        <ListContainerBody>
          <FlexGrid>
            {firstElement}
            {data?.length ? (
              <>
                {type === "batch"
                  ? data.map((questionBatch) => (
                      <AdminListBatch
                        key={questionBatch.id}
                        batch={questionBatch as ExpertQuestionBatch}
                      />
                    ))
                  : data.map((expertChallenge) => (
                      <ExpertChallenge
                        key={expertChallenge.id}
                        expertChallenge={expertChallenge as ExpertChallengeType}
                        viewMode={ViewMode.Admin}
                      />
                    ))}
                <LoadMore>
                  {`${data?.length} of ${total} displayed`}
                  {hasMore && (
                    <LoadMoreButton onClick={() => fetchMore()}>
                      Load more...
                    </LoadMoreButton>
                  )}
                </LoadMore>
              </>
            ) : (
              <>{emptyState}</>
            )}
          </FlexGrid>
        </ListContainerBody>
      )}
    </ListContainer>
  );
}

const CollapseButton = styled(LinkButton)`
  margin-left: auto;
`;
const ListContainer = styled.div`
  background: #ffffff;
  margin-bottom: 30px;
`;

const ListContainerHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 36px;
  border-bottom: 1px solid #e7e7e7;

  h3 {
    margin: 0;
  }
`;

const ListContainerBody = styled.div`
  padding: 16px 36px;
`;

const LoadMoreButton = styled(LinkButton)`
  margin-left: 3px;
`;

const LoadMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  color: #989898;
  font-size: 12px;
`;

export default List;
