import { TestType } from "./graphql-queries";

export const freeTextQuestion = "free-text";
export const singleChoiceQuestion = "single-choice";
export const multipleChoiceQuestion = "multiple-choice";
export const pictureQuestion = "picture-question";
export const numericInputQuestion = "numeric-input";
export const codeInputQuestion = "code-input";

export const questionTypes = {
  [freeTextQuestion]: "Free text",
  [singleChoiceQuestion]: "Single choice",
  [multipleChoiceQuestion]: "Multiple choice",
  [pictureQuestion]: "Picture choice",
  [numericInputQuestion]: "Numeric input",
  [codeInputQuestion]: "Code input",
};

export const questionTypesDisabled = [pictureQuestion];

export const isChoiceQuestionType = (type: string): boolean => {
  return type === singleChoiceQuestion || type === multipleChoiceQuestion;
};

export const standardDifficulty = "standard";
export const hardDifficulty = "hard";

export const questionDifficulties = {
  [standardDifficulty]: "Standard",
  [hardDifficulty]: "Hard",
};

export const isOpenEndedQuestion = (type: string): boolean => {
  return type === freeTextQuestion || type === codeInputQuestion;
};

export const defaultTimeOpenEndedQuestion = "300";
export const defaultTimeChoiceQuestion = "30";
export const defaultHomeworkQuestion = "1200";

export const getDefaultQuestionDuration = (
  type: string,
  testType: TestType | null | undefined
) => {
  if (testType === TestType.Homework) {
    return defaultHomeworkQuestion;
  }

  return isOpenEndedQuestion(type)
    ? defaultTimeOpenEndedQuestion
    : defaultTimeChoiceQuestion;
};
