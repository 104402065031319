import React, { useState } from "react";
import styled from "@emotion/styled";
import { TextInput } from "@hundred5/design-system";
import { useDebounce } from "react-use";
import { SearchIcon } from "../../icons";

interface Props {
  onSearch: (value: string) => void;
  placeholder?: string;
}

export default function SearchInput({ placeholder, onSearch, ...rest }: Props) {
  const [value, setValue] = useState<string | undefined>();

  useDebounce(
    () => {
      if (value !== undefined) {
        onSearch(value);
      }
    },
    250,
    [value]
  );

  return (
    <SearchBox {...rest}>
      <StyledInput
        value={value || ""}
        onChange={(event) => setValue(event.target.value)}
        placeholder={placeholder || "Search..."}
      />
      <SearchIcon />
    </SearchBox>
  );
}

const SearchBox = styled.div`
  display: block;
  line-height: 1.5;
  font-size: 1rem;
  color: #1a1a1a;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  -webkit-appearance: none;
  transition: border-color 0.2s, background-color 0.2s;
  position: relative;

  &:focus-within {
    outline: none;
    border-color: #333333;
    & svg path {
      fill: #333333;
    }
  }

  &::placeholder {
    color: #bdbdbd;
    opacity: 1;
  }

  & svg {
    position: absolute;
    right: 0;
    top: 33%;
    display: inline-block;
    margin: 0 8px;
  }
  width: 200px;
  margin-left: auto;
`;

const StyledInput = styled(TextInput)`
  border: 0;
  display: inline-block;
  width: calc(100% - 15px);
`;
