import React from "react";
import { useFormikContext } from "formik";
import { Form } from "@hundred5/design-system";
import styled from "@emotion/styled";

interface Props {
  header: JSX.Element;
  content: JSX.Element;
  footer?: JSX.Element;
}

export default function FormikForm(props: Props) {
  const form = useFormikContext();

  return (
    <Form
      header={props.header}
      content={<Content>{props.content}</Content>}
      footer={props.footer}
      onSubmit={form.handleSubmit}
      onReset={form.handleReset}
    />
  );
}

const Content = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;
