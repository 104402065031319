import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { pick } from "lodash";
import { Button, Field, LinkButton } from "@hundred5/design-system";
import {
  ExpertChallenge,
  NewExpertChallenge,
  TestType,
} from "../../utils/graphql-queries";
import {
  ExpertsDropdown,
  FormikRadioField,
  FormikSelectField,
  FormikTextField,
  SkillsDropdown,
} from "../";
import ExpertTag from "../expert-tag";
import { testTypes } from "../../utils/test-types";

//region Component
interface Props {
  expertChallenge?: Partial<ExpertChallenge>;
  onSubmit: (challenge: NewExpertChallenge) => void;
  onCancel: () => void;
}

export default function ExpertChallengeForm({
  expertChallenge,
  onSubmit,
  onCancel,
}: Props) {
  const [accessList, setAccessList] = React.useState<
    { id: string; name: string }[]
  >(
    expertChallenge?.experts?.map((expert) => {
      return { id: expert.id, name: expert.user.name };
    }) || []
  );
  const challenge = useMemo(() => {
    if (expertChallenge) {
      return {
        ...pick(expertChallenge, [
          "name",
          "deadline",
          "requiredQuestions",
          "visibility",
          "testType",
        ]),
        expertChallengeSkillIDs: expertChallenge.expertChallengeSkills?.map(
          (skill) => skill.skill.id
        ),
        expertIDs: expertChallenge.experts?.map((expert) => expert.id),
        testType: expertChallenge.testType
          ? expertChallenge.testType
          : TestType.Quiz,
      };
    } else {
      return INITIAL_VALUES;
    }
  }, [expertChallenge]);

  return (
    <Formik<NewExpertChallenge>
      initialValues={challenge}
      onSubmit={onSubmit}
      validationSchema={VALIDATION_SCHEMA}
      enableReinitialize
    >
      {(form) => {
        const skillsError =
          form.getFieldMeta("expertChallengeSkillIDs").touched &&
          form.getFieldMeta("expertChallengeSkillIDs").error;

        const expertsError =
          form.getFieldMeta("expertIDs").touched &&
          form.getFieldMeta("expertIDs").error;

        return (
          <Form>
            <Container>
              <Inputs>
                <FormikTextField
                  id="expert-challenge-name"
                  name="name"
                  label="Challenge name:"
                  required
                />

                <FormikSelectField
                  id="test-type"
                  name="testType"
                  label="Test type"
                  required
                >
                  {Object.values(TestType).map((testType) => (
                    <option key={testType} value={testType}>
                      {testType ? testTypes[testType] : testTypes.quiz}
                    </option>
                  ))}
                </FormikSelectField>

                <Field
                  for="skills"
                  label="Skills"
                  error={skillsError}
                  required
                  input={
                    <SkillsDropdown
                      initialSkillId={challenge.expertChallengeSkillIDs?.[0]}
                      onSelect={(skill) =>
                        skill &&
                        form.setFieldValue("expertChallengeSkillIDs", [
                          skill.id,
                        ])
                      }
                      error={!!skillsError}
                    />
                  }
                />

                <InlineInputs>
                  <FormikTextField
                    id="expert-challenge-required-questions"
                    name="requiredQuestions"
                    label="Questions:"
                    type="number"
                    required
                  />

                  <FormikTextField
                    id="expert-challenge-deadline"
                    name="deadline"
                    label="Deadline:"
                    type="number"
                    required
                  />
                  <div>
                    <FormikRadioField
                      id="expert-challenge-visibility-public"
                      name="visibility"
                      label="Public"
                      value="public"
                    />
                    <FormikRadioField
                      id="expert-challenge-visibility-private"
                      name="visibility"
                      label="Private"
                      value="private"
                    />
                  </div>
                </InlineInputs>
                {form.values.visibility === "private" && (
                  <Field
                    for="expertIDs"
                    label="Access List"
                    error={expertsError}
                    required
                    input={
                      <>
                        <Experts>
                          {accessList.map((expert) => (
                            <ExpertTag
                              key={expert.id}
                              name={expert.name}
                              onDelete={() => {
                                form.setFieldValue(
                                  "expertIDs",
                                  form.values.expertIDs?.filter(
                                    (expertId) => expertId !== expert.id
                                  )
                                );
                                setAccessList((experts) =>
                                  experts.filter(
                                    (listExpert) => listExpert.id !== expert.id
                                  )
                                );
                              }}
                            />
                          ))}
                        </Experts>
                        <ExpertsDropdown
                          isStateless
                          skillIDs={form.values.expertChallengeSkillIDs || []}
                          onSelect={(expert) => {
                            if (!expert) {
                              return;
                            }
                            form.setFieldValue(
                              "expertIDs",
                              form.values.expertIDs?.concat([expert.id]) || [
                                expert.id,
                              ]
                            );
                            setAccessList((experts) =>
                              experts.concat([expert])
                            );
                          }}
                        />
                      </>
                    }
                  />
                )}
              </Inputs>

              <Buttons>
                <LinkButton
                  variant="secondary"
                  onClick={onCancel}
                  type="button"
                >
                  Cancel
                </LinkButton>
                <Button type="submit">
                  {expertChallenge ? "Save changes" : "Create challenge"}
                </Button>
              </Buttons>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}

const INITIAL_VALUES: NewExpertChallenge = {
  name: "",
  deadline: 10,
  requiredQuestions: 10,
  expertChallengeSkillIDs: [],
  expertIDs: [],
  testType: TestType.Quiz,
};

const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required("Challenge name cannot be empty"),
  requiredQuestions: yup.number().required("Questions cannot be empty"),
  deadline: yup.number().required("Deadline cannot be empty"),
  expertChallengeSkillIDs: yup
    .array()
    .nullable()
    .required("Skill must be selected"),
  expertIDs: yup.array().when("visibility", {
    is: (visibility) => visibility === "private",
    then: yup.array().required("At least one expert must be selected"),
    testType: yup.string().required("Test type cannot be empty"),
  }),
});
//endregion

//region Styles
const Container = styled.div`
  padding: 24px 36px 0;
`;

const Inputs = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
`;

const InlineInputs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  & > * {
    width: 33%;
  }

  input[type="number"] {
    width: 50px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;

  & > :first-of-type {
    margin-right: 15px;
  }
`;

const Experts = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin: 0 10px 10px 0;
  }
`;
//endregion
